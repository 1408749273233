import { Pencil, ChevronDown, Plus, Search } from 'lucide-react';
import { BackSide, CustomListPopover, FrontSide } from '@common/components';
import { useQueryClient } from '@tanstack/react-query';

import { Button } from '@components';
import {
  apiEndpoints,
  queryKeys,
  useGetQuery,
  usePostMutation,
  useUpdateMutation,
} from '@services';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SUCCESS } from '@constants';

import { doInfiniteScroll, httpErrorHandler } from '@utils';
import { Loader } from '@common/components/Loader';
import useInfiniteScroll from '@common/hooks/useInfiniteScrool';

export function CardsPreview({
  activeCardId,
  newCard,
  setDisableMode,
  setActiveCard,
  setNewCard,
  setActiveCardId,
}) {
  // TODO: Needs Refactoring
  const [editMode, setEditMode] = useState(false);
  const [cardKey, setCardKey] = useState();
  const [cardTags, setCardTags] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [initialQuestion, setInitialQuestion] = useState();
  const [initialAnswer, setInitialAnswer] = useState();
  const [newTags, setNewTags] = useState([]);
  const [removeTags, setRemoveTags] = useState([]);
  const [cardDecks, setCardDecks] = useState([]);
  const [search, setSearch] = useState('');
  const queryClient = useQueryClient();
  const [tagsLoading, setTagsLoading] = useState(true);
  const [checkBoxDisable, setCheckBoxDisable] = useState(false);
  const [isSaveDisable, setIsSaveDisable] = useState(true);

  const {
    listing: getDecks,
    listFetching: refetchingDecks,
    listRefetch: refetchDecks,
    listNextPage: decksNextPage,
    listHasNextPage: deckHasNextPage,
    errorMessage,
  } = useInfiniteScroll({
    apiUrl: apiEndpoints?.CARD?.DECKS,
    key: queryKeys?.CARD?.DECKS,
    params: { card: activeCardId, search: search },
    isEnabled: !!activeCardId,
  });

  const handleScroll = (e) =>
    doInfiniteScroll(e, deckHasNextPage, refetchingDecks, decksNextPage);

  const {
    data: getCardDetails,
    isFetching: cardDetailsFetching,
    refetch: cardDetailsRefetch,
    isLoading: cardDetailsLoading,
  } = useGetQuery(
    queryKeys?.CARD?.CARDS + 'details',
    apiEndpoints?.CARD?.CARD_DETAILS(cardKey),
    {},
    { enabled: !!cardKey, retry: false, refetchOnWindowFocus: false },
  );

  const onGetAllCardTags = () => {
    setTagsLoading(false);
  };

  const {
    data: getCardTags,
    refetch: refetchCardTags,
    isFetching: fetchingCardTags,
    isLoading: LoadingCardTags,
  } = useGetQuery(
    queryKeys?.CARD?.TAGS,
    apiEndpoints?.CARD?.TAGS,
    {
      card: activeCardId,
    },
    {
      enabled: !!activeCardId,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: onGetAllCardTags,
    },
  );

  useEffect(() => {
    if (getCardTags) {
      setCardTags(getCardTags?.data?.results);
    }
  }, [getCardTags]);

  useEffect(() => {
    const allSelectedIds = getDecks?.pages?.flatMap((page) =>
      page?.data?.results
        ?.filter((room) => room?.isSelected)
        ?.map((room) => room?.id),
    );

    setCardDecks(allSelectedIds);
  }, [getDecks]);

  const onSuccess = (data) => {
    setEditMode(false);
    setCardKey(data?.data?.id);
    setActiveCardId(data?.data?.id);
    queryClient.invalidateQueries({ queryKey: queryKeys?.CARD?.CARDS });
    queryClient.invalidateQueries({ queryKey: queryKeys?.HOME });

    toast.success(SUCCESS('New card added'));
  };
  const onError = (err) => {
    httpErrorHandler({
      err: err?.response,
      errorKeys: ['question', 'answer', 'non_field_errors'],
    });
  };

  useEffect(() => {
    if (
      question == initialQuestion &&
      answer == initialAnswer &&
      cardTags == getCardTags?.data?.results
    ) {
      setIsSaveDisable(true);
    } else {
      setIsSaveDisable(false);
    }
  }, [question, answer, cardTags]);
  const { mutate: createCardMutation } = usePostMutation(
    queryKeys?.CARD?.CARDS,
    apiEndpoints?.CARD?.ADD_CARD,
    onSuccess,
    onError,
  );

  const onUpdateSuccess = () => {
    setCheckBoxDisable(false);
    queryClient.invalidateQueries({ queryKey: queryKeys?.CARD?.CARDS });
    queryClient.invalidateQueries({ queryKey: queryKeys?.CARD?.TAGS });
    toast.success(SUCCESS('Card updated'));
  };

  const onErrorUpdate = (err) => {
    setCheckBoxDisable(false);
    httpErrorHandler({
      err: err?.response,
      errorKeys: ['question', 'answer', 'non_field_errors'],
    });
  };

  const { mutate: updateCardMutation } = useUpdateMutation(
    `${apiEndpoints?.CARD?.UPDATE_CARD}${cardKey}`,
    onUpdateSuccess,
    onErrorUpdate,
  );

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    setDisableMode(!editMode);
  }, [editMode]);

  useEffect(() => {
    if (getCardDetails?.data) {
      setQuestion(getCardDetails?.data?.question);
      setAnswer(getCardDetails?.data?.answer);
      setInitialQuestion(getCardDetails?.data?.question);
      setInitialAnswer(getCardDetails?.data?.answer);
    }
  }, [getCardDetails]);

  const handleDeckUpdate = (value, item) => {
    setCheckBoxDisable(true);
    if (value) {
      setCardDecks([...cardDecks, item]);
      updateCardMutation({ payload: { newDecks: [item] } });
    } else {
      setCardDecks((currentIds) => currentIds.filter((id) => id !== item));
      updateCardMutation({ payload: { toRemoveDecks: [item] } });
    }
  };

  const checkFormulaExist = (content) => {
    return /class="ql-formula"/.test(content) ? true : false;
  };
  function checkContent(htmlString) {
    const hasMediaTags = /<img[\s\S]*?>|<iframe[\s\S]*?>/i.test(htmlString);

    if (hasMediaTags) {
      return true;
    } else {
      const contentWithoutTags = htmlString.replace(/<\/?[^>]+(>|$)/g, '');
      const hasContent = contentWithoutTags.trim().length > 0;

      return hasContent;
    }
  }

  useEffect(() => {
    refetchDecks();
  }, [search]);

  const handleSave = () => {
    if (!(checkContent(question) && checkContent(answer))) {
      toast.error('Field should not be empty');
    } else {
      setEditMode(!editMode);

      if (newCard) {
        const data = {
          question: checkFormulaExist(question)
            ? question + '<span>&nbsp</span>'
            : question,
          answer: checkFormulaExist(answer)
            ? answer + '<span>&nbsp</span>'
            : answer,
          tags: newTags,
        };
        createCardMutation({ payload: data });
        setNewCard(false);
        setEditMode(!editMode);
      } else {
        const data = {
          toRemoveTags: removeTags,
          newTags: newTags,
          question: checkFormulaExist(question)
            ? question + '<span>&nbsp</span>'
            : question,
          answer: checkFormulaExist(answer)
            ? answer + '<span>&nbsp</span>'
            : answer,
        };

        updateCardMutation({ payload: data });
      }

      setNewTags([]);
      setRemoveTags([]);
      setIsSaveDisable(true);
    }
  };

  const handleDiscard = () => {
    setEditMode(!editMode);
    setQuestion(initialQuestion);
    setAnswer(initialAnswer);
    setCardTags(getCardTags?.data?.results);
    setNewCard(false);
  };

  useEffect(() => {
    if (cardKey) {
      refetchDecks();
      cardDetailsRefetch();
      refetchCardTags();
    }
  }, [cardKey]);
  useEffect(() => {
    if (newCard) {
      setCardKey(null);
      setEditMode(true);
      setQuestion('');
      setAnswer('');
      setCardTags([]);
    } else if (activeCardId) {
      setCardKey(activeCardId);
    }
  }, [activeCardId]);

  return (
    <div className='flex flex-col gap-7  pl-[1.875rem] pr-10 pt-6 lg:w-[calc(100vw-510px)] 2xl:w-[calc(100vw-570px)]'>
      <div className='flex items-center justify-between'>
        <h1 className='text-2xl font-bold leading-[1.8125rem]'>
          {editMode ? 'Editor' : 'Preview'}
        </h1>
        {editMode ? (
          <div className='flex gap-3'>
            <Button
              variant='outline'
              className='flex w-[8.1875rem] items-center gap-1 rounded-md'
              onClick={handleDiscard}
            >
              Discard Changes
            </Button>
            <Button
              className='flex w-[7.1875rem] items-center gap-1 rounded-md'
              onClick={handleSave}
              disabled={isSaveDisable}
            >
              Save
            </Button>
          </div>
        ) : (
          <div className='flex gap-4'>
            <Button className='h-9 w-9 p-2.5' onClick={handleEditMode}>
              <Pencil height='18px' width='18px' />
            </Button>

            <CustomListPopover
              name={'Deck(s)'}
              data={getDecks}
              search={search}
              setSearch={setSearch}
              handleScroll={handleScroll}
              selectedItems={cardDecks}
              updateItem={handleDeckUpdate}
              checkBoxDisable={checkBoxDisable}
              onchange={''}
            />
          </div>
        )}
      </div>
      {!cardDetailsFetching ? (
        <div className='flex h-[calc(100vh-100px)]'>
          <FrontSide
            data={getCardDetails}
            question={question}
            setQuestion={setQuestion}
            editMode={editMode}
            setEditMode={setEditMode}
            toAdd={newTags}
            setToAdd={setNewTags}
            toRemove={removeTags}
            setToRemove={setRemoveTags}
            cardTags={cardTags}
            setCardTags={setCardTags}
            tags={cardTags}
            selectedCard={activeCardId}
            tagsLoading={tagsLoading}
            setTagsLoading={setTagsLoading}
            newCard={newCard}
          />
          <BackSide answer={answer} setAnswer={setAnswer} editMode={editMode} />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
