// Tooltip.js
import React from 'react';
import { TagPills } from '.';
import { Tooltip } from 'react-tooltip';

const TagTooltip = ({
  id,
  name,
  handleRemoveTag = () => {},
  onChange = () => {},
  onClick = () => {},
  cancelable = true,
}) => {
  return name.length > 15 ? (
    <div data-tooltip-id={`my-tooltip${id}`} data-tooltip-content={name}>
      {cancelable ? (
        <TagPills
          id={id}
          pillsText={name?.substring(0, 15) + '...'}
          isCancelable={() => handleRemoveTag(id)}
          onChange={onChange}
          onClick={onClick}
          className='cursor-pointer'
        />
      ) : (
        <TagPills
          id={id}
          pillsText={name?.substring(0, 15) + '...'}
          onChange={onChange}
          onClick={onClick}
          className='cursor-pointer'
        />
      )}
      <Tooltip className='absolute top-10' place='top' id={`my-tooltip${id}`} />
    </div>
  ) : cancelable ? (
    <TagPills
      id={id}
      pillsText={name}
      isCancelable={() => handleRemoveTag(id)}
      onChange={onChange}
      onClick={onClick}
      className='cursor-pointer'
    />
  ) : (
    <TagPills
      id={id}
      pillsText={name}
      onChange={onChange}
      onClick={onClick}
      className='cursor-pointer'
    />
  );
};

export default TagTooltip;
