import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  AnswerCard,
  ConnectionLost,
  Footer,
  Header,
  QuestionCard,
  Rating,
  SessionCompletion,
  SessionProgressbar,
  WaitingScreen,
} from './components';

import { Button } from '@components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getLocalStorageItem } from '@utils';
import { ACCESS_TOKEN } from '@constants';
import { toast } from 'react-toastify';
import { generatePath } from 'react-router';
import { sessionUrl } from '../../../config';
import { Offline, Detector } from 'react-detect-offline';
export default function Session() {
  const [connect, setConnect] = useState(false);
  const { uuId, roomName } = useParams();
  const [role, setRole] = useState('');
  const [data, setData] = useState();
  const [socket, setSocket] = useState(null);
  const [rating, setRating] = useState();
  const [prevRating, setPrevRating] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [showToaster, setShowToaster] = useState(false);
  const [btnText, setBtnText] = useState('Show Results');
  const [updateRating, setUpdateRating] = useState(false);
  const [prevProgValue, setPrevProgValue] = useState('');
  const [onlineStatus, setOnlineStatus] = useState();
  const [timeoutId, setTimeoutId] = useState(null);
  const [otherUser, setOtherUser] = useState(false);

  const connection = useRef(null);
  const heartbeatInterval = useRef(null);
  const queryParams = new URLSearchParams(location?.search);

  function getSocketParams() {
    if (getLocalStorageItem(ACCESS_TOKEN)) {
      setRole('teacher');
      return `role=teacher&room=${queryParams.get('roomId')}&deck=${queryParams.get('deckId')}`;
    } else {
      setRole('outsider');
      return `role=outsider`;
    }
  }

  const sendHeartbeat = () => {
    if (
      connection.current &&
      connection.current.readyState === WebSocket.OPEN
    ) {
      connection.current.send(JSON.stringify({ type: 'ping' }));
    }
  };

  useEffect(() => {
    if (updateRating == prevRating && btnText !== 'Show Results') {
      setBtnText('Next');
    } else if (updateRating && btnText !== 'Show Results') {
      setBtnText('Update Rating');
    }
  }, [updateRating]);

  const socketInitialize = () => {
    if (!connection.current) {
      const params = getSocketParams();

      const ws = new WebSocket(`${sessionUrl}${uuId}/?${params}`);

      ws.onopen = () => {
        setConnect(true);
        heartbeatInterval.current = setInterval(sendHeartbeat, 15000);
      };

      setSocket(ws);
      connection.current = ws;

      ws.onmessage = (event) => {
        if (event?.data == 'Wait for other user to join.') {
          setOtherUser(true);
        } else {
          setOtherUser(false);
          setData(JSON.parse(event?.data));
        }
      };

      ws.onclose = (event) => {
        if (event?.code == 1006) {
          toast.error('Something went wrong');
        }
        setConnect(false);
        clearInterval(heartbeatInterval.current);
        redirectOnClose();
      };
    }
  };

  useEffect(() => {
    const sessionClosingWarning = () => {
      if (
        connection.current &&
        connection?.current?.readyState === WebSocket.OPEN
      ) {
        connection?.current?.close();
        redirectOnClose();
      }
    };

    if (onlineStatus === false) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(() => {
        if (onlineStatus === false) {
          sessionClosingWarning();
        }
      }, 31000);

      setTimeoutId(newTimeoutId);
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [onlineStatus]);

  useEffect(() => {
    socketInitialize();

    return () => {
      if (
        connection.current &&
        connection?.current?.readyState === WebSocket.OPEN
      ) {
        clearInterval(heartbeatInterval?.current);
        connection?.current?.close();
        connection.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (otherUser) {
      toast.error('No other user connected');
      setConnect(false);
    } else {
      setConnect(true);
    }
  }, [otherUser]);

  useEffect(() => {
    if (data?.can_get_initial && role == 'teacher') {
      if (connection?.current) {
        socket.send(
          JSON.stringify({
            type: 'initial_card',
          }),
        );
      }
    }
    setRating(data?.rating);

    if (
      prevProgValue != 100 &&
      data?.progress_value == 100 &&
      btnText !== 'Show Results'
    ) {
      toast.success('Congratulation ! You have completed 100 % progress.');
    }
  }, [data]);

  const redirectOnClose = () => {
    if (getLocalStorageItem(ACCESS_TOKEN)) {
      const path = generatePath('/roomDetails/:id', {
        id: queryParams.get('roomId'),
      });
      navigate(path);
    } else {
      const path = generatePath('/endSesssion/:roomName/:uuId', {
        roomName: roomName,
        uuId: uuId,
      });
      navigate(path);
    }
  };

  const sendMessage = () => {
    if (rating !== 0) {
      if (rating == prevRating) {
        setPrevRating(null);
        setBtnText('Show Results');
        setShowToaster(false);
        socket.send(
          JSON.stringify({
            type: 'next_card',
          }),
        );
      } else {
        setPrevRating(rating);
        setShowToaster(true);
        setPrevProgValue(data?.progress_value);

        setBtnText('Next');
        socket.send(
          JSON.stringify({
            type: 'rate_card',
            user_card_review: data?.user_card_review_id,
            rating: rating,
          }),
        );
      }
    } else {
      toast.error('Can’t move further without rating.');
    }
  };

  const buttonColor = () => {
    return btnText === 'Show Results'
      ? 'bg-green-600'
      : btnText === 'Update Rating'
        ? 'bg-red-500'
        : '';
  };

  const previousCard = () => {
    setShowToaster(false);
    socket.send(
      JSON.stringify({
        type: 'previous_card',
      }),
    );
  };

  const closeConnection = () => {
    
    if (socket) {
      socket.send(
        JSON.stringify({
          type: 'end_session',
        }),
      );
    }
    setConnect(false);
  };

  return (
    <div className='relative h-screen overflow-y-auto'>
      <Header
        role={role}
        closeSocket={closeConnection}
        room={roomName}
        showToaster={showToaster}
        setShowToaster={setShowToaster}
      />

      {connect && !!data ? (
        <div className='flex h-[calc(100vh-103px)] flex-col '>
          <div className='flex h-full flex-col justify-between'>
            <div className='mx-auto w-[60.375rem] pt-[30px]'>
              <div>
                <h2 className='text-[1.875rem] font-bold leading-[2.25rem]'>
                  {data?.deck_name}
                </h2>

                <SessionProgressbar progressValue={data?.progress_value} />
              </div>
              <div className='relative mt-[1.625rem] flex items-center justify-center'>
                <div className='absolute top-0 h-[1.875rem] w-[53.6875rem] rounded-3xl border border-sessionCardBorder bg-sessionCardsBg'></div>
                <div className='absolute top-2.5 h-[1.875rem] w-[57.1875rem] rounded-3xl border border-sessionCardBorder bg-sessionCardsBg'></div>
                {role === 'teacher' ? (
                  <AnswerCard
                    question={data?.question}
                    answer={data?.answer}
                    role={role}
                    tags={data?.tags}
                  />
                ) : data?.rating ? (
                  <AnswerCard
                    question={data?.question}
                    answer={data?.answer}
                    role={role}
                  />
                ) : (
                  <QuestionCard question={data?.question} />
                )}
              </div>
            </div>
            <div
              className={`mx-auto flex w-full max-w-[60.375rem] items-center py-[1.875rem] ${role === 'teacher' ? 'justify-between' : 'justify-center'}`}
            >
              {role === 'teacher' && (
                <Button
                  variant='outline'
                  className='w-[11.25rem]'
                  onClick={previousCard}
                  disabled={!data?.has_previous}
                >
                  Previous
                </Button>
              )}

              <Rating
                role={role}
                showAnswer={data?.rating ? true : false}
                filledStars={rating}
                setRaiting={setRating}
                setUpdateRating={setUpdateRating}
              />

              {role === 'teacher' && (
                <Button
                  className={`w-[11.25rem] ${buttonColor()}`}
                  onClick={sendMessage}
                  // disabled={!data?.has_next}
                >
                  {btnText}
                </Button>
              )}
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      ) : (
        <WaitingScreen />
      )}
      <Detector
        render={({ online }) => {
          setOnlineStatus(online);
        }}
      />
      <Offline>
        <ConnectionLost />
      </Offline>
    </div>
  );
}
