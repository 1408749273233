import { Decks, DecksDetail } from '@pages/deck/components';
import { Sidebar, Header } from './index';
import { Outlet } from 'react-router-dom';

export function Layout({ children }) {
  return (
    <div className='flex'>
      <Sidebar />
      <div className='h-screen lg:w-[calc(100vw-15rem)] 2xl:w-[calc(100vw-310px)]'>
        <div className='flex h-full flex-col'>
          <Header />
          <Outlet />
        </div>
      </div>
    </div>
  );
}
