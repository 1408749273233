import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@components';

const ImageModal = ({ imageUrl, isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className='h-[44.375rem] min-w-[62.5625rem] !rounded-lg border-0 !bg-transparent p-0'
        closeClassName='bg-primary w-8 h-8 rounded-full flex justify-center items-center'
        crossColor='text-white'
      >
        <img
          src={imageUrl}
          alt='Modal Content'
          style={{ maxWidth: '100%' }}
          className='h-[44.375rem] w-full rounded-lg'
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImageModal;
