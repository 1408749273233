export const DEFAULT_FIELD_ERROR = 'This field is required';
export const SIGN_IN_INITIAL_VALUE = {
  email: '',
  password: '',
};
export const DECK_CREATE_INITIAL_VALUE = {
  name: '',
  tag: [],
};

export const ROOM_NAME_INITIAL_VALUE = {
  name: '',
};

export const TAG_NAME_INITIAL_VALUE = {
  name: '',
};
