import React, { useEffect } from 'react';
import { Progress } from '@components';

export function SessionProgressbar(progressValue) {
  const [progress, setProgress] = React.useState(13);
  React.useEffect(() => {
    const timer = setTimeout(() => setProgress(66), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='mt-3 flex items-center justify-between'>
      <Progress value={progressValue.progressValue} className='w-[54.375rem]' />
      <span className='text-2xl font-bold leading-[1.6875rem]'>
        {progressValue.progressValue
          ? parseInt(progressValue.progressValue)
          : 0}
        %
      </span>
    </div>
  );
}
