import { ContentCard, CountPill, TagPills } from '@common/components';
import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@components';
import { ChevronsUpDown, Search } from 'lucide-react';
import React from 'react';
import { DecksPreview } from './index';
import { useGetQuery } from '@services/networkRequestService';
import { apiEndpoints, queryKeys, useUpdateMutation } from '@services';
import { useParams } from 'react-router-dom';
import { Loader } from '@common/components/Loader';
import { toast } from 'react-toastify';
import { SUCCESS } from '@constants';
import { doInfiniteScroll, httpErrorHandler } from '@utils';
import { Tooltip } from 'react-tooltip';
import useInfiniteScroll from '@common/hooks/useInfiniteScrool';
import TagTooltip from '@common/components/TagTooltip';
import { AppContext } from '@useContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export function DecksDetail({ setDeckDetails }) {
  const queryClient = useQueryClient();
  // TODO: Needs Refactoring
  const { id } = useParams();

  const [deckKey, setDeckKey] = useState();
  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [addCard, setAddCard] = useState([]);
  const [removeCard, setRemoveCard] = useState([]);
  const [search, setSearch] = useState();
  const [value, setValue] = useState('');
  const [deckCards, setDeckCards] = useState();
  const [checkBoxSelect, setCheckBoxSelect] = useState(false);
  const { backBtn, setBackBtn, setHeader } = useContext(AppContext);

  const {
    listing: getCards,
    listFetching: fetchingDeckCards,
    listRefetch: refetchDeckCards,
    listNextPage: deckCardsNextPage,
    listHasNextPage: DeckCardsHasNext,
  } = useInfiniteScroll({
    apiUrl: apiEndpoints?.DECK?.CARDS,
    key: queryKeys?.DECK?.CARDS,
    params: { deck: deckKey, search: search },
    isEnabled: !!deckKey,
  });

  const handleScroll = (e) =>
    doInfiniteScroll(e, DeckCardsHasNext, fetchingDeckCards, deckCardsNextPage);

  useEffect(() => {
    if (!!search) {
      refetchDeckCards();
    }
  }, [search]);

  const {
    data: getDeckDetails,
    refetch: refetchDeckDetails,
    isFetching: deckDetailsFetching,
  } = useGetQuery(
    queryKeys?.DECK?.DETAILS,
    apiEndpoints?.DECK?.DETAILS(deckKey),
    {},
    { enabled: !!deckKey },
  );

  const {
    data: getDeckTags,
    refetch: refetchDeckTags,
    isFetching: fetchingDeckTags,
  } = useGetQuery(
    queryKeys?.DECK?.TAGS + `Details`,
    apiEndpoints?.DECK?.TAGS,
    {
      deck: deckKey,
    },
    { enabled: !!deckKey },
  );

  const onSuccessUpdate = () => {
    setCheckBoxSelect(false);
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: queryKeys?.DECK?.DETAILS });
    }, 1000);

    toast.success(SUCCESS('Deck updated'));
  };

  const { mutate: updateDeckMutation } = useUpdateMutation(
    apiEndpoints?.DECK?.UPDATE(deckKey),
    onSuccessUpdate,
    (err) => httpErrorHandler({ err: err?.response }),
  );

  useEffect(() => {
    const data = {
      newCards: addCard,
      toRemoveCards: removeCard,
    };
  }, [addCard, removeCard]);

  useEffect(() => {
    const allSelectedIds = getCards?.pages?.flatMap((page) =>
      page?.data?.results
        ?.filter((room) => room.isSelected)
        .map((room) => room.id),
    );

    setDeckCards(allSelectedIds);
  }, [getCards]);

  const handleTags = () => {
    refetchDeckTags();
  };

  useEffect(() => {
    if (!selectedCard) {
      if (getDeckDetails?.data?.cards) {
        setSelectedCard(getDeckDetails?.data?.cards[0]?.id);
      } else {
        setSelectedCard(null);
      }
    }
  }, [getDeckDetails?.data?.cards]);

  useEffect(() => {
    if (id) {
      setDeckKey(id);
      setSelectedCard(null);
    }
    setHeader('Decks');
    setBackBtn(true);
  }, [id]);

  useEffect(() => {
    if (deckKey) {
      refetchDeckCards();
    }
  }, [deckKey]);

  const handleAddCards = (value, id) => {
    setCheckBoxSelect(true);
    if (value) {
      setDeckCards([...deckCards, id]);
      updateDeckMutation({ payload: { newCards: [id] } });
    } else {
      setDeckCards((currentIds) =>
        currentIds.filter((deckId) => deckId !== id),
      );
      updateDeckMutation({ payload: { toRemoveCards: [id] } });

      setSelectedCard(null);
    }
  };

  return (
    <div className='flex h-[calc(100%-101px)]'>
      <div className='h-full w-[344px] border-r py-4'>
        <div className='flex flex-col gap-4 pl-10 pr-4'>
          <h1
            className='max-w-[300px] truncate text-2xl font-bold leading-8'
            data-tooltip-id='my-tooltip'
            data-tooltip-content={getDeckDetails?.data?.name}
          >
            📚
            <span className='pl-2'>
              {deckDetailsFetching ? <Skeleton /> : getDeckDetails?.data?.name}
            </span>
          </h1>

          <Tooltip place='top' id='my-tooltip' />
          {deckDetailsFetching && getDeckDetails?.data?.tags?.length !== 0 ? (
            <Skeleton count='4' />
          ) : (
            <div className='flex h-[98px] flex-wrap gap-x-1.5 gap-y-3'>
              {getDeckDetails?.data?.tags?.map(({ id, name }) => (
                <TagTooltip id={id} name={name} cancelable={false} />
              ))}

              {getDeckDetails?.data?.remainingTagsCount > 0 ? (
                <CountPill
                  onClick={() => handleTags(getDeckDetails?.data?.id)}
                  count={getDeckDetails?.data?.remainingTagsCount}
                  data={getDeckTags?.data?.results}
                  isLoading={fetchingDeckTags}
                />
              ) : (
                ''
              )}
            </div>
          )}
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild className='w-full'>
              <Button
                variant='outline'
                role='combobox'
                aria-expanded={open}
                className='w-full justify-between'
              >
                {value
                  ? frameworks.find((framework) => framework.value === value)
                      ?.label
                  : 'Add Card'}
                <ChevronsUpDown
                  className='ml-2 h-4 w-4 shrink-0'
                  color='#004672'
                />
              </Button>
            </PopoverTrigger>
            <PopoverContent className='max-w-[288px] p-0'>
              <Input
                placeholder='Select Card'
                leadIcon={<Search color='#00000080' />}
                className='border-0 !pl-11'
                wrapperClassName='!gap-0'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div
                className='mt-1.5 h-[300px] overflow-y-auto'
                onScroll={handleScroll}
              >
                {getCards?.pages?.map((page) =>
                  page?.data?.results?.map((card) => (
                    <div className='flex gap-2 rounded-sm bg-primary/5 py-1.5 pl-2 pr-4'>
                      <Checkbox
                        className='mt-0.5'
                        onCheckedChange={(e) => handleAddCards(e, card?.id)}
                        checked={deckCards?.includes(card?.id)}
                        disabled={checkBoxSelect}
                      />
                      <p className='text-sm font-normal leading-5 text-secondary '>
                        {card?.cleanedQuestion}
                      </p>
                    </div>
                  )),
                )}
              </div>
            </PopoverContent>
          </Popover>
        </div>

        <div className='mt-6 flex h-[calc(100%-200px)] flex-col gap-4 overflow-y-auto pb-5 pl-10 pr-4'>
          {!deckDetailsFetching ? (
            getDeckDetails?.data?.cards?.map((card, index) => (
              <div
                key={card.id}
                className={` ${index !== getDeckDetails?.data?.cards?.length - 1 ? 'border-b border-primary/15 pb-4' : ''}`}
              >
                <ContentCard card={card} setSelectedCard={setSelectedCard} />
              </div>
            ))
          ) : (
            <Loader />
          )}
        </div>
      </div>
      {selectedCard ? (
        <DecksPreview
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          selectedDeck={deckKey}
        />
      ) : (
        ''
      )}
    </div>
  );
}
