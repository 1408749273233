import { Input } from '@components';
import { Search } from 'lucide-react';
import { CreateDeckDialog, DecksDetail, DecksTable } from './index';
import { DeleteDialog, EmptyScreen } from '@common/components';
import { useEffect, useState, useContext } from 'react';
import { AppContext } from '@useContext';
import { apiEndpoints, queryKeys, useDeleteMutation } from '@services';
import { toast } from 'react-toastify';
import { SUCCESS } from '@constants';
import { useQueryClient } from '@tanstack/react-query';

export function Decks() {
  const [deckDetails, setDeckDetails] = useState(false);
  const [deckKey, setDeckKey] = useState();
  const [searchParam, setSearchParam] = useState();
  const [deleteDecks, setDeleteDecks] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const queryClient = useQueryClient();

  const handleChange = (e) => {
    setSearchParam(e.target.value);
  };
  const { setBackBtn, setRoomLink } = useContext(AppContext);

  useEffect(() => {
    setBackBtn(false);
    setRoomLink(null);
  }, []);

  const onSuccess = () => {
    toast.success(SUCCESS('Delete deck(s)'));
    queryClient.invalidateQueries({ queryKey: queryKeys?.DECK?.DECKS });
    setSelectAll(false);
    setDeleteDecks([]);
  };
  const { mutate: deleteDeckMutation } = useDeleteMutation(
    apiEndpoints?.DECK?.DELETE,
    onSuccess,
  );

  const handleDelete = () => {
    const data = { object_ids: deleteDecks };
    deleteDeckMutation({
      payload: data,
    });
  };

  return (
    <div className='h-[calc(100%-101px)] px-10 py-6 lg:w-[calc(100vw-15rem)] 2xl:w-[calc(100vw-310px)]'>
      {deckDetails ? (
        <DecksDetail setDeckDetails={setDeckDetails} />
      ) : (
        <div className='flex flex-col space-y-6'>
          <div className='flex items-center justify-between'>
            <h1 className='text-2xl font-bold leading-[29px] text-secondary'>
              All Decks
            </h1>
            {deleteDecks.length > 0 ? (
              <DeleteDialog
                type='Deck(s)'
                name='Deck(s)'
                handleDelete={handleDelete}
              />
            ) : (
              <div className='flex items-center gap-4'>
                <div className='w-[228px]'>
                  <Input
                    onChange={handleChange}
                    labelClassName='hidden'
                    placeholder='Search Deck'
                    className='h-[2.625rem]'
                    leadIcon={
                      <Search color='#004672' height='18px' width='18px' />
                    }
                  />
                </div>

                <CreateDeckDialog />
              </div>
            )}
          </div>
          <DecksTable
            setDeckDetails={setDeckDetails}
            setDeckKey={setDeckKey}
            searchParam={searchParam}
            deleteDecks={deleteDecks}
            setDeleteDecks={setDeleteDecks}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
        </div>
      )}
    </div>
  );
}
