import { X } from 'lucide-react';
import { cn } from '@utils';

export function TagPills({
  pillsText,
  isCancelable,
  className,
  onClick = () => {},
}) {
  const handleCancel = () => {
    isCancelable();
  };

  const handleClick = () => {
    onClick();
  };
  return (
    <div
      onClick={handleClick}
      className={cn(
        'flex h-fit w-fit items-center gap-0.5 whitespace-nowrap rounded-full bg-pillBlue px-2 py-1',
        className,
      )}
    >
      <span className='text-xs font-normal leading-4 text-[#0B5CAD]'>
        {pillsText}
      </span>
      {isCancelable ? (
        <X
          onClick={handleCancel}
          color='#5F93C7'
          height='12px'
          width='12px'
          className='cursor-pointer'
        />
      ) : (
        ''
      )}
    </div>
  );
}
