import { useEffect, useState } from 'react';
import { CardContent, CardHeader, CardTitle, Card } from '@components';

export function ContentCard({ card, setSelectedCard }) {
  const [isActive, setIsActive] = useState(false);

  const handleCardClick = () => {
    setIsActive(true);
    setSelectedCard(card?.id);
  };

  const handleCardFocus = () => {
    setIsActive(true);
    setSelectedCard(card?.id);
  };

  const handleCardBlur = () => {
    setIsActive(false);
  };

  return (
    <Card
      className={`flex min-h-[6.75rem] flex-col gap-2 rounded-lg cursor-pointer ${isActive ? 'border-none bg-primary/5 p-[13px]' : ' border border-primary/15 p-3'}`}
      onClick={handleCardClick}
      onFocus={handleCardFocus}
      onBlur={handleCardBlur}
      tabIndex={0}
    >
      <CardHeader className='p-0'>
        <CardTitle className='max-w-[16.5rem] truncate text-sm font-semibold leading-[1.0588rem] text-primary'>
          {card?.cleanedQuestion}
        </CardTitle>
      </CardHeader>
      <CardContent className='line-clamp-3 max-w-[16.5rem] p-0 text-xs font-normal leading-4'>
        {card?.cleanedAnswer}
      </CardContent>
    </Card>
  );
}
