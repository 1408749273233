import NoDataIcon from '@assets/svgs/NoDataIcon';

export function EmptyScreen({ description }) {
  return (
    <div className='flex h-[calc(100vh-193px)] flex-col items-center justify-center'>
      <NoDataIcon />
      <p className='max-w-[462px] text-center text-sm font-normal leading-[17px] text-secondary'>
        {description}
      </p>
    </div>
  );
}
