import { useEffect, useState } from 'react';
import React from 'react';

import { CountPill, TagPills } from '@common/components';
import { Input, Popover, PopoverContent, PopoverTrigger } from '@components';
import { Plus } from 'lucide-react';
import {
  usePostMutation,
  useUpdateMutation,
  apiEndpoints,
  queryKeys,
  useGetQuery,
} from '@services';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { toast } from 'react-toastify';
import { TAG_NAME_INITIAL_VALUE, SUCCESS } from '@constants';
import Editor from './Editor';
import RenderHtml from './RenderHtml';
import TagTooltip from './TagTooltip';
import { useFormikForm } from '@common/hooks';
import { tagValidationSchema } from '@common/validators/loginValidationSchema';
import { useQueryClient } from '@tanstack/react-query';
import useInfiniteScroll from '@common/hooks/useInfiniteScrool';
import { doInfiniteScroll } from '@utils';

export function FrontSide({
  data,
  selectedCard,
  editMode,
  setEditMode,
  question,
  setQuestion,
  tags,
  toAdd,
  setToAdd,
  toRemove,
  setToRemove,
  newCard,
  activeCardTags,
  tagsLoading,
  setTagsLoading,
  cardTags,
  setCardTags,
}) {
  const [inputValue, setInputValue] = useState();
  const queryClient = useQueryClient();

  const onSuccessTag = (data) => {
    setInputValue('');
    toast.success(SUCCESS('New tag created'));
    addTags(data?.data);
  };

  const { mutate: tagCreateMutation } = usePostMutation(
    queryKeys?.TAG?.TAGS,
    apiEndpoints?.TAG?.NEW_TAG,
    onSuccessTag,
  );

  const onSuccessUpdate = () => {
    queryClient.invalidateQueries({ queryKey: queryKeys?.CARD?.TAGS });
  };

  const { mutate: updateCardMutation } = useUpdateMutation(
    apiEndpoints?.CARD?.UPDATE_CARD + `${selectedCard}`,
    onSuccessUpdate,
  );

  const handleRemoveTag = (tagId) => {
    if (!editMode) {
      const data = {
        toRemoveTags: [tagId],
      };
      updateCardMutation({ payload: data });

      let newArray = cardTags;
      newArray = newArray?.filter((item) => item?.id !== tagId);
      setCardTags(newArray);
    } else {
      let newArray = cardTags;
      newArray = newArray?.filter((item) => item?.id !== tagId);
      setCardTags(newArray);
      if (toAdd?.includes(tagId)) {
        const ids = toAdd;
        setToAdd(ids?.filter((item) => item !== tagId));
      } else {
        setToRemove([...toRemove, tagId]);
      }
    }
  };

  const {
    listing: getTags,
    listFetching: fetchingTags,
    listLoading: LoadingTags,
    listRefetch: refetchTags,
    listNextPage: tagNextPage,
    listHasNextPage: tagHasNextPage,
  } = useInfiniteScroll({
    apiUrl: apiEndpoints?.DECK?.TAGS,
    key: queryKeys?.TAG,
    params: { search: inputValue },
  });

  const handleCreateNewTag = () => {
    tagCreateMutation({
      payload: {
        name: formik?.values['name'],
      },
    });
  };

  const formik = useFormikForm(
    TAG_NAME_INITIAL_VALUE,
    handleCreateNewTag,
    tagValidationSchema,
  );

  const addTags = (item) => {
    if (cardTags?.find((tag) => tag?.id === item?.id)) {
      return;
    }
    if (!editMode) {
      const data = {
        newTags: [item?.id],
      };
      updateCardMutation({ payload: data });
      let newArray = [...cardTags, item];
      setCardTags(newArray);
    } else {
      let newArray = [...cardTags, item];
      setCardTags(newArray);

      if (toRemove?.includes(item?.id)) {
        setToRemove(toRemove.filter((tag) => tag !== item?.id));
      } else {
        setToAdd([...toAdd, item?.id]);
      }
    }
  };
  const handleOnChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleScroll = (e) => {
    doInfiniteScroll(e, tagHasNextPage, LoadingTags, tagNextPage);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    formik?.setFieldValue('name', e.target.value);
  };

  useEffect(() => {
    refetchTags();
  }, [inputValue]);

  return (
    <div className='w-1/2 break-all border-r pr-4'>
      <span className='text-base font-normal leading-[1.21rem] text-darkGrey'>
        Front Side
      </span>

      <div className='mt-2 flex flex-wrap items-center gap-2.5'>
        {cardTags
          ?.slice(-5)
          .reverse()
          ?.map(({ id, name }) => (
            <TagTooltip id={id} name={name} handleRemoveTag={handleRemoveTag} />
          ))}
        {cardTags?.length > 5 && (
          <CountPill count={cardTags?.length - 5} data={cardTags} />
        )}

        <Popover>
          <PopoverTrigger>
            <Plus color='#89888D' />
          </PopoverTrigger>
          <PopoverContent
            sideOffset={10}
            align='end'
            className='max-w-[211px] rounded-lg border border-primary p-0 shadow-[none]'
          >
            <Input
              placeholder='Search for an option'
              className=' rounded-none border-l-0 border-r-0 border-t-0 !border-b-primary/50 bg-primary/5 text-xs font-normal leading-3 text-primary'
              labelClassName='hidden'
              value={inputValue}
              onChange={(e) => handleInputChange(e)}
              errorMessage={formik.getFieldError('name')}
            />
            <div className='p-2'>
              <p className='text-center text-xs font-normal leading-3 text-primary/70'>
                Select an option or create one
              </p>

              <div
                className='mt-2 flex h-[7.5rem] flex-wrap items-center gap-2 overflow-y-auto rounded bg-primary/5 px-3.5 py-2.5'
                onScroll={handleScroll}
              >
                {getTags?.pages?.[0]?.data?.count > 0 ? (
                  getTags?.pages?.map((page) =>
                    page?.data?.results?.map((tag) => (
                      <TagPills
                        pillsText={tag?.name}
                        onClick={() => addTags(tag)}
                        className='cursor-pointer'
                      />
                    )),
                  )
                ) : (
                  <span>
                    create
                    <TagPills
                      id='name'
                      pillsText={formik.values['name']}
                      onChange={formik.handleChange}
                      onClick={formik.handleSubmit}
                      className='cursor-pointer'
                    />
                  </span>
                )}
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
      <div className='mt-4 h-[calc(100%-184px)] overscroll-y-auto pb-5'>
        {editMode ? (
          <div className='mt-6'>
            <Editor
              data={question}
              setData={setQuestion}
              editorClassName='h-[calc(100vh-438px)]'
            />
          </div>
        ) : (
          <div>
            <RenderHtml
              content={question}
              renderClassName='h-[calc(100vh-351px)]'
            />
          </div>
        )}
      </div>
    </div>
  );
}
