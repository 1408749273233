import React from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { Footer, Header } from './index';

export function WaitingScreen() {
  return (
    <div className='flex h-screen flex-col'>
      <div className='flex flex-grow flex-col items-center justify-center'>
        <RotatingLines
          visible={true}
          height='86'
          width='86'
          color='red'
          strokeWidth='2'
          animationDuration='0.75'
          ariaLabel='rotating-lines-loading'
          wrapperStyle={{}}
          wrapperClass=''
          strokeColor='#326BFF'
        />
        <p className='text-xl font-normal leading-[3.25rem]'>Waiting ...</p>
      </div>
      <Footer />
    </div>
  );
}
