import { Delete } from 'lucide-react';

export const apiEndpoints = {
  USER: { SIGN_IN: 'users/login/', PROFILE: 'users/me/' },
  HOME: 'learnings/home-page/',
  CARD: {
    CARDS: 'learnings/cards/',
    CARD_IMAGE: 'learnings/image-create/',
    UPDATE_CARD: 'learnings/card-update/',
    ADD_CARD: 'learnings/card-create/',
    TAGS: 'learnings/tags/',
    DECKS: 'learnings/select-decks/',
    DELETE: 'learnings/delete-cards/',
    CARD_DETAILS: (cardKey) => `learnings/card-detail/${cardKey}`,
  },
  DECK: {
    CARDS: 'learnings/select-cards/',
    DECKS: 'learnings/decks/',
    TAGS: 'learnings/tags/',
    ROOMS: 'learnings/select-rooms/',
    CREATE: 'learnings/deck-create/',
    DELETE: 'learnings/delete-decks/',
    DETAILS: (deckkey) => `learnings/deck-detail/${deckkey}`,
    UPDATE: (deckkey) => `learnings/deck-update/${deckkey}`,
  },
  ROOM: {
    ROOMS: 'learnings/rooms/',
    CREATE: 'learnings/room-create/',
    DETAIL: 'learnings/decks/',
    DECKS: 'learnings/select-decks/',
    DELETE: 'learnings/delete-rooms/',
    DETAILS: (roomKey) => `learnings/room-detail/${roomKey}`,
    UPDATE: (roomKey) => `learnings/room-update/${roomKey}`,
  },
  TAG: { TAGS: 'learnings/tags', NEW_TAG: 'learnings/tag-create/' },
};
