import { CountPill, EmptyScreen, TagPills } from '@common/components';
import { DeckRooms } from './index';
import moment from 'moment';
import {
  Checkbox,
  ScrollArea,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@components';

import React, { useEffect, useState, useRef } from 'react';
import { EditDeckDialog } from './EditDeckDialog';
import { useNavigate } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import {
  apiEndpoints,
  queryKeys,
  useGetQuery,
  useUpdateMutation,
} from '@services';
import 'react-tooltip/dist/react-tooltip.css';
import { toast } from 'react-toastify';
import { SUCCESS } from '@constants';
import useInfiniteScroll from '@common/hooks/useInfiniteScrool';
import { doInfiniteScroll } from '@utils';
import { Loader } from '@common/components/Loader';
import CustomTooltip from '@common/components/CustomToolTip';
import TagTooltip from '@common/components/TagTooltip';

export function DecksTable({
  setDeckDetails,
  searchParam,
  deleteDecks,
  setDeleteDecks,
  selectAll,
  setSelectAll,
}) {
  const [deckKey, setDeckKey] = useState();
  const [selectedDeck, setSelectedDeck] = useState();
  const [roomSearch, setRoomSearch] = useState();
  const [checkBoxDisable, setCheckBoxDisable] = useState(false);

  const navigate = useNavigate();
  const queryClient = new QueryClient();

  const {
    listing: deckList,
    listFetching,
    listLoading,
    listRefetch,
    listNextPage,
    listHasNextPage,
    errorMessage,
  } = useInfiniteScroll({
    apiUrl: apiEndpoints?.DECK?.DECKS,
    key: queryKeys?.DECK?.DECKS,
    params: { search: searchParam },
  });

  useEffect(() => {
    listRefetch();
  }, [searchParam]);

  const {
    data: getDeckTags,
    refetch: refetchDeckTags,
    isFetching: fetchingDeckTags,
  } = useGetQuery(
    queryKeys?.DECK?.TAGS,
    apiEndpoints?.DECK?.TAGS,
    {
      deck: selectedDeck,
    },
    {
      enabled: !!selectedDeck,
    },
  );

  const {
    listing: getDeckRooms,
    listFetching: fetchingDeckRooms,
    listLoading: LoadingDeckRooms,
    listRefetch: refetchDeckRooms,
    listNextPage: deckRoomsNextPage,
    listHasNextPage: deckRoomsHasNextPage,
  } = useInfiniteScroll({
    apiUrl: apiEndpoints?.DECK?.ROOMS,
    key: queryKeys?.DECK?.ROOM,
    params: { deck: selectedDeck, search: roomSearch },
    isEnabled: false,
  });

  const handleRoomScroll = (e) =>
    doInfiniteScroll(
      e,
      deckRoomsHasNextPage,
      LoadingDeckRooms,
      deckRoomsNextPage,
    );

  const onSuccessDeckUpdate = () => {
    setCheckBoxDisable(false);

    queryClient.invalidateQueries(queryKeys?.DECK?.DECKS);
    toast.success(SUCCESS('Deck updated'));
  };

  const onErrorUpdate = (err) => {
    setCheckBoxDisable(false);
  };

  const { mutate: updateDeckMutation } = useUpdateMutation(
    apiEndpoints?.DECK?.UPDATE(selectedDeck),
    onSuccessDeckUpdate,
    onErrorUpdate,
  );

  useEffect(() => {
    refetchDeckRooms();
  }, [roomSearch]);

  useEffect(() => {
    if (!!selectedDeck) {
      refetchDeckRooms();
      refetchDeckTags();
    }
  }, [selectedDeck]);

  const handleRowClick = (id) => {
    navigate(`/details/${id}`);
  };

  const handleDeckTags = (id) => {
    setSelectedDeck(id);
  };

  const handleRooms = (id) => {
    setSelectedDeck(id);
  };

  const handleScroll = (e) => {
    doInfiniteScroll(e, listHasNextPage, listLoading, listNextPage);
  };

  const handleSelectAll = (status) => {
    setSelectAll(!selectAll);
    if (status) {
      const items = deckList?.pages?.flatMap((page) =>
        page?.data?.results?.map((deck) => deck?.id),
      );
      setDeleteDecks(items);
    } else {
      setDeleteDecks([]);
    }
  };

  const handleDeleteDecks = (status, deckId) => {
    if (status) {
      const deleteItems = [...deleteDecks, deckId];
      setDeleteDecks(deleteItems);
    } else {
      const items = deleteDecks.filter((deck) => deck !== deckId);
      setDeleteDecks(items);
    }
  };

  return (
    <div className='h-[calc(100vh-220px)] '>
      {!listLoading ? (
        deckList?.pages[0]?.data?.count > 0 ? (
          <div
            className='h-full !overflow-y-auto rounded-lg border'
            onScroll={handleScroll}
          >
            <Table className='relative w-full'>
              <TableHeader className='sticky top-0 z-[10] rounded-l-lg rounded-r-lg bg-white'>
                <TableRow>
                  <TableHead className='pl-7'>
                    <div className='flex items-center gap-2'>
                      <Checkbox
                        checked={selectAll}
                        onCheckedChange={(e) => handleSelectAll(e)}
                      />
                      Title
                    </div>
                  </TableHead>
                  <TableHead>Tags</TableHead>
                  <TableHead>No. of Cards</TableHead>
                  <TableHead>Last Updated</TableHead>
                  <TableHead>Action</TableHead>
                  <TableHead className='pr-[33px]'></TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {deckList?.pages?.map((page) =>
                  page?.data?.results?.map((deck, index) => (
                    <TableRow
                      key={index}
                      className='cursor-pointer'
                      onClick={() => handleRowClick(deck?.id)}
                    >
                      <TableCell className='relative pl-7'>
                        <div className='flex items-center gap-2'>
                          <Checkbox
                            checked={deleteDecks?.includes(deck?.id)}
                            onCheckedChange={(e) =>
                              handleDeleteDecks(e, deck?.id)
                            }
                            onClick={(e) => e.stopPropagation()}
                          />
                          <CustomTooltip id={deck?.name} name={deck?.name} />
                        </div>
                      </TableCell>

                      <TableCell className=''>
                        <div className='flex gap-1.5'>
                          <div className='flex gap-1.5 overflow-hidden'>
                            {deck?.tags?.map(({ id, name }) => (
                              <TagTooltip
                                id={id}
                                name={name}
                                cancelable={false}
                              />
                            ))}
                          </div>
                          {deck?.remainingTagsCount > 0 ? (
                            <CountPill
                              count={deck?.remainingTagsCount}
                              onClick={() => handleDeckTags(deck?.id)}
                              data={getDeckTags?.data?.results}
                              isLoading={fetchingDeckTags}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{deck?.cardCount}</TableCell>
                      <TableCell>{moment(deck?.modified)?.fromNow()}</TableCell>
                      <TableCell>
                        <DeckRooms
                          handleRooms={() => handleRooms(deck?.id)}
                          data={getDeckRooms}
                          updateDeckMutation={updateDeckMutation}
                          isLoading={fetchingDeckRooms}
                          handleScroll={handleRoomScroll}
                          search={roomSearch}
                          setSearch={setRoomSearch}
                          checkBoxDisable={checkBoxDisable}
                          setCheckBoxDisable={setCheckBoxDisable}
                        />
                      </TableCell>
                      <TableCell className='pr-[33px]'>
                        <EditDeckDialog
                          deck={deck}
                          updateDeckMutation={updateDeckMutation}
                          setSelectedDeck={setDeckKey}
                          tags={getDeckTags?.data?.results}
                        />
                      </TableCell>
                    </TableRow>
                  )),
                )}
              </TableBody>
            </Table>
          </div>
        ) : (
          <EmptyScreen description='Oops! It looks like there are no deck available at the moment. Start by creating your first deck to get started!' />
        )
      ) : (
        <Loader />
      )}
    </div>
  );
}
