import { TagPills } from '@common/components';
import { X } from 'lucide-react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
  Label,
} from '@components';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { useState, useEffect, useRef } from 'react';
import {
  queryKeys,
  apiEndpoints,
  usePostMutation,
  useGetQuery,
  useUpdateMutation,
} from '@services';
import { useOnClickOutside } from '@common/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { Ellipsis } from 'lucide-react';
import { toast } from 'react-toastify';
import {
  DECK_CREATE_INITIAL_VALUE,
  SUCCESS,
  TAG_NAME_INITIAL_VALUE,
} from '@constants';
import { Loader } from '@common/components/Loader';
import { useFormikForm } from '@common/hooks';
import {
  CreateDeckValidationSchema,
  tagValidationSchema,
} from '@common/validators/loginValidationSchema';
import TagTooltip from '@common/components/TagTooltip';
import { httpErrorHandler } from '@utils';

export function EditDeckDialog({ deck }) {
  const [inputValue, setInputValue] = useState('');
  const [deckName, setDeckName] = useState('');
  const [newTags, setNewTags] = useState([]);
  const [removeTags, setRemoveTags] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDeck, setSelectedDeck] = useState();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = () => {
    setIsLoading(false);
  };

  const {
    data: getDeckTags,
    refetch: refetchDeckTags,
    isFetching: fetchingDeckTags,
  } = useGetQuery(
    queryKeys?.DECK?.TAGS,
    apiEndpoints?.DECK?.TAGS,
    {
      deck: deck?.id,
    },
    {
      enabled: false,
      onSuccess,
    },
  );

  const onSuccessUpdate = () => {
    queryClient.invalidateQueries({ queryKey: queryKeys?.DECK?.DECKS });
    toast.success(SUCCESS('Deck updated'));
  };

  const onErrorUpdate = (err) => {
    httpErrorHandler({
      err: err?.response,
      errorKeys: ['name'],
    });
  };

  const { mutate: updateDeckMutation } = useUpdateMutation(
    apiEndpoints?.DECK?.UPDATE(selectedDeck),
    onSuccessUpdate,
    onErrorUpdate,
  );

  const handleEditDeck = () => {
    setSelectedDeck(deck?.id);
    const allIds = newTags.map((item) => item.id);
    let data = null;

    if (formik.values['name'] === deckName) {
      data = {
        newTags: allIds,
        toRemoveTags: removeTags,
      };
    } else {
      data = {
        name: formik.values['name'],
        newTags: allIds,
        toRemoveTags: removeTags,
      };
    }
    updateDeckMutation({ payload: data });
    setOpen(false);
  };

  const formik = useFormikForm(
    DECK_CREATE_INITIAL_VALUE,
    handleEditDeck,
    CreateDeckValidationSchema,
  );

  const onSuccessTag = (data) => {
    setNewTags([...newTags, data?.data]);
    toast.success(SUCCESS('New tag created'));
  };

  const OnErrorTag = (data) => {
    toast.error('Error in creating card.');
  };

  const { mutate: tagCreateMutation } = usePostMutation(
    queryKeys?.TAG?.TAGS,
    apiEndpoints?.TAG?.NEW_TAG,
    onSuccessTag,
    OnErrorTag,
  );

  const {
    data: getTags,
    refetch: refetchTags,
    isFetching: fetchingTags,
  } = useGetQuery(queryKeys?.TAG, apiEndpoints?.DECK?.TAGS, {
    search: inputValue,
  });

  useEffect(() => {
    setNewTags(getDeckTags?.data?.results);
  }, [getDeckTags]);

  const handleCreateNewTag = () => {
    tagCreateMutation({
      payload: {
        name: tagFormik?.values['name'],
      },
    });
    setInputValue('');
  };

  const tagFormik = useFormikForm(
    TAG_NAME_INITIAL_VALUE,
    handleCreateNewTag,
    tagValidationSchema,
  );
  const addTags = (item) => {
    setNewTags([...newTags, item]);
  };

  useEffect(() => {
    if (open) {
      formik.setFieldValue('name', deck?.name);
      setDeckName(deck?.name);
      setIsLoading(true);
      refetchDeckTags();
    } else {
      setDeckName('');
      setInputValue('');
    }
  }, [open]);

  useEffect(() => {
    refetchTags();
  }, [inputValue]);

  const RemoveTags = (id) => {
    setRemoveTags([...removeTags, id]);
    setNewTags((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    tagFormik?.setFieldValue('name', e.target.value);
  };
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, () => setIsInputFocused(false));
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DropdownMenu>
        <DropdownMenuTrigger className='focus-visible:outline-none'>
          <Ellipsis color='#17294A' />
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end'>
          <DropdownMenuItem onClick={(e) => e.stopPropagation()}>
            <DialogTrigger className='w-full text-start'>Edit </DialogTrigger>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>Edit Deck</DialogTitle>
        </DialogHeader>
        {!isLoading ? (
          <div className='mt-2 flex flex-col gap-4'>
            <Input
              isRequired
              id='name'
              label='Deck Name'
              placeholder='Deck Name'
              className='border-primary/50'
              value={formik.values['name']}
              onChange={formik.handleChange}
              errorMessage={formik.getFieldError('name')}
            />
            <div className='relative'>
              <Label className='border-primary/50 text-sm font-medium leading-[1.0625rem] text-primary'>
                Add Tag
              </Label>
              <div ref={wrapperRef}>
                <div className='mt-3 flex flex-wrap gap-2.5'>
                  {newTags?.map(({ id, name }) => (
                    <TagTooltip
                      id={id}
                      name={name}
                      handleRemoveTag={RemoveTags}
                    />
                  ))}
                </div>
                <Input
                  placeholder='Add Tag'
                  className='border-primary/50 bg-[#F2F6F8]'
                  onFocus={() => setIsInputFocused(true)}
                  value={inputValue}
                  onChange={(e) => handleInputChange(e)}
                />
                {isInputFocused && (
                  <div className='absolute mt-1.5 flex min-h-[129px] w-full flex-col gap-3 rounded-lg border border-primary bg-white p-3.5'>
                    <span className='flex items-center justify-between text-xs font-normal leading-3 text-primary'>
                      Select an option or create one
                      <X
                        onClick={() => setIsInputFocused(false)}
                        color='black'
                        height='15px'
                        width='15px'
                        className='cursor-pointer'
                      />
                    </span>
                    <div className='flex h-[5.625rem] flex-wrap gap-2 overflow-y-auto'>
                      {getTags?.data?.results?.length > 0 ? (
                        getTags?.data?.results?.map((tag) => (
                          <TagPills
                            pillsText={tag?.name}
                            onClick={() => addTags(tag)}
                            className='h-fit cursor-pointer'
                          />
                        ))
                      ) : (
                        <span>
                          create
                          <TagPills
                            id='name'
                            pillsText={tagFormik.values['name']}
                            onChange={tagFormik.handleChange}
                            onClick={tagFormik.handleSubmit}
                            className='h-fit cursor-pointer'
                          />
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}

        <div className='flex w-full justify-end'>
          <DialogPrimitive.Close>
            <Button className='w-[11.25rem]' onClick={formik.handleSubmit}>
              Save
            </Button>
          </DialogPrimitive.Close>
        </div>
      </DialogContent>
    </Dialog>
  );
}
