import { CreditCard, WalletCards, Proportions } from 'lucide-react';
import { NavLink, useLocation } from 'react-router-dom';
import { queryKeys, apiEndpoints, useGetQuery } from '@services';
import { useEffect, useState } from 'react';
import { HEADER } from '@constants/headerData';

export function Sidebar() {
  const [activeLink, setActiveLink] = useState('Cards');
  const location = useLocation();

  const {
    data: getCount,
    refetch: refetchCount,
    isFetching: isFetchingCount,
  } = useGetQuery(queryKeys?.HOME, apiEndpoints?.HOME);

  useEffect(() => {
    if (location?.pathname) {
      setActiveLink(HEADER[location?.pathname]);
      refetchCount();
    }
  }, [location]);

  const navLinks = [
    {
      id: 1,
      name: 'Cards',
      href: '/cards',
      icon: <CreditCard />,
      unread: getCount?.data?.cardCount,
    },
    {
      id: 2,
      name: 'Decks',
      href: '/decks',
      icon: <WalletCards />,
      unread: getCount?.data?.deckCount,
    },
    {
      id: 3,
      name: 'Rooms',
      href: '/rooms',
      icon: <Proportions />,
      unread: getCount?.data?.roomCount,
    },
  ];

  return (
    <div className='h-screen bg-primary px-6 py-[2.8125rem] lg:w-[15rem] 2xl:w-[19.375rem]'>
      <h1 className='text-[2rem] font-semibold leading-[2.375rem] text-white'>
        Mempals
      </h1>
      <ul className='mt-6 flex flex-col gap-2'>
        {navLinks.map((link, index) => (
          <NavLink
            key={link?.id}
            onClick={() => setActiveLink(link?.name)}
            to={link?.href}
            className='text-sm font-medium leading-6 text-white '
          >
            <li
              key={index}
              className={`flex cursor-pointer items-center justify-between rounded-lg  p-3 text-white ${
                activeLink === link?.name ? 'bg-btnPrimary' : ''
              }`}
            >
              <div className='flex gap-3'>
                {link?.icon}
                {link?.name}
              </div>

              {link.unread > 0
                ? (link?.name === 'Cards' ||
                    link?.name === 'Decks' ||
                    link?.name === 'Rooms') && (
                    <div
                      className={`${
                        link?.name === 'Cards'
                          ? 'bg-cardsCount'
                          : 'bg-decksCount'
                      } flex h-5 min-w-5 items-center justify-center rounded px-1.5 py-1 text-center text-[.625rem] font-semibold leading-3 text-secondary`}
                    >
                      {link?.unread}
                    </div>
                  )
                : ''}
            </li>
          </NavLink>
        ))}
      </ul>
    </div>
  );
}
