import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { backendBaseUrl, sessionUrl } from '../../../config';

const generateId = () => '_' + Math.random().toString(36).substr(2, 9);

const CustomToolbar = ({ id }) => (
  <div id={id}>
    <select className='ql-header' defaultValue=''>
      <option value='1'></option>
      <option value='2'></option>
      <option value=''></option>
    </select>
    <button className='ql-bold'></button>
    <button className='ql-italic'></button>
    <button className='ql-underline'></button>

    <button className='ql-strike'></button>
    <select className='ql-color'></select>
    <select className='ql-background'></select>
    <button className='ql-script' value='sub'></button>
    <button className='ql-script' value='super'></button>
    <button className='ql-blockquote'></button>
    <button className='ql-code-block'></button>
    <button className='ql-list' value='ordered'></button>
    <button className='ql-list' value='bullet'></button>
    <button className='ql-image'></button>
    <button className='ql-link'></button>
    <button className='ql-video'></button>
    <button className='ql-formula'></button>
    <button className='ql-clean'></button>
  </div>
);

const imageHandler = async (quill) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await axios.post(
          `${backendBaseUrl}learnings/image-create/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        const url = response?.data?.image;
        const range = quill.getSelection();
        quill.insertEmbed(range.index, 'image', url);
      } catch (error) {
        console.error('Error uploading image', error);
      }
    }
  };
};

const Editor = ({ data, setData, editorClassName }) => {
  const [value, setValue] = useState(data);
  const quillRef = useRef(null);
  const toolbarId = useRef(generateId()).current;

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const toolbar = quill.getModule('toolbar');
      toolbar.addHandler('image', () => imageHandler(quill));
    }
  }, []);

  const modules = {
    toolbar: {
      container: `#${toolbarId}`,
    },
  };

  return (
    <div>
      <CustomToolbar id={toolbarId} />
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={(content) => {
          setValue(content);
          setData(content);
        }}
        modules={modules}
        theme='snow'
        className={`${editorClassName}`}
      />
    </div>
  );
};

export default Editor;
