import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
} from '@components';
import { Trash2 } from 'lucide-react';
import React from 'react';

export function DeleteDialog({ name, type, handleDelete }) {
  return (
    <Dialog>
      <DialogTrigger>
        <Button
          variant='destructive'
          className='h-9 w-9 border-transparent bg-[#F5D9D9] p-0 hover:bg-[#F5D9D9]'
        >
          <Trash2 color='#EF4444' height={24} width={24} />
        </Button>
      </DialogTrigger>
      <DialogContent className='flex w-[450px] flex-col items-center gap-4 px-[76px] py-[62px] text-center'>
        <h1 className='text-2xl font-bold leading-5'>Delete {type}</h1>
        <p className='text-sm font-medium leading-5'>
          Do you want to delete {name}?
        </p>
        <DialogClose>
          <Button
            variant='destructive'
            className=' w-[180px] bg-borderDark'
            onClick={handleDelete}
          >
            Delete
          </Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
}
