import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
} from '@components';
import { SUCCESS } from '@constants';
import { apiEndpoints, queryKeys, usePostMutation } from '@services';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { useQueryClient } from '@tanstack/react-query';
import { useFormikForm } from '@common/hooks';
import { ROOM_NAME_INITIAL_VALUE } from '@constants';
import { roomValidationSchema } from '@common/validators/loginValidationSchema';
import { httpErrorHandler } from '@utils';

export function CreateRoomDialog() {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState();

  const handleCreate = () => {
    newRoomMutation({ payload: { name: formik.values['name'] } });
    setOpen(false);
  };

  const formik = useFormikForm(
    ROOM_NAME_INITIAL_VALUE,
    handleCreate,
    roomValidationSchema,
  );
  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: [queryKeys?.ROOM?.ROOMS] });
    queryClient.invalidateQueries({ queryKey: [queryKeys?.HOME] });
    toast.success(SUCCESS('New room created'));
  };
  const { mutate: newRoomMutation } = usePostMutation(
    queryKeys?.ROOM?.ROOMS,
    apiEndpoints?.ROOM?.CREATE,
    onSuccess,
    (err) =>
      httpErrorHandler({
        err: err?.response,
        errorKeys: ['name', 'non_field_errors'],
      }),
  );

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button className='w-[145px]'>Create Room</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Room</DialogTitle>
        </DialogHeader>
        <div className='mt-2 flex flex-col gap-4'>
          <Input
            isRequired
            id='name'
            label='Room Name'
            placeholder='Room Name'
            className='border-primary/50'
            value={formik.values['name']}
            errorMessage={formik.getFieldError('name')}
            onChange={formik.handleChange}
          />
        </div>
        <div className='mt-3.5 flex w-full justify-end'>
          {/* Todo: Button should be disalbled until both filds are filled */}
          <DialogPrimitive.Close>
            <Button
              type='submit'
              className='w-[11.25rem]'
              onClick={formik.handleSubmit}
            >
              Create
            </Button>
          </DialogPrimitive.Close>
        </div>
      </DialogContent>
    </Dialog>
  );
}
