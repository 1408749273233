export const queryKeys = {
  EMPTY: '',
  USERS: 'user',
  CARD: { CARDS: 'cards', TAGS: 'cardTags', DECKS: 'cardDecks' },
  HOME: 'home',
  DECK: {
    CARDS: 'deckCards',
    DECKS: 'decks',
    TAGS: 'deckTags',
    ROOM: 'deckRooms',
    DETAILS: 'details',
  },
  ROOM: {
    ROOMS: 'rooms',
    DECKS: 'roomDecks',
    DETAIL: 'roomDetail',
  },
  TAG: {
    TAGS: 'TAGS',
  },
};
