import { useState, useEffect } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { editorCommands } from '@constants/commands';
import MDEditor from '@uiw/react-md-editor';
import { usePostMutation, apiEndpoints, queryKeys } from '@services';

import Editor from './Editor';
import RenderHtml from './RenderHtml';

export function BackSide({ editMode, answer, setAnswer }) {
  const [imageUrl, setImageUrl] = useState('');
  const onSuccess = (data) => {
    setImageUrl(data?.data?.image);
  };
  const { mutate: getImageUrl } = usePostMutation(
    queryKeys?.EMPTY,
    apiEndpoints?.CARD?.CARD_IMAGE,
    onSuccess,
  );

  const uploadImageToServer = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    getImageUrl({
      payload: formData,
    });
    return imageUrl;
  };

  const handleImageUpload = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const imageUrl = await uploadImageToServer(file);
        if (imageUrl) {
          const markdownImageText = `![image](${imageUrl})`;
          setAnswer(answer + '\n' + markdownImageText);
        }
      }
    };
    input.click();
  };

  const customCommands = [
    {
      name: 'image',
      keyCommand: 'image',
      buttonProps: { 'aria-label': 'Insert image' },
      icon: <span>📷</span>,
      execute: (state, api) => {
        handleImageUpload();
      },
    },
  ];
  return (
    <div className='quillWrapper w-1/2 break-all pl-6'>
      <span className='text-base font-normal leading-[1.21rem] text-darkGrey'>
        Back Side
      </span>
      {editMode ? (
        <Editor
          data={answer}
          setData={setAnswer}
          editorClassName='h-[calc(100vh-312px)]'
        />
      ) : (
        <RenderHtml content={answer} renderClassName='h-[calc(100vh-240px)]' />
      )}
    </div>
  );
}
