import React from 'react';
import { TagPills } from '.';
import { Tooltip } from 'react-tooltip';

const CustomTooltip = ({ id, name }) => {
  return name.length > 15 ? (
    <div data-tooltip-id={`my-tooltip${id}`} data-tooltip-content={name}>
      {name?.substring(0, 15) + '...'}
      <Tooltip className='absolute top-10' place='top' id={`my-tooltip${id}`} />
    </div>
  ) : (
    name
  );
};

export default CustomTooltip;
