import React, { useEffect } from 'react';
import { TagPills } from '@common/components';
import { Card, CardContent, CardHeader } from '@components';
import RenderHtml from '@common/components/RenderHtml';
import TagTooltip from '@common/components/TagTooltip';

export function AnswerCard({ question, answer, role, tags }) {
  return (
    <Card className='relative top-5 flex h-[calc(100vh-374px)] w-[966px] rounded-3xl  border-sessionCardBorder bg-sessionCardsBg'>
      {answer && (
        <>
          <CardHeader className='flex h-full w-[36.5%] flex-col items-center gap-2 gap-y-2 overflow-y-auto border-r border-sessionCardBorder px-[1.875rem] pb-6 pt-[1.875rem] text-center'>
            <span className='text-base font-bold leading-5'>Question</span>

            {role === 'teacher' && (
              <div className='flex gap-2.5'>
                {tags?.map(({ id, name }) => (
                  <TagTooltip id={id} name={name} cancelable={false} />
                ))}
              </div>   
            )}

            <h1 className='mt-2 text-2xl font-semibold leading-[2rem]'>
              <RenderHtml content={question} />
            </h1>
          </CardHeader>
          <CardContent className='flex w-[63.5%] flex-col items-center justify-center gap-[.8125rem] px-[1.875rem] py-6 text-center'>
            <span className='text-base font-semibold leading-5'>Answer</span>
            <RenderHtml content={answer} />
          </CardContent>
        </>
      )}
    </Card>
  );
}
