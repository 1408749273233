import React from 'react';
import { Card } from '@components';
import RenderHtml from '@common/components/RenderHtml';

export function QuestionCard({ question }) {
  return (
    <Card className='absolute top-7 flex h-[calc(100vh-374px)] min-h-[16.25rem] w-[60.375rem] items-center justify-center rounded-3xl border-sessionCardBorder bg-sessionCardsBg text-center'>
      <span className='absolute left-[1.875rem] top-[1.875rem] text-base font-bold leading-5 text-primary'>
        Question
      </span>
      <p className='max-h-[10rem] max-w-[47rem] overflow-y-auto text-[2rem] font-semibold leading-[3.1875rem]'>
        {question && <RenderHtml content={question} />}
      </p>
    </Card>
  );
}
