import React from 'react';

export function Footer() {
  return (
    <div className=' border-footerBoder flex h-[4.1875rem] justify-center border-t py-[1.375rem]'>
      <p className=' text-base font-normal leading-[1.1875rem]'>
        Powered by{' '}
        <span className=' text-lg font-semibold leading-[1.375rem] text-btnPrimary'>
          MEMPALS
        </span>
      </p>
    </div>
  );
}
