import { useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from '@routes';
import { useSentry, useSetupAxios } from '@services';
import { AppContext } from '@useContext';
import './index.css';

function App() {
  useSetupAxios();
  useSentry();

  // eslint-disable-next-line no-unused-vars
  const [permissions, setPermissions] = useState(true);
  const [user, setUser] = useState({});
  const [count, setCount] = useState({});
  const [header, setHeader] = useState('Cards');
  const [backBtn, setBackBtn] = useState(false);
  const [roomLink, setRoomLink] = useState(null);
  const context = {
    user,
    setUser,
    count,
    setCount,
    permissions,
    header,
    setHeader,
    backBtn,
    setBackBtn,
    roomLink,
    setRoomLink,
  };

  return (
    <AppContext.Provider value={context}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
}

export default App;
