import React from 'react';
import { Footer, Header } from './index';
import { SessionEndedIcon } from '@assets/svgs';
import { Button } from '@components';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

export function SessionEnded() {
  const navigate = useNavigate();
  const { roomName, uuId } = useParams();
  const handleRefresh = () => {
    const path = generatePath('/session/:roomName/:uuId', {
      roomName: roomName,
      uuId: uuId,
    });
    navigate(path);
  };
  return (
    <div className='flex h-screen flex-col'>
      <Header room={roomName} />
      <div className='flex flex-grow flex-col items-center justify-center gap-y-4'>
        <SessionEndedIcon />
        <div className='space-y-2 text-center'>
          <h2 className='text-2xl font-bold leading-[1.875rem]'>Thank You!</h2>
          <p className='text-xl font-normal leading-6'>
            Session has been ended.
          </p>
          <Button className={`w-[11.25rem]`} onClick={handleRefresh}>
            Refresh
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
