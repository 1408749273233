import { useState, useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { PAGE_REGEX } from '@constants';
import { performGetRequest } from '@services/apiClient';
const useInfiniteScroll = ({
  apiUrl,
  key,
  params,
  isEnabled = true,
  onSuccess,
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const getNextPage = (lastPage) => {
    if (lastPage?.data?.next) {
      const match = lastPage?.data?.next.match(PAGE_REGEX);
      const pageNumber = match ? match[1] : 1;
      return pageNumber;
    }
  };
  const getListing = ({ pageParam = 1 }) =>
    performGetRequest({
      url: apiUrl,
      params: {
        page: pageParam,
        ...params,
      },
    });

  const {
    data: listing,
    isFetching: listFetching,
    isLoading: listLoading,
    refetch: listRefetch,
    fetchNextPage: listNextPage,
    hasNextPage: listHasNextPage,
  } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    retry: false,
    queryKey: [key],
    queryFn: getListing,
    getNextPageParam: getNextPage,
    enabled: isEnabled,
    cacheTime: 0,

    //onError: ({ response: { data } }) => setErrorMessage(data?.detail),
    onSuccess,
  });

  return {
    listing,
    listFetching,
    listLoading,
    listRefetch,
    listNextPage,
    listHasNextPage,
    errorMessage,
  };
};

export default useInfiniteScroll;
