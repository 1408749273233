import { Input, Button } from '@components';
import SignInImage from '@assets/images/SignInImage.png';
import {
  getLocalStorageItem,
  httpErrorHandler,
  setLocalStorageItem,
} from '@utils';
import { SIGN_IN_INITIAL_VALUE } from '@constants/fieldConstants';
import { LoginFormValidationSchema } from '@common/validators/loginValidationSchema';
import { apiEndpoints, queryKeys } from '@services';
import { useFormikForm } from '@common/hooks';
import { usePostMutation } from '@services/networkRequestService';
import { ACCESS_TOKEN } from '@constants';
import { AppContext } from '@useContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';

export default function SignIn() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AppContext);
  const [disable, setDisable] = useState(false);

  const onSignInSuccess = (response) => {
    const { access } = response?.data;
    setLocalStorageItem(ACCESS_TOKEN, access);
    setUser(response?.data);

    navigate('/cards');
    setDisable(false);
  };

  const onSignInError = (err) => {
    httpErrorHandler({
      err: err?.response,
      errorKeys: ['email', 'password'],
    });
    setDisable(false);
  };

  const { mutate: signInMutation } = usePostMutation(
    queryKeys?.USERS,
    apiEndpoints?.USER?.SIGN_IN,
    onSignInSuccess,
    onSignInError,
  );

  const handleSubmit = () => {
    setDisable(true);
    const data = {
      email: formik.values['email'],
      password: formik.values['password'],
    };
    signInMutation({ payload: data });
  };

  const formik = useFormikForm(
    SIGN_IN_INITIAL_VALUE,
    handleSubmit,
    LoginFormValidationSchema,
  );
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (getLocalStorageItem(ACCESS_TOKEN)) {
      navigate('/cards');
    }
  }, []);

  return (
    <div className='relative mx-auto flex min-h-screen max-w-[90rem] overflow-y-auto px-[1.375rem] py-5'>
      <div className='flex h-[inherit] w-1/2 flex-col items-center justify-center px-[1.125rem] py-2.5'>
        <h1 className='absolute left-10 top-[1.875rem] text-[2rem] font-semibold leading-[2.375rem] text-btnPrimary'>
          Mempals
        </h1>
        <form onSubmit={formik.handleSubmit}>
          <div className='flex h-full min-w-[26.25rem] flex-col items-center justify-center'>
            <div className='flex w-full flex-col gap-[1.125rem]'>
              <h2 className='text-3xl font-semibold leading-9 text-primary'>
                Sign In
              </h2>
              <p className='text-base leading-[1.1875rem] text-secondary/70'>
                Sign In to Access Your Account
              </p>
            </div>
            <div className='mt-10 w-full space-y-4'>
              <Input
                isRequired
                id='email'
                label='Email'
                value={formik.values['email']}
                onChange={formik?.handleChange}
                placeholder='example@hotmail.com'
                errorMessage={formik.getFieldError('email')}
              />
              <Input
                isRequired
                id='password'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                placeholder='************'
                value={formik.values['password']}
                onChange={formik.handleChange}
                errorMessage={formik.getFieldError('password')}
                tailIcon={
                  showPassword ? (
                    <EyeOff onClick={togglePasswordVisibility} />
                  ) : (
                    <Eye onClick={togglePasswordVisibility} />
                  )
                }
              />
            </div>
            <div className='mt-6 w-full'>
              <Button className='w-full' type='submit' disabled={disable}>
                Sign In
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className='relative flex h-[inherit] w-1/2 flex-col justify-between rounded-2xl bg-primary p-[3.75rem]'>
        <div></div>
        <div className='flex w-full justify-center'>
          <img src={SignInImage} />
        </div>
        <div className='flex flex-col gap-4'>
          <h2 className='text-3xl font-semibold leading-9 text-white'>
            Strengthening Learning Partnerships
          </h2>
          <p className='text-base font-light leading-6 text-white'>
            Mempals - Where Teachers and Students Connect for Learning Success.
          </p>
        </div>
      </div>
    </div>
  );
}
