import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@components';
import { TagPills } from '@common/components';
import { useEffect, useState } from 'react';
import { Loader } from '@common/components/Loader';
import TagTooltip from './TagTooltip';

export function CountPill({ count, onClick = () => {}, data, isLoading }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onClick();
  }, [open]);
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger className='flex h-[1.625rem] w-[1.625rem] items-center justify-center rounded-full border bg-btnPrimary text-[.625rem] font-medium leading-4 text-white focus-visible:outline-none'>
        {count}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align='end'
        className='flex w-[310px] flex-col gap-3 p-4 shadow-none'
      >
        <h6 className='text-xs font-medium leading-4 text-secondary'>
          All Tags
        </h6>
        {!isLoading ? (
          <div className='flex flex-wrap gap-x-2 gap-y-4'>
            {data?.map(({ id, name }) => (
              <TagTooltip id={id} name={name} cancelable={false} />
            ))}
          </div>
        ) : (
          <Loader />
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
