import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from 'react-router-dom';
import { Home } from '@pages/home';
import { Unauthorized } from '@pages/unauthorized';
import { PrivateRoute } from '@routes';
import { SignIn } from '@pages/auth/signin';
import { ROUTES } from './Routes';
import { Cards } from '@pages/cards/components';
import { Decks, DecksDetail } from '@pages/deck/components';
import { Layout } from '@common/components/Layout';
import { RoomDetail, Rooms } from '@pages/rooms/components';
import Session from '@pages/session/Session';
import { SessionEnded } from '@pages/session/components';
import Editor from '@common/components/Editor';

const createPrivateRoute = (Component) => {
  return (
    <PrivateRoute>
      <Component />
    </PrivateRoute>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={createPrivateRoute(Layout)}>
        {/* Nested routes under Layout */}
        <Route path='/' element={<Navigate to={ROUTES.CARDS} />} />
        <Route path={ROUTES.CARDS} element={<Cards />} />
        <Route path={ROUTES.DECKS} element={<Decks />}></Route>
        <Route path={ROUTES.ROOMS} element={<Rooms />} />
        <Route path={ROUTES.DECK_DETAILS} element={<DecksDetail />} />
        <Route path={ROUTES.ROOM_DETAILS} element={<RoomDetail />} />
      </Route>
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
      <Route path={ROUTES.SESSION} element={<Session />} />
      <Route path={ROUTES.UN_AUTHORIZED} element={<Unauthorized />} />
      <Route path={ROUTES.END_SESSION} element={<SessionEnded />} />
      <Route path={ROUTES.EDITOR} element={<Editor />} />
    </>,
  ),
  { basename: '/app' },
);
