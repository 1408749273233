import {
  Button,
  Checkbox,
  Input,
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
} from '@components';
import { Search } from 'lucide-react';
import { Plus } from 'lucide-react';
import { ContentCard, CardsPreview, EmptyCardListing } from './index';
import { apiEndpoints, queryKeys, useDeleteMutation } from '@services';
import { useEffect, useState, useContext } from 'react';
import useInfiniteScroll from '@common/hooks/useInfiniteScrool';
import { doInfiniteScroll } from '@utils';
import { AppContext } from '@useContext';
import { toast } from 'react-toastify';
import { SUCCESS } from '@constants';
import { QueryClient } from '@tanstack/react-query';

export function Cards() {
  const [activeCardId, setActiveCardId] = useState();
  const [selectedsearch, setSelectedSearch] = useState('');
  const [addnewCard, setAddNewCard] = useState(false);
  const [cards, setCards] = useState([]);
  const [disableMode, setDisableMode] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const { backBtn, setBackBtn, setRoomLink } = useContext(AppContext);
  const [deleteCards, setDeleteCards] = useState([]);
  const queryClient = new QueryClient();

  const onSuccessGetList = (res) => {
    if (!activeCardId) {
      setActiveCardId(res?.pages?.[0]?.data?.results?.[0]?.id);
    }
  };
  const { listing, listFetching, listRefetch, listNextPage, listHasNextPage } =
    useInfiniteScroll({
      apiUrl: apiEndpoints?.CARD?.CARDS,
      key: queryKeys?.CARD?.CARDS,
      params: { search: selectedsearch },
      onSuccess: onSuccessGetList,
    });

  const onSuccess = () => {
    toast.success(SUCCESS('Card(s) deleted'));
    setActiveCardId(null);
    listRefetch();
    setDeleteCards([]);
  };

  const { mutate: deleteDeckMutation } = useDeleteMutation(
    apiEndpoints?.CARD?.DELETE,
    onSuccess,
  );

  const handleChange = (e) => {
    setSelectedSearch(e.target.value);
  };

  useEffect(() => {
    setBackBtn(false);
    setRoomLink(null);
  }, []);

  useEffect(() => {
    listRefetch();
  }, [selectedsearch]);

  const addCard = () => {
    setAddNewCard(!addnewCard);
    setShowPreview(true);
  };

  useEffect(() => {
    if (activeCardId) {
      setAddNewCard(false);
      setShowPreview(true);
    }
  }, [activeCardId]);

  const handleScroll = (e) =>
    doInfiniteScroll(e, listHasNextPage, listFetching, listNextPage);

  const handleDelete = () => {
    const data = { object_ids: deleteCards };
    deleteDeckMutation({
      payload: data,
    });
  };

  const handleDeleteCards = (status, cardId) => {
    if (status) {
      const deleteItems = [...deleteCards, cardId];
      setDeleteCards(deleteItems);
    } else {
      const items = deleteCards.filter((card) => card !== cardId);
      setDeleteCards(items);
    }
  };
  const handleSelectAll = (status) => {
    if (status) {
      const items = listing?.pages?.flatMap((page) =>
        page?.data?.results?.map((deck) => deck?.id),
      );
      setDeleteCards(items);
    } else {
      setDeleteCards([]);
    }
  };

  return (
    <div className='flex h-[calc(100%-101px)] lg:w-[calc(100vw-15rem)] 2xl:w-[calc(100vw-310px)]'>
      <div
        className={`flex flex-col gap-2.5 border-r py-6 ${disableMode ? '	' : 'pointer-events-none opacity-50'}`}
      >
        <div className='border-primary/15 pl-10 pr-[1.125rem] '>
          <div className='flex flex-col gap-4'>
            <Input
              className='!h-[42px] !w-[18rem] !py-2.5'
              placeholder='Search Card'
              wrapperClassName='!gap-0'
              leadIcon={<Search color='#004672' height='18px' width='18px' />}
              onChange={(e) => handleChange(e)}
            />

            {deleteCards.length > 0 ? (
              <>
                <Dialog>
                  <DialogTrigger>
                    <Button
                      variant='destructive'
                      className=' h-[2.625rem] w-[18rem] border-transparent bg-[#E66767]'
                    >
                      Delete
                    </Button>
                  </DialogTrigger>
                  <DialogContent className='flex w-[450px] flex-col items-center gap-4 px-[76px] py-[62px] text-center'>
                    <h1 className='text-2xl font-bold leading-5'>
                      Delete card(s)
                    </h1>
                    <p className='text-sm font-medium leading-5'>
                      Do you want to delete card(s)?
                    </p>
                    <DialogClose>
                      <Button
                        variant='destructive'
                        className=' w-[180px] bg-borderDark'
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    </DialogClose>
                  </DialogContent>
                </Dialog>
                <div className='flex items-center gap-x-1.5'>
                  <Checkbox
                    className='!mt-0 h-3.5 w-3.5'
                    checkClassName='h-3 w-3'
                    onCheckedChange={(e) => handleSelectAll(e)}
                  />
                  <span className='text-sm font-medium leading-[.875rem]'>
                    Select all
                  </span>
                </div>
              </>
            ) : (
              <Button
                variant='ghost'
                className='flex h-[42px] w-[18rem] gap-2'
                onClick={addCard}
              >
                <Plus height='20px' width='20px' />
                New Card
              </Button>
            )}
          </div>
        </div>

        <div
          className='flex h-[calc(100%-120px)] flex-col gap-4 overflow-y-scroll pl-10 pr-[1.125rem]'
          onScroll={handleScroll}
        >
          {listing?.pages?.map((page) =>
            page?.data?.count === 0 ? (
              <EmptyCardListing />
            ) : (
              page?.data?.results?.map((card, index) => (
                <div
                  key={card?.id}
                  className={` ${index !== cards?.length - 1 ? 'border-b border-primary/15 pb-4' : ''}`}
                >
                  <ContentCard
                    data={card}
                    setActiveCardId={setActiveCardId}
                    deleteCards={deleteCards}
                    setDeleteCards={setDeleteCards}
                    handleDeleteCards={handleDeleteCards}
                  />
                </div>
              ))
            ),
          )}
        </div>
      </div>

      {showPreview ? (
        addnewCard ? (
          <CardsPreview
            activeCardId={null}
            newCard={addnewCard}
            setDisableMode={setDisableMode}
            setActiveCardId={setActiveCardId}
            setNewCard={setAddNewCard}
          />
        ) : (
          <CardsPreview
            key={activeCardId}
            activeCardId={activeCardId}
            newCard={false}
            setDisableMode={setDisableMode}
            setActiveCardId={setActiveCardId}
          />
        )
      ) : (
        ''
      )}
    </div>
  );
}
