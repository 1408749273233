import { toast } from 'react-toastify';
export const showErrorToast = ({ title, message }) => {
  toast.error(`${message}`);
};
export const httpErrorHandler = ({
  err,
  errorKeys,
  setFieldErrors,
  onCustomError,
}) => {
  onCustomError?.();

  const errorCases = {
    400: () => handleBadRequest(err, errorKeys, setFieldErrors),
    403: () =>
      showErrorToast({ title: 'Unauthorized', message: 'Permission denied' }),
    404: () =>
      showErrorToast({
        title: 'Not Found',
        message: 'Requested resource not found',
      }),
    500: () =>
      showErrorToast({
        title: 'Server Error',
        message: 'Internal Server Error',
      }),
    default: () =>
      showErrorToast({ title: 'Error', message: 'Something went wrong' }),
  };

  const handleCustomError = errorCases[err?.status] || errorCases.default;
  handleCustomError();
};

const handleBadRequest = (err, errorKeys, setFieldErrors) => {
  errorKeys?.forEach((key) => {
    const firstError = err?.data?.[key]?.[0];
    if (firstError) {
      showErrorToast({ title: key, message: firstError });
    }
  });
};
