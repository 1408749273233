import * as React from "react"
import { Label } from "./label";
import { cn } from "@utils";

const Input = React.forwardRef(
	(
		{
			className,
			type,
			label,
			errorMessage,
			leadIcon,
			tailIcon,
			handleTailIconClick,
			isRequired,
			forgotPassword,
			labelClassName,
			wrapperClassName,
			leadIconClassName,
			...props
		},
		ref
	) => {
		return (
			<div className='flex w-full flex-col'>
				<div className={`flex flex-col gap-2 ${wrapperClassName}`}>
					<Label className={cn('text-sm font-medium leading-[1.0625rem] text-primary',labelClassName)}>
						{label}
						{isRequired && <span className='text-sm font-medium leading-[1.0625rem] text-borderDark ml-1'>*</span>}
					</Label>
					<div className='relative'>
						<input
							type={type}
							className={cn(
								`h-10 w-full rounded-md border border-primary bg-white px-3 py-2.5 text-sm font-medium leading-5 text-tertiary placeholder:text-badgeGray read-only:border-readonlyBorder
               read-only:text-readonlyText focus:border-tertiary read-only:focus:border-pageBorder focus-visible:outline-none md:px-3 md:py-3 2xl:h-11 
               ${leadIcon ? '!pl-9' : ''}
              ${tailIcon ? 'pr-14 md:pr-16' : ''}
               `,
								className
							)}
							ref={ref}
							{...props}
						/>
						<span className={`absolute inset-y-0 start-0 flex cursor-pointer items-center ps-3 ${leadIconClassName}`}>{leadIcon}</span>
						<span
							onClick={handleTailIconClick}
							className='absolute end-0 top-2.5 flex cursor-pointer items-center pe-4 md:top-2'
						>
							{tailIcon}
						</span>
					</div>
				</div>
				{errorMessage && <p className='mt-1 select-none text-xs text-borderDark'>{errorMessage}</p>}
			</div>
		);
	}
);
Input.displayName = 'Input';

export { Input };
