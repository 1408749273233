import * as React from 'react';
import { cn } from '@utils';

const Textarea = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <textarea
		Unre
      className={cn(
        'focus-visible:transparent focus-visible:transparent flex min-h-[6rem] max-h-[12rem] placeholder:text-darkGrey w-full rounded-md  border border-none bg-white text-2xl font-semibold leading-8 text-secondary ring-offset-white placeholder:text-2xl placeholder:font-semibold placeholder:leading-8 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-300 resize-none overflow-hidden',
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
