import useInfiniteScroll from '@common/hooks/useInfiniteScrool';
import { EmptyScreen } from '@common/components';
import {
  Checkbox,
  ScrollArea,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@components';
import { EditRoomDialog } from '@pages/rooms/components/index';
import { apiEndpoints, queryKeys } from '@services';
import { doInfiniteScroll } from '@utils';
import { Copy, Check } from 'lucide-react';
import { Loader } from '@common/components/Loader';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import CustomTooltip from '@common/components/CustomToolTip';

export function RoomsTable({
  search,
  deleteRooms,
  setDeleteRooms,
  selectAll,
  setSelectAll,
}) {
  const [isCopied, setIsCopied] = useState(null);
  const {
    listing,
    listFetching,
    listLoading,
    listRefetch,
    listNextPage,
    listHasNextPage,
    errorMessage,
  } = useInfiniteScroll({
    apiUrl: apiEndpoints?.ROOM?.ROOMS,
    key: queryKeys?.ROOM?.ROOMS,
    params: { search: search },
  });

  const navigate = useNavigate();

  const handleScroll = (e) =>
    doInfiniteScroll(e, listHasNextPage, listLoading, listNextPage);

  const handleRowClick = (id) => {
    navigate(`/roomDetails/${id}`);
  };
  const copyToClipboard = async (e, link, id) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(link);
    setIsCopied(id);
    setTimeout(() => {
      setIsCopied(null);
    }, 1000);
  };
  useEffect(() => {
    listRefetch();
  }, [search]);

  const handleSelectAll = (status) => {
    setSelectAll(!selectAll);
    if (status) {
      const items = listing?.pages?.flatMap((page) =>
        page?.data?.results?.map((deck) => deck?.id),
      );
      setDeleteRooms(items);
    } else {
      setDeleteRooms([]);
    }
  };

  const handleDeleteRooms = (status, roomId) => {
    if (status) {
      const deleteItems = [...deleteRooms, roomId];
      setDeleteRooms(deleteItems);
    } else {
      const items = deleteRooms.filter((room) => room !== roomId);
      setDeleteRooms(items);
    }
  };

  return (
    <div className='h-[calc(100vh-220px)]'>
      {!listLoading ? (
        listing?.pages[0]?.data?.count > 0 ? (
          <div
            className='h-full overflow-y-auto rounded-lg border'
            onScroll={handleScroll}
          >
            <Table className='relative w-full'>
              <TableHeader className='sticky top-0 rounded-l-lg rounded-r-lg bg-white'>
                <TableRow>
                  <TableHead className='pl-7'>
                    <div className='flex items-center gap-2'>
                      <Checkbox
                        checked={selectAll}
                        onCheckedChange={(e) => handleSelectAll(e)}
                      />
                      Title
                    </div>
                  </TableHead>
                  <TableHead>No. of Decks</TableHead>
                  <TableHead>Last Session</TableHead>
                  <TableHead>Link</TableHead>
                  <TableHead className='pr-[33px]'></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {listing?.pages?.map((page) =>
                  page?.data?.results?.map((room, index) => (
                    <TableRow
                      key={index}
                      className='cursor-pointer'
                      onClick={() => handleRowClick(room?.id)}
                    >
                      <TableCell className='pl-7'>
                        <div className='flex items-center gap-2'>
                          <Checkbox
                            checked={deleteRooms?.includes(room?.id)}
                            onCheckedChange={(e) =>
                              handleDeleteRooms(e, room?.id)
                            }
                            onClick={(e) => e.stopPropagation()}
                          />
                          <CustomTooltip id={room?.id} name={room?.name} />
                        </div>
                      </TableCell>
                      <TableCell>{room?.deckCount}</TableCell>
                      <TableCell>
                        {room?.lastSession
                          ? moment(room?.lastSession)?.fromNow()
                          : '-'}
                      </TableCell>
                      {room?.url?.length > 15 ? (
                        <>
                          <TableCell
                            className='z-[999] flex items-center gap-[5px]'
                            data-tooltip-id={`my-tooltip${room?.id}`}
                            data-tooltip-content={room?.url}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {isCopied === room?.id ? (
                              <Check
                                color='green'
                                size='18px'
                                strokeWidth='1.5px'
                              />
                            ) : (
                              <Copy
                                color='#0167A7'
                                height='18px'
                                width='18px'
                                strokeWidth='1.5px'
                                className='cursor-pointer'
                                onClick={(e) =>
                                  copyToClipboard(e, room?.url, room?.id)
                                }
                              />
                            )}

                            {room?.url?.substring(0, 15) + '...'}
                          </TableCell>
                          <Tooltip
                            className='absolute top-10'
                            place='top'
                            id={`my-tooltip${room?.id}`}
                          />
                        </>
                      ) : (
                        <TableCell
                          className='z-[999] flex items-center gap-[5px]'
                          onClick={(e) => e.stopPropagation()}
                        >
                          {isCopied === room?.id ? (
                            <Check
                              color='green'
                              size='18px'
                              strokeWidth='1.5px'
                            />
                          ) : (
                            <Copy
                              color='#0167A7'
                              height='18px'
                              width='18px'
                              strokeWidth='1.5px'
                              className='cursor-pointer'
                              onClick={(e) =>
                                copyToClipboard(e, room?.url, room?.id)
                              }
                            />
                          )}

                          {room?.url}
                        </TableCell>
                      )}

                      <TableCell
                        className='pr-[33px]'
                        onClick={(e) => e.stopPropagation()}
                      >
                        <EditRoomDialog room={room} />
                      </TableCell>
                    </TableRow>
                  )),
                )}
              </TableBody>
            </Table>
          </div>
        ) : (
          <EmptyScreen description='"Oops! It looks like there are no rooms available at the moment. Start by creating your first room to get started!' />
        )
      ) : (
        <Loader />
      )}
    </div>
  );
}
