import { TagPills } from '@common/components';
import { X } from 'lucide-react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
} from '@components';
import { useEffect, useState, useRef } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import {
  queryKeys,
  apiEndpoints,
  usePostMutation,
  useGetQuery,
} from '@services';
import { useQueryClient } from '@tanstack/react-query';
import { DECK_CREATE_INITIAL_VALUE } from '@constants/fieldConstants';
import { useOnClickOutside } from '@common/hooks';
import {
  CreateDeckValidationSchema,
  tagValidationSchema,
} from '@common/validators/loginValidationSchema';
import { httpErrorHandler } from '@utils';
import { useFormikForm } from '@common/hooks';
import { toast } from 'react-toastify';
import { SUCCESS, TAG_NAME_INITIAL_VALUE } from '@constants';
import TagTooltip from '@common/components/TagTooltip';

export function CreateDeckDialog() {
  const [inputValue, setInputValue] = useState('');
  const [name, setName] = useState('');
  const [newTags, setNewTags] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const onSuccess = () => {
    toast.success(SUCCESS('Deck created'));
    queryClient.invalidateQueries({ queryKey: [queryKeys?.DECK?.DECKS] });
    queryClient.invalidateQueries({ queryKey: [queryKeys?.HOME] });
  };
  const { mutate: NewDeckMutation } = usePostMutation(
    queryKeys?.DECK?.DECKS,
    apiEndpoints?.DECK?.CREATE,
    onSuccess,
    (err) =>
      httpErrorHandler({
        err: err?.response,
        errorKeys: ['name', 'tags', 'non_field_errors'],
      }),
  );

  const handleNewDeck = () => {
    const allIds = newTags.map((item) => item.id);
    NewDeckMutation({ payload: { name: formik.values['name'], tags: allIds } });
    setOpen(false);
  };

  const formik = useFormikForm(
    DECK_CREATE_INITIAL_VALUE,
    handleNewDeck,
    CreateDeckValidationSchema,
  );

  const onSuccessTag = (data) => {
    setNewTags([...newTags, data?.data]);
  };

  const { mutate: tagCreateMutation } = usePostMutation(
    queryKeys?.TAG?.TAGS,
    apiEndpoints?.TAG?.NEW_TAG,
    onSuccessTag,
  );

  useEffect(() => {
    setNewTags([]);
  }, []);

  const {
    data: getTags,
    refetch: refetchTags,
    isFetching: fetchingTags,
  } = useGetQuery(queryKeys?.TAG, apiEndpoints?.DECK?.TAGS, {
    search: inputValue,
  });

  useEffect(() => {
    refetchTags();
  }, [inputValue]);

  const addTags = (item) => {
    setNewTags([...newTags, item]);
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleCreateNewTag = () => {
    tagCreateMutation({
      payload: {
        name: tagFormik?.values['name'],
      },
    });
    setInputValue('');
  };

  const tagFormik = useFormikForm(
    TAG_NAME_INITIAL_VALUE,
    handleCreateNewTag,
    tagValidationSchema,
  );
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, () => setIsInputFocused(false));

  const RemoveTags = (id) => {
    setNewTags((prevItems) => prevItems.filter((item) => item.id !== id));
  };
  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setNewTags([]);
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button className='h-[42px] w-[145px]'>Create Deck</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Deck</DialogTitle>
        </DialogHeader>
        <div className='mt-2 flex flex-col gap-4'>
          <Input
            isRequired
            id='name'
            label='Deck Name'
            placeholder='Deck Name'
            className='border-primary/50'
            value={formik.values['name']}
            errorMessage={formik.getFieldError('name')}
            onChange={formik.handleChange}
          />
          <div>
            <div className='relative'>
              <Label className='border-primary/50 text-sm font-medium leading-[1.0625rem] text-primary'>
                Add Tag
              </Label>
              <div ref={wrapperRef}>
                <div className='mt-3 flex flex-wrap gap-2.5'>
                  {newTags?.map(({ id, name }) => (
                    <TagTooltip
                      id={id}
                      name={name}
                      handleRemoveTag={RemoveTags}
                    />
                  ))}
                </div>
                <Input
                  id='tag'
                  placeholder='Add Tag'
                  className='border-primary/50 bg-[#F2F6F8]'
                  onFocus={() => setIsInputFocused(true)}
                  value={inputValue}
                  errorMessage={tagFormik.getFieldError('name')}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    tagFormik?.setFieldValue('name', e.target.value);
                  }}
                />
                {isInputFocused && (
                  <div className='absolute mt-1.5 flex min-h-[129px] w-full flex-col gap-3 rounded-lg border border-primary bg-white p-3.5'>
                    <span className='flex items-center justify-between text-xs font-normal leading-3 text-primary'>
                      Select an option or create one
                      <X
                        onClick={() => setIsInputFocused(false)}
                        color='black'
                        height='15px'
                        width='15px'
                        className='cursor-pointer'
                      />
                    </span>

                    <div className='flex h-[5.625rem] flex-wrap gap-2 overflow-y-auto'>
                      {getTags?.data?.results?.length > 0 ? (
                        getTags?.data?.results?.map((tag) => (
                          <TagPills
                            pillsText={tag?.name}
                            onClick={() => addTags(tag)}
                            className='h-5 cursor-pointer'
                          />
                        ))
                      ) : (
                        <span>
                          create
                          <TagPills
                            id='name'
                            pillsText={tagFormik.values['name']}
                            onChange={tagFormik.handleChange}
                            onClick={tagFormik.handleSubmit}
                            className='cursor-pointer'
                          />
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex w-full justify-end'>
          {/* Todo: Button should be disalbled until both fields are filled */}
          <DialogPrimitive.Close>
            <Button className='w-[11.25rem]' onClick={formik.handleSubmit}>
              Create
            </Button>
          </DialogPrimitive.Close>
        </div>
      </DialogContent>
    </Dialog>
  );
}
