import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
} from '@components';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { apiEndpoints, queryKeys, useUpdateMutation } from '@services';
import { useQueryClient } from '@tanstack/react-query';
import { Ellipsis } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useFormikForm } from '@common/hooks';
import { ROOM_NAME_INITIAL_VALUE, SUCCESS } from '@constants';
import { roomValidationSchema } from '@common/validators/loginValidationSchema';
import { toast } from 'react-toastify';
import { httpErrorHandler } from '@utils';

export function EditRoomDialog({ room }) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState();
  const [name, setName] = useState('');
  const handleEdit = () => {
    updateRoomMutation({ payload: { name: formik.values['name'] } });
    setOpen(false);
  };

  const formik = useFormikForm(
    ROOM_NAME_INITIAL_VALUE,
    handleEdit,
    roomValidationSchema,
  );
  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: queryKeys?.ROOM?.ROOMS });
    toast.success(SUCCESS('Room updated'));
  };

  const onError = (err) => {
    httpErrorHandler({
      err: err?.response,
      errorKeys: ['name'],
    });
  };
  const { mutate: updateRoomMutation } = useUpdateMutation(
    apiEndpoints?.ROOM?.UPDATE(room?.id),
    onSuccess,
    onError,
  );

  useEffect(() => {
    if (open) {
      formik.setFieldValue('name', room?.name);
    } else {
      setName('');
    }
  }, [open]);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Ellipsis color='#17294A' />
      </DialogTrigger>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>Edit Room</DialogTitle>
        </DialogHeader>
        <div className='mt-2 flex flex-col gap-4'>
          <Input
            isRequired
            id='name'
            label='Room Name'
            placeholder='Room Name'
            className='border-primary/50'
            value={formik.values['name']}
            onChange={formik.handleChange}
            errorMessage={formik.getFieldError('name')}
          />
        </div>
        <div className='mt-3.5 flex w-full justify-end'>
          {/* Todo: Button should be disalbled until both filds are filled */}
          <DialogPrimitive.Close>
            <Button className='w-[11.25rem]' onClick={formik.handleSubmit}>
              Done
            </Button>
          </DialogPrimitive.Close>
        </div>
      </DialogContent>
    </Dialog>
  );
}
