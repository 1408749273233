import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@components';
import { RatingAlert } from './RatingAlert';

export function Header({
  role,
  room,
  showToaster,
  setShowToaster,
  closeSocket,
}) {
  const closeToaster = () => {
    setShowToaster(false);
  };

  return (
    <div className='flex items-center justify-between bg-header-background bg-cover px-[5.6875rem] py-6'>
      {showToaster && <RatingAlert onClose={closeToaster} />}
      <h1 className='text-[1.875rem] font-semibold leading-[2.25rem] text-white'>
        {room?.replace(/_/g, ' ')} Classroom
      </h1>

      {role === 'teacher' ? (
        <Dialog>
          <DialogTrigger className='text-base font-semibold leading-5 text-borderDark'>
            <Button variant='destructive' className='h-[52px] w-[12.875rem]'>
              End session
            </Button>
          </DialogTrigger>
          <DialogContent className='flex h-[208px] w-[346px] flex-col items-center py-11'>
            <DialogHeader>
              <DialogTitle>End Session?</DialogTitle>
            </DialogHeader>
            <p className='text-sm font-medium leading-5'>
              Are you sure you want to end session?
            </p>
            <div className='flex w-full justify-center'>
              <DialogClose>
                <Button
                  variant='destructive'
                  className='w-[11.25rem]'
                  onClick={() => closeSocket()}
                >
                  End session
                </Button>
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <div></div>
      )}
    </div>
  );
}
