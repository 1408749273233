import React from 'react';
import {
  Avatar,
  AvatarFallback,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@components';
import { ArrowLeft, ChevronDown, Link2 } from 'lucide-react';
import { LogoutDialog } from '.';
import { AppContext } from '@useContext';
import { useState, useEffect } from 'react';
import { HEADER } from '@constants/headerData';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGetQuery, queryKeys, apiEndpoints } from '@services';
import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { Offline } from 'react-detect-offline';
import { Check } from 'lucide-react';

export function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [isCopied, setIsCopied] = useState();
  const { user, setUser, header, setHeader, backBtn, roomLink } =
    useContext(AppContext);

  useEffect(() => {
    if (location?.pathname) {
      setHeader(HEADER[location.pathname]);
    }
  }, [location]);

  const {
    data,
    isFetching: isUserFetching,
    refetch: refetchUser,
  } = useGetQuery(
    queryKeys?.USERS,
    apiEndpoints?.USER?.PROFILE,
    {},
    {
      onSuccess,
      refetchOnWindowFocus: false,
    },
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(roomLink);
    setIsCopied(roomLink);
    setTimeout(() => {
      setIsCopied(null);
    }, 1000);
  };

  function onSuccess(response) {
    setUser(response?.data);
  }
  useEffect(() => {
    !isEmpty(user) &&
      setName(
        user?.firstName?.[0]
          ? user?.firstName?.[0] + user?.lastName?.[0]
          : user?.email?.[0],
      );
  }, [user]);
  return (
    <div className='flex items-center justify-between border-b border-borderPrimary px-10 py-[1.625rem]'>
      <div className='flex items-center gap-4'>
        {backBtn && (
          <ArrowLeft className='cursor-pointer' onClick={handleGoBack} />
        )}

        <h1 className='text-2xl font-bold leading-[1.875rem]'>{header}</h1>
        {roomLink ? (
          !isCopied ? (
            <Link2
              color='#0167A7'
              className='h-6 w-6 rotate-[135deg] cursor-pointer'
              onClick={copyToClipboard}
            />
          ) : (
            <Check color='green' size='18px' strokeWidth='1.5px' />
          )
        ) : (
          ''
        )}
      </div>
      <div className='text-lg font-bold'>
        <Offline>You are offline!</Offline>
      </div>
      <div>
        <DropdownMenu className='relative' open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild className='cursor-pointer'>
            <div className='flex items-center gap-4'>
              <Avatar className='h-12 w-12'>
                <div className='flex w-full items-center justify-center rounded-full bg-tertiary'>
                  <AvatarFallback className='text-xs font-bold leading-[.9375rem] text-white'>
                    {name}
                  </AvatarFallback>
                </div>
              </Avatar>{' '}
              <div>
                <ChevronDown
                  color='#004672'
                  className='h-3.5 w-3.5 lg:h-5 lg:w-5 2xl:h-6 2xl:w-6'
                />
              </div>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end' className='w-[13.375rem]'>
            <DropdownMenuItem className='flex flex-col items-start border-b px-4 pb-4 pt-6'>
              <h2 className='text-base font-semibold leading-5 text-primary'>
                {user?.firstName} {user?.lastName}
              </h2>
              <p className='break-all text-sm font-medium leading-5 text-primaryLight'>
                {user?.email}
              </p>
            </DropdownMenuItem>
            <LogoutDialog />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
