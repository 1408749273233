import { AddCards } from '@assets/svgs';

export function EmptyCardListing() {
  return (
    <div className='mt-[8.9375rem] flex h-full flex-col items-center gap-5'>
      <AddCards />
      <div className='flex flex-col items-center gap-2'>
        <h1 className='text-xl font-bold leading-6 text-secondary'>Add Card</h1>
        <p className='text-sm font-normal leading-[1.0625rem] text-secondary'>
          Add cards to keep going
        </p>
      </div>
    </div>
  );
}
