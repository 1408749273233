import { Loader } from '@common/components/Loader';
import React from 'react';

export function ConnectionLost() {
  return (
    <div className='pointer-events-none absolute top-0 flex h-screen w-full items-center justify-center gap-2.5 bg-[#17294AB2] text-center text-xl font-semibold leading-6 text-white'>
      <Loader
        borderColor='#fff'
        className='!h-[1.125rem] !w-[1.125rem] border-[1.5px]'
      />
      You lost your network connection. Trying to reconnect.
    </div>
  );
}
