import React from 'react';
import { Star } from 'lucide-react';

export function Rating({
  totalStars = 5,
  filledStars = 4,
  role,
  showAnswer,
  setRaiting,
  setUpdateRating,
}) {
  const handleRating = (index) => {
    if (role === 'teacher') {
      setRaiting(index + 1);
      setUpdateRating(index + 1);
    }
  };
  const stars = [1, 2, 3, 4, 5].map((_, index) => (
    <Star
      key={index}
      height='48px'
      width='48px'
      fill={index < filledStars ? '#F2CB00' : '#DEDEDE'}
      strokeWidth='0px'
      className='cursor-pointer'
      onClick={() => handleRating(index)}
    />
  ));

  return (
    <>
      {role === 'teacher' ? (
        <div className='flex w-[22.5625rem] gap-3.5 rounded-md border border-footerBoder px-[1.5625rem] py-2'>
          {stars}
        </div>
      ) : showAnswer ? (
        <div className='flex w-[22.5625rem] gap-3.5 rounded-md border border-footerBoder px-[1.5625rem] py-2'>
          {stars}
        </div>
      ) : (
        ''
      )}
    </>
  );
}
