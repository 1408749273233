import { useEffect, useState } from 'react';

import { CustomListPopover } from '@common/components';

export function DeckRooms({
  handleRooms,
  data,
  updateDeckMutation,
  isLoading,
  handleScroll,
  search,
  setSearch,
  checkBoxDisable = false,
  setCheckBoxDisable,
}) {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    const allSelectedIds = data?.pages?.flatMap((page) =>
      page?.data?.results
        ?.filter((room) => room?.isSelected)
        ?.map((room) => room?.id),
    );

    setRooms(allSelectedIds);
  }, [data]);

  const handleCheckedChange = (value, id) => {
    setCheckBoxDisable(true);
    if (value) {
      setRooms([...rooms, id]);
      updateDeckMutation({ payload: { newRooms: [id] } });
    } else {
      setRooms((currentIds) => currentIds?.filter((roomId) => roomId !== id));
      updateDeckMutation({ payload: { toRemoveRooms: [id] } });
    }
  };

  return (
    <CustomListPopover
      name={'Room'}
      data={data}
      search={search}
      setSearch={setSearch}
      handleOpen={handleRooms}
      handleScroll={handleScroll}
      selectedItems={rooms}
      updateItem={handleCheckedChange}
      isLoading={isLoading}
      checkBoxDisable={checkBoxDisable}
    />
  );
}
