import {
  Checkbox,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@components';
import { ChevronDown, Search } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Loader } from './Loader';

export function CustomListPopover({
  name,
  data,
  search,
  setSearch,
  selectedItems,
  updateItem,
  handleScroll,
  checkBoxDisable = false,
  handleOpen = () => {},
  isLoading = false,
}) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!open) {
      setSearch('');
    }
  }, [open]);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className='flex items-center gap-x-1 rounded-md border border-btnPrimary px-3 py-1.5'
        onClick={(e) => {
          e.stopPropagation();
          handleOpen();
        }}
      >
        <span className='text-sm font-medium leading-5'>Add to {name}</span>
        <ChevronDown color='#0167A7' height={12} width={12} />
      </PopoverTrigger>
      <PopoverContent
        className='max-w-[200px] p-0'
        align='end'
        onClick={(e) => e.stopPropagation()}
      >
        <div className=' border-b p-[5px]'>
          <Input
            placeholder='Search'
            leadIcon={<Search color='#004672' height={18} width={18} />}
            className='!h-8 border-0 !py-1.5 !pl-8'
            wrapperClassName='!gap-0'
            leadIconClassName='ps-2'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div
          className='h-[147px] overflow-y-auto p-[5px]'
          onScroll={handleScroll}
        >
          {isLoading ? (
            <Loader />
          ) : data?.pages[0]?.data?.count > 0 ? (
            data?.pages?.map((page) =>
              page?.data?.results?.map((item) => (
                <div className='flex gap-2 rounded-sm p-2'>
                  <Checkbox
                    className='mt-0.5'
                    onCheckedChange={(e) => updateItem(e, item?.id)}
                    checked={selectedItems?.includes(item?.id)}
                    disabled={checkBoxDisable}
                  />
                  <p className='text-sm font-normal leading-5 text-secondary '>
                    {item?.name}
                  </p>
                </div>
              )),
            )
          ) : (
            <div className='h-available flex items-center justify-center text-xs'>
              No {name} available
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}
