import React, { useContext } from 'react';
import { CustomListPopover, EmptyScreen } from '@common/components';
import { Loader } from '@common/components/Loader';
import useInfiniteScroll from '@common/hooks/useInfiniteScrool';
import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@components';
import { SUCCESS } from '@constants';
import {
  apiEndpoints,
  queryKeys,
  useGetQuery,
  useUpdateMutation,
} from '@services';
import { generatePath } from 'react-router';

import { useQueryClient } from '@tanstack/react-query';
import { doInfiniteScroll } from '@utils';
import { ChevronDown, Search } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomTooltip from '@common/components/CustomToolTip';
import { AppContext } from '@useContext';

export function RoomDetail() {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState('');
  const [decksearch, setDeckSearch] = useState('');
  const [selectedDecks, setSelectedDecks] = useState([]);
  const { setHeader, setBackBtn, setRoomLink } = useContext(AppContext);
  const [checkBoxDisable, setCheckBoxDisable] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();
  const {
    listing,
    listFetching,
    listRefetch,
    listNextPage,
    listHasNextPage,
    errorMessage,
  } = useInfiniteScroll({
    apiUrl: apiEndpoints?.ROOM?.DETAIL,
    key: queryKeys?.ROOM?.DECKS,
    params: { room: id, search: search },
  });

  const {
    listing: roomDecks,
    listFetching: roomDecksFetching,
    listRefetch: roomDecksRefetch,
    listNextPage: roomDecksNextPage,
    listHasNextPage: roomDecksHasNextPage,
  } = useInfiniteScroll({
    apiUrl: apiEndpoints?.ROOM?.DECKS,
    key: queryKeys?.ROOM?.roomDecks + 'detail',
    params: { room: id, search: decksearch },
  });

  const {
    data: getRoomDetails,
    isFetching: isFetchingRoomDetails,
    refetch: refetchRoomDetails,
  } = useGetQuery(
    queryKeys?.ROOM?.DETAIL,
    apiEndpoints?.ROOM?.DETAILS(id),
    {},
    { enabled: !!id },
  );

  const handleRoomScroll = (e) =>
    doInfiniteScroll(
      e,
      roomDecksHasNextPage,
      roomDecksFetching,
      roomDecksNextPage,
    );

  const onSuccessUpdate = () => {
    setCheckBoxDisable(false);

    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: queryKeys?.ROOM?.DECKS });
    }, 1000);

    toast.success(SUCCESS('Room updated'));
  };
  const onErrorUpdate = () => {
    setCheckBoxDisable(false);
    toast.error('Somthiong went Wrong');
  };

  useEffect(() => {
    roomDecksRefetch();
  }, [decksearch]);

  const { mutate: updateRoomMutation } = useUpdateMutation(
    apiEndpoints?.ROOM?.UPDATE(id),
    onSuccessUpdate,
    onErrorUpdate,
  );

  const handleScroll = (e) =>
    doInfiniteScroll(e, listHasNextPage, listFetching, listNextPage);

  const handleRowClick = (id) => {
    const path = generatePath('/details/:id', {
      id: id,
    });
    navigate(path);
  };

  useEffect(() => {
    if (getRoomDetails) {
      setHeader(getRoomDetails?.data?.name);
      setRoomLink(getRoomDetails?.data?.url);
      setBackBtn(true);
    }
  }, [getRoomDetails]);

  useEffect(() => {
    const allSelectedIds = roomDecks?.pages?.flatMap((page) =>
      page?.data?.results
        ?.filter((room) => room.isSelected)
        .map((room) => room.id),
    );
    setSelectedDecks(allSelectedIds);
  }, [roomDecks]);

  const handleCheckedChange = (value, id) => {
    setCheckBoxDisable(true);

    if (value) {
      setSelectedDecks([...selectedDecks, id]);
      updateRoomMutation({ payload: { newDecks: [id] } });
    } else {
      setSelectedDecks((selectedDecks) =>
        selectedDecks.filter((deckId) => deckId !== id),
      );
      updateRoomMutation({ payload: { toRemoveDecks: [id] } });
    }
  };

  const startSession = (e, deckId) => {
    window.location.href =
      getRoomDetails?.data?.url + `?roomId=${id}&deckId=${deckId}`;
  };

  useEffect(() => {
    listRefetch();
  }, [search]);

  return (
    <>
      <div className='flex items-center justify-between py-6 pl-[38px] pr-10'>
        <h1 className='text-2xl font-bold leading-[29px] text-secondary'>
          All Decks
        </h1>
        <div className='flex items-center gap-4'>
          <div className='w-[228px]'>
            <Input
              labelClassName='hidden'
              placeholder='Search Deck'
              className='2xl:h-10'
              leadIcon={<Search color='#004672' height='18px' width='18px' />}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <CustomListPopover
            name={'Deck(s)'}
            data={roomDecks}
            search={decksearch}
            setSearch={setDeckSearch}
            handleScroll={handleRoomScroll}
            selectedItems={selectedDecks}
            updateItem={handleCheckedChange}
            checkBoxDisable={checkBoxDisable}
          />
        </div>
      </div>
      <div className='h-[calc(100vh-220px)] pl-[38px] pr-10'>
        {!listFetching ? (
          listing?.pages[0]?.data?.count > 0 ? (
            <div
              className='h-full !overflow-y-auto rounded-lg border'
              onScroll={handleScroll}
            >
              <Table className='relative w-full'>
                <TableHeader className='sticky top-0 rounded-l-lg rounded-r-lg bg-white'>
                  <TableRow>
                    <TableHead className='pl-10'>Title</TableHead>
                    <TableHead className='pl-20'>No. of Cards</TableHead>
                    <TableHead className='items-center pl-20'>
                      Progress %
                    </TableHead>
                    <TableHead className='pl-20 !text-start'>
                      Last Studied
                    </TableHead>
                    <TableHead className='pl-20 pr-[33px]'>Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {listing?.pages?.map((page) =>
                    page?.data?.results.map((deck, index) => (
                      <TableRow
                        key={index}
                        onClick={() => handleRowClick(deck?.id)}
                        className='cursor-pointer'
                      >
                        <TableCell className='pl-10'>
                          <CustomTooltip
                            id={deck?.id}
                            name={`📚 ${deck?.name}`}
                          />
                        </TableCell>
                        <TableCell className=' pl-20'>
                          {deck?.cardCount}
                        </TableCell>
                        <TableCell className='pl-20'>
                          {deck?.progressPercentage?.toFixed(1)}
                        </TableCell>
                        <TableCell className='pl-20'>
                          {moment(deck?.modified)?.fromNow()}
                        </TableCell>
                        <TableCell
                          className='pl-20 pr-[33px]'
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div>
                            <Button
                              variant='outline'
                              size='xs'
                              className='w-[120px] border-[#339A37] hover:bg-[#339A37] hover:text-white disabled:border-gray-400 disabled:text-gray-400 disabled:opacity-100'
                              onClick={(e) => startSession(e, deck?.id)}
                              disabled={deck?.cardCount < 1}
                            >
                              Start Session
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    )),
                  )}
                </TableBody>
              </Table>
            </div>
          ) : (
            <EmptyScreen description='Oops! It looks like there are no deck available in this room at the moment. Start by adding your first deck to get started!' />
          )
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}
