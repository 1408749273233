import { Input } from '@components';
import { Search } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { DeleteDialog, EmptyScreen } from '@common/components';
import { RoomsTable } from './RoomsTable';
import { CreateRoomDialog, RoomDetail } from '@pages/rooms/components/index';
import { useContext } from 'react';
import { AppContext } from '@useContext';
import { apiEndpoints, queryKeys, useDeleteMutation } from '@services';
import { toast } from 'react-toastify';
import { SUCCESS } from '@constants';
import { useQueryClient } from '@tanstack/react-query';

export function Rooms() {
  const [search, setSearch] = useState('');
  const { setBackBtn, setRoomLink } = useContext(AppContext);
  const [deleteRooms, setDeleteRooms] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    setBackBtn(false);
    setRoomLink(null);
  }, []);
  const onSuccess = () => {
    toast.success(SUCCESS('Room(s) deleted'));
    queryClient.invalidateQueries({ queryKey: queryKeys?.ROOM?.ROOMS });
    setDeleteRooms([]);
    setSelectAll(false);
  };
  const { mutate: deleteRoomMutation } = useDeleteMutation(
    apiEndpoints?.ROOM?.DELETE,
    onSuccess,
  );

  const handleDelete = () => {
    deleteRoomMutation({
      payload: { object_ids: deleteRooms },
    });
  };

  return (
    <div className='h-[calc(100%-101px)] space-y-6 px-10 py-6 lg:w-[calc(100vw-240px)] 2xl:w-[calc(100vw-310px)]'>
      <div className='flex items-center justify-between'>
        <h1 className='text-2xl font-bold leading-[29px] text-secondary'>
          All Rooms
        </h1>
        {deleteRooms.length > 0 ? (
          <DeleteDialog
            type='Room(s)'
            name='Room(s)'
            handleDelete={handleDelete}
          />
        ) : (
          <div className='flex items-center gap-4'>
            <div className='w-[228px]'>
              <Input
                labelClassName='hidden'
                placeholder='Search Room'
                className='2xl:h-10'
                leadIcon={<Search color='#004672' height='18px' width='18px' />}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <CreateRoomDialog />
          </div>
        )}
      </div>
      {/* <EmptyScreen description='"Oops! It looks like there are no room available at the moment. Start by creating your first room to get started!' /> */}
      <RoomsTable
        search={search}
        deleteRooms={deleteRooms}
        setDeleteRooms={setDeleteRooms}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
      />
    </div>
  );
}
