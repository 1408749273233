export default function NoDataIcon() {
  return (
    <svg
      width='243'
      height='216'
      viewBox='0 0 243 216'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_283_5227)'>
        <path d='M274 188.029H-32V188.182H274V188.029Z' fill='#EBEBEB' />
        <path
          d='M243.339 197.876H223.069V198.029H243.339V197.876Z'
          fill='#EBEBEB'
        />
        <path
          d='M170.707 199.541H165.388V199.694H170.707V199.541Z'
          fill='#EBEBEB'
        />
        <path
          d='M222.457 192.196H210.713V192.349H222.457V192.196Z'
          fill='#EBEBEB'
        />
        <path
          d='M26.5378 193.225H0.105469V193.378H26.5378V193.225Z'
          fill='#EBEBEB'
        />
        <path
          d='M35.8647 193.225H31.9907V193.378H35.8647V193.225Z'
          fill='#EBEBEB'
        />
        <path
          d='M105.792 195.807H48.4596V195.96H105.792V195.807Z'
          fill='#EBEBEB'
        />
        <path
          d='M113.044 160.734H-5.12708C-6.05282 160.732 -6.94009 160.363 -7.59412 159.708C-8.24815 159.053 -8.61548 158.165 -8.61548 157.239V-8.87592C-8.60742 -9.79636 -8.23653 -10.6764 -7.58338 -11.325C-6.93022 -11.9736 -6.04754 -12.3383 -5.12708 -12.3398H113.044C113.971 -12.3398 114.86 -11.9717 115.515 -11.3163C116.17 -10.661 116.539 -9.77213 116.539 -8.84532V157.239C116.539 158.166 116.17 159.055 115.515 159.71C114.86 160.366 113.971 160.734 113.044 160.734ZM-5.12708 -12.2174C-6.01224 -12.2158 -6.8606 -11.8631 -7.48593 -11.2366C-8.11127 -10.6101 -8.46248 -9.76109 -8.46248 -8.87592V157.239C-8.46248 158.124 -8.11127 158.973 -7.48593 159.6C-6.8606 160.226 -6.01224 160.579 -5.12708 160.581H113.044C113.93 160.579 114.779 160.227 115.405 159.6C116.031 158.974 116.384 158.125 116.386 157.239V-8.87592C116.384 -9.76165 116.031 -10.6106 115.405 -11.2369C114.779 -11.8633 113.93 -12.2158 113.044 -12.2174H-5.12708Z'
          fill='#EBEBEB'
        />
        <path
          d='M245.426 160.734H127.249C126.322 160.732 125.434 160.363 124.779 159.708C124.124 159.053 123.756 158.166 123.754 157.239V-8.87592C123.764 -9.79691 124.136 -10.677 124.79 -11.3254C125.444 -11.9738 126.327 -12.3383 127.249 -12.3398H245.426C246.345 -12.3367 247.226 -11.9713 247.878 -11.3229C248.53 -10.6745 248.9 -9.7953 248.908 -8.87592V157.239C248.908 158.164 248.542 159.051 247.889 159.706C247.236 160.361 246.35 160.73 245.426 160.734ZM127.249 -12.2174C126.363 -12.2158 125.514 -11.8633 124.887 -11.2369C124.261 -10.6106 123.909 -9.76165 123.907 -8.87592V157.239C123.909 158.125 124.261 158.974 124.887 159.6C125.514 160.227 126.363 160.579 127.249 160.581H245.426C246.311 160.579 247.16 160.227 247.787 159.6C248.413 158.974 248.766 158.125 248.767 157.239V-8.87592C248.766 -9.76165 248.413 -10.6106 247.787 -11.2369C247.16 -11.8633 246.311 -12.2158 245.426 -12.2174H127.249Z'
          fill='#EBEBEB'
        />
        <path
          d='M145.29 60.5249L229.612 60.5249V5.30415L145.29 5.30415V60.5249Z'
          fill='#E6E6E6'
        />
        <path
          d='M142.714 60.5249L228.406 60.5249V5.30415L142.714 5.30415V60.5249Z'
          fill='#F0F0F0'
        />
        <path
          d='M145.29 71.3569L229.612 71.3569V60.5184L145.29 60.5184V71.3569Z'
          fill='#E6E6E6'
        />
        <path
          d='M138.436 71.3569L224.128 71.3569V60.5184L138.436 60.5184V71.3569Z'
          fill='#F0F0F0'
        />
        <path
          d='M224.807 56.9267V8.90918L146.318 8.90918V56.9267L224.807 56.9267Z'
          fill='#FAFAFA'
        />
        <path
          d='M207.108 56.9206L197.084 8.90918H181.441L191.466 56.9206H207.108Z'
          fill='white'
        />
        <path
          d='M223.143 53.34C223.177 53.34 223.211 53.3331 223.243 53.3198C223.274 53.3065 223.303 53.287 223.327 53.2625C223.351 53.238 223.37 53.2089 223.382 53.177C223.395 53.1452 223.401 53.1111 223.4 53.0768V11.2527C223.4 11.1846 223.373 11.1192 223.325 11.071C223.276 11.0228 223.211 10.9957 223.143 10.9957C223.109 10.9948 223.075 11.0009 223.043 11.0135C223.012 11.0262 222.983 11.0451 222.959 11.0691C222.935 11.0931 222.916 11.1218 222.904 11.1534C222.891 11.1849 222.885 11.2187 222.886 11.2527V53.0768C222.885 53.1111 222.891 53.1452 222.904 53.177C222.916 53.2089 222.935 53.238 222.959 53.2625C222.983 53.287 223.011 53.3065 223.043 53.3198C223.075 53.3331 223.109 53.34 223.143 53.34Z'
          fill='#F0F0F0'
        />
        <path
          d='M188.106 56.9206L178.081 8.90918H171.98L182.01 56.9206H188.106Z'
          fill='white'
        />
        <path
          d='M146.777 56.9267V8.90918H146.318V56.9267H146.777Z'
          fill='#E6E6E6'
        />
        <path
          opacity='0.6'
          d='M141.869 14.3373H226.191L226.521 10.3042H142.2L141.869 14.3373Z'
          fill='#EBEBEB'
        />
        <path
          opacity='0.6'
          d='M141.869 20.9469H226.191L226.521 16.9199H142.2L141.869 20.9469Z'
          fill='#EBEBEB'
        />
        <path
          opacity='0.6'
          d='M141.869 27.5558H226.191L226.521 23.5288H142.2L141.869 27.5558Z'
          fill='#EBEBEB'
        />
        <path
          opacity='0.6'
          d='M141.869 34.1722H226.191L226.521 30.1392H142.2L141.869 34.1722Z'
          fill='#EBEBEB'
        />
        <path
          opacity='0.6'
          d='M141.869 40.7816H226.191L226.521 36.7485H142.2L141.869 40.7816Z'
          fill='#EBEBEB'
        />
        <path
          opacity='0.6'
          d='M141.869 47.391H226.191L226.521 43.3579H142.2L141.869 47.391Z'
          fill='#EBEBEB'
        />
        <path
          d='M217.506 147.869H199.826V151.358H217.506V147.869Z'
          fill='#E6E6E6'
        />
        <path
          d='M166.478 188.029H169.74V107.814H166.478V188.029Z'
          fill='#E6E6E6'
        />
        <path
          d='M155.168 151.358H199.82V147.87H155.168V151.358Z'
          fill='#F5F5F5'
        />
        <path
          d='M217.506 166.945H199.826V170.434H217.506V166.945Z'
          fill='#E6E6E6'
        />
        <path
          d='M155.168 170.428H199.82V166.94H155.168V170.428Z'
          fill='#F5F5F5'
        />
        <path
          d='M217.506 109.724H199.826V113.212H217.506V109.724Z'
          fill='#E6E6E6'
        />
        <path
          d='M155.168 113.206H199.82V109.718H155.168V113.206Z'
          fill='#F5F5F5'
        />
        <path
          d='M217.506 128.793H199.826V132.281H217.506V128.793Z'
          fill='#E6E6E6'
        />
        <path
          d='M155.168 132.282H199.82V128.794H155.168V132.282Z'
          fill='#F5F5F5'
        />
        <path
          d='M211.135 188.029H214.397V107.814H211.135V188.029Z'
          fill='#E6E6E6'
        />
        <path
          d='M196.558 188.029H199.82V107.814H196.558V188.029Z'
          fill='#F5F5F5'
        />
        <path
          d='M155.168 188.029H158.43V107.814H155.168V188.029Z'
          fill='#F5F5F5'
        />
        <path
          d='M8.01257 188.029H41.4155L41.4155 123.224H8.01257L8.01257 188.029Z'
          fill='#E6E6E6'
        />
        <path
          d='M16.9294 188.028H8.00641V179.081H26.2807L16.9294 188.028Z'
          fill='#FAFAFA'
        />
        <path
          d='M99.0782 188.029H132.481V123.224H99.0782V188.029Z'
          fill='#E6E6E6'
        />
        <path
          d='M8.0126 184.944H106.869L106.869 123.218L8.0126 123.218L8.0126 184.944Z'
          fill='#FAFAFA'
        />
        <path
          d='M97.946 188.028H106.863V179.081H88.5946L97.946 188.028Z'
          fill='#FAFAFA'
        />
        <path
          d='M14.9282 161.67H99.9472V146.223H14.9282V161.67Z'
          fill='#F0F0F0'
        />
        <path
          d='M14.9282 180.416H99.9472V164.969H14.9282V180.416Z'
          fill='#F0F0F0'
        />
        <path
          d='M83.7904 147.875H31.0911C30.3477 147.875 29.6348 147.58 29.1091 147.055C28.5834 146.529 28.2881 145.816 28.2881 145.073V144.883H86.5872V145.073C86.5873 145.815 86.2928 146.527 85.7684 147.052C85.2441 147.578 84.5327 147.874 83.7904 147.875Z'
          fill='#FAFAFA'
        />
        <path
          d='M14.9282 142.918H99.9472V127.471L14.9282 127.471V142.918Z'
          fill='#F0F0F0'
        />
        <path
          d='M83.7904 129.124H31.0911C30.3477 129.124 29.6348 128.829 29.1091 128.303C28.5834 127.777 28.2881 127.064 28.2881 126.321V126.131H86.5872V126.321C86.5873 127.063 86.2928 127.775 85.7684 128.301C85.2441 128.826 84.5327 129.122 83.7904 129.124Z'
          fill='#FAFAFA'
        />
        <path
          d='M83.7904 166.627H31.0911C30.3477 166.627 29.6348 166.332 29.1091 165.806C28.5834 165.28 28.2881 164.567 28.2881 163.824V163.634H86.5872V163.824C86.5873 164.566 86.2928 165.278 85.7684 165.804C85.2441 166.329 84.5327 166.625 83.7904 166.627Z'
          fill='#FAFAFA'
        />
        <path
          d='M13.3308 60.5249L97.6522 60.5249V5.30415L13.3308 5.30415V60.5249Z'
          fill='#E6E6E6'
        />
        <path
          d='M10.7604 60.5249L96.4527 60.5249V5.30415L10.7604 5.30415V60.5249Z'
          fill='#F0F0F0'
        />
        <path
          d='M13.3308 71.3569L97.6522 71.3569V60.5184L13.3308 60.5184V71.3569Z'
          fill='#E6E6E6'
        />
        <path
          d='M6.47031 71.3569L92.1626 71.3569V60.5184L6.47031 60.5184V71.3569Z'
          fill='#F0F0F0'
        />
        <path
          d='M92.848 56.9267V8.90918L14.359 8.90918V56.9267L92.848 56.9267Z'
          fill='#FAFAFA'
        />
        <path
          d='M75.149 56.9206L65.1244 8.90918H49.4817L59.5063 56.9206H75.149Z'
          fill='white'
        />
        <path
          d='M91.1772 53.34C91.2115 53.34 91.2454 53.3331 91.277 53.3198C91.3086 53.3065 91.3372 53.287 91.3612 53.2625C91.3851 53.238 91.4039 53.2089 91.4165 53.177C91.429 53.1452 91.4351 53.1111 91.4343 53.0768V11.2527C91.4351 11.2187 91.429 11.1849 91.4164 11.1534C91.4038 11.1218 91.3849 11.0931 91.3608 11.0691C91.3368 11.0451 91.3081 11.0262 91.2766 11.0135C91.245 11.0009 91.2112 10.9948 91.1772 10.9957C91.109 10.9957 91.0437 11.0228 90.9955 11.071C90.9473 11.1192 90.9202 11.1846 90.9202 11.2527V53.0768C90.9194 53.1111 90.9254 53.1452 90.938 53.177C90.9505 53.2089 90.9693 53.238 90.9933 53.2625C91.0172 53.287 91.0458 53.3065 91.0774 53.3198C91.109 53.3331 91.1429 53.34 91.1772 53.34Z'
          fill='#F0F0F0'
        />
        <path
          d='M56.1463 56.9206L46.1218 8.90918H40.0201L50.0447 56.9206H56.1463Z'
          fill='white'
        />
        <path
          d='M14.818 56.9267L14.818 8.90918H14.359L14.359 56.9267H14.818Z'
          fill='#E6E6E6'
        />
        <path
          opacity='0.6'
          d='M9.90363 14.3373H94.225L94.5555 10.3042H10.2341L9.90363 14.3373Z'
          fill='#EBEBEB'
        />
        <path
          opacity='0.6'
          d='M9.90363 16.6141H94.225L94.5555 12.5811H10.2341L9.90363 16.6141Z'
          fill='#EBEBEB'
        />
        <path
          opacity='0.6'
          d='M9.90363 18.8839H94.225L94.5555 14.8569H10.2341L9.90363 18.8839Z'
          fill='#EBEBEB'
        />
        <path
          opacity='0.6'
          d='M9.90363 21.161H94.225L94.5555 17.1279H10.2341L9.90363 21.161Z'
          fill='#EBEBEB'
        />
        <path
          opacity='0.6'
          d='M9.90363 23.4313H94.225L94.5555 19.4043H10.2341L9.90363 23.4313Z'
          fill='#EBEBEB'
        />
        <path
          opacity='0.6'
          d='M9.90363 25.7079H94.225L94.5555 21.6748H10.2341L9.90363 25.7079Z'
          fill='#EBEBEB'
        />
        <path
          d='M26.7643 121.241H29.6774L29.6774 88.7496H26.7643L26.7643 121.241Z'
          fill='#F5F5F5'
        />
        <path
          d='M27.1314 121.223H27.9576L27.9576 88.7321H27.1314L27.1314 121.223Z'
          fill='#FAFAFA'
        />
        <path
          d='M28.3004 121.223H28.6247L28.6247 88.7321H28.3004L28.3004 121.223Z'
          fill='#FAFAFA'
        />
        <path
          d='M39.0104 123.224H17.4435C17.4435 122.503 17.7298 121.812 18.2394 121.303C18.749 120.793 19.4401 120.507 20.1608 120.507H36.287C37.0076 120.507 37.6988 120.793 38.2084 121.303C38.718 121.812 39.0043 122.503 39.0043 123.224H39.0104Z'
          fill='#F0F0F0'
        />
        <path
          d='M22.9821 108.921C23.204 108.92 23.4163 108.831 23.5732 108.674C23.7301 108.517 23.8189 108.305 23.8205 108.083V86.8038C23.8205 86.5814 23.7322 86.3682 23.575 86.2109C23.4177 86.0537 23.2045 85.9654 22.9821 85.9654C22.8715 85.9645 22.7618 85.9856 22.6594 86.0274C22.5569 86.0692 22.4638 86.1308 22.3853 86.2087C22.3068 86.2867 22.2445 86.3794 22.2019 86.4815C22.1594 86.5836 22.1375 86.6932 22.1375 86.8038V108.108C22.1455 108.326 22.2379 108.533 22.3955 108.685C22.553 108.837 22.7633 108.922 22.9821 108.921Z'
          fill='#F0F0F0'
        />
        <path
          d='M15.1669 96.4734H41.2687L38.2637 78.2358H18.1718L15.1669 96.4734Z'
          fill='#E0E0E0'
        />
        <path
          d='M121 215.667C186.534 215.667 239.661 212.565 239.661 208.739C239.661 204.913 186.534 201.811 121 201.811C55.4655 201.811 2.33929 204.913 2.33929 208.739C2.33929 212.565 55.4655 215.667 121 215.667Z'
          fill='#F5F5F5'
        />
        <path
          d='M31.7206 77.8437L31.1102 77.8877L31.9232 89.1743L32.5336 89.1304L31.7206 77.8437Z'
          fill='#0167A7'
        />
        <path
          d='M32.9483 94.9565L32.3379 95.0005L32.6439 99.249L33.2544 99.205L32.9483 94.9565Z'
          fill='#0167A7'
        />
        <path
          d='M174.679 146.878H43.6126C42.1537 146.851 40.7561 146.286 39.6893 145.29C38.6226 144.295 37.9621 142.94 37.8353 141.486L31.3971 52.0544C31.3372 51.3647 31.4225 50.6701 31.6473 50.0154C31.8722 49.3606 32.2318 48.7602 32.7028 48.2528C33.1738 47.7455 33.7459 47.3424 34.3822 47.0696C35.0185 46.7968 35.7049 46.6603 36.3971 46.6688H167.463C168.921 46.6949 170.318 47.2595 171.384 48.2538C172.451 49.2481 173.112 50.6019 173.24 52.0544L179.679 141.486C179.738 142.176 179.653 142.871 179.428 143.526C179.203 144.181 178.844 144.782 178.373 145.29C177.902 145.797 177.33 146.201 176.694 146.475C176.058 146.748 175.371 146.885 174.679 146.878Z'
          fill='#0167A7'
        />
        <path
          d='M175.18 146.878H44.1144C42.6555 146.851 41.2579 146.286 40.1912 145.29C39.1244 144.295 38.4639 142.94 38.3372 141.486L31.8989 52.0544C31.839 51.3647 31.9243 50.6701 32.1492 50.0154C32.3741 49.3606 32.7336 48.7602 33.2046 48.2528C33.6757 47.7455 34.2478 47.3424 34.884 47.0696C35.5203 46.7968 36.2067 46.6603 36.899 46.6688H167.965C169.423 46.6949 170.82 47.2595 171.886 48.2538C172.953 49.2481 173.614 50.6019 173.742 52.0544L180.18 141.486C180.241 142.176 180.157 142.871 179.932 143.527C179.708 144.182 179.348 144.784 178.877 145.292C178.406 145.8 177.834 146.203 177.197 146.477C176.56 146.75 175.873 146.886 175.18 146.878Z'
          fill='#0167A7'
        />
        <path
          opacity='0.5'
          d='M175.18 146.878H44.1144C42.6555 146.851 41.2579 146.286 40.1912 145.29C39.1244 144.295 38.4639 142.94 38.3372 141.486L31.8989 52.0544C31.839 51.3647 31.9243 50.6701 32.1492 50.0154C32.3741 49.3606 32.7336 48.7602 33.2046 48.2528C33.6757 47.7455 34.2478 47.3424 34.884 47.0696C35.5203 46.7968 36.2067 46.6603 36.899 46.6688H167.965C169.423 46.6949 170.82 47.2595 171.886 48.2538C172.953 49.2481 173.614 50.6019 173.742 52.0544L180.18 141.486C180.241 142.176 180.157 142.871 179.932 143.527C179.708 144.182 179.348 144.784 178.877 145.292C178.406 145.8 177.834 146.203 177.197 146.477C176.56 146.75 175.873 146.886 175.18 146.878Z'
          fill='white'
        />
        <path
          d='M168.161 49.3618H37.0948H36.6542C33.3065 49.6372 33.992 54.7352 37.3702 54.7352H168.656C172.041 54.7352 171.992 49.6372 168.601 49.3618H168.161Z'
          fill='#0167A7'
        />
        <path
          d='M40.5098 52.0542C40.5226 52.212 40.5023 52.3707 40.45 52.5201C40.3977 52.6695 40.3147 52.8063 40.2062 52.9217C40.0978 53.037 39.9664 53.1283 39.8205 53.1897C39.6746 53.2511 39.5175 53.2813 39.3592 53.2782C39.0266 53.2735 38.7075 53.1459 38.4634 52.9199C38.2193 52.6938 38.0675 52.3855 38.0373 52.0542C38.0245 51.8969 38.0447 51.7387 38.0966 51.5898C38.1485 51.4408 38.231 51.3043 38.3387 51.189C38.4465 51.0738 38.5771 50.9823 38.7223 50.9205C38.8675 50.8587 39.024 50.828 39.1817 50.8302C39.5154 50.8334 39.836 50.9603 40.0814 51.1865C40.3268 51.4126 40.4794 51.7219 40.5098 52.0542Z'
          fill='#FAFAFA'
        />
        <path
          d='M44.7142 52.0542C44.7271 52.212 44.7067 52.3707 44.6544 52.5201C44.6021 52.6695 44.5191 52.8063 44.4107 52.9217C44.3023 53.037 44.1709 53.1283 44.025 53.1897C43.8791 53.2511 43.7219 53.2813 43.5636 53.2782C43.231 53.2735 42.9119 53.1459 42.6678 52.9199C42.4237 52.6938 42.2719 52.3855 42.2417 52.0542C42.2289 51.8969 42.2491 51.7387 42.301 51.5898C42.3529 51.4408 42.4354 51.3043 42.5432 51.189C42.6509 51.0738 42.7816 50.9823 42.9267 50.9205C43.0719 50.8587 43.2284 50.828 43.3862 50.8302C43.7198 50.8334 44.0405 50.9603 44.2858 51.1865C44.5312 51.4126 44.6839 51.7219 44.7142 52.0542Z'
          fill='#FAFAFA'
        />
        <path
          d='M48.9125 52.0543C48.9253 52.2116 48.9051 52.3698 48.8532 52.5187C48.8013 52.6677 48.7188 52.8042 48.6111 52.9195C48.5033 53.0347 48.3727 53.1262 48.2275 53.188C48.0823 53.2498 47.9258 53.2805 47.7681 53.2783C47.435 53.275 47.1152 53.1478 46.8708 52.9215C46.6264 52.6953 46.475 52.3861 46.4461 52.0543C46.4324 51.8968 46.4519 51.7382 46.5035 51.5888C46.555 51.4394 46.6375 51.3025 46.7454 51.1871C46.8534 51.0716 46.9844 50.9802 47.13 50.9187C47.2756 50.8573 47.4326 50.8271 47.5906 50.8303C47.9232 50.835 48.2423 50.9626 48.4864 51.1886C48.7305 51.4146 48.8823 51.723 48.9125 52.0543Z'
          fill='#FAFAFA'
        />
        <path
          d='M171.704 137.954H46.336C45.8064 137.945 45.299 137.74 44.9122 137.378C44.5254 137.016 44.2869 136.524 44.243 135.996L38.9492 62.4517C38.9256 62.1994 38.956 61.9449 39.0383 61.7051C39.1207 61.4654 39.253 61.2459 39.4267 61.0613C39.6003 60.8767 39.8112 60.7311 40.0455 60.6342C40.2797 60.5373 40.5318 60.4914 40.7852 60.4994H166.184C166.713 60.5067 167.22 60.7107 167.607 61.0716C167.994 61.4325 168.233 61.9245 168.277 62.4517L173.577 135.996C173.6 136.251 173.568 136.509 173.483 136.751C173.398 136.993 173.263 137.215 173.085 137.4C172.908 137.586 172.693 137.731 172.455 137.827C172.216 137.922 171.961 137.966 171.704 137.954Z'
          fill='white'
        />
        <path
          d='M118.876 109.937L116.832 81.5896L110.651 77.8687H93.6314L95.9447 109.937H118.876Z'
          fill='white'
        />
        <path
          d='M118.876 110.231H95.9447C95.8689 110.233 95.7952 110.205 95.7393 110.154C95.6834 110.103 95.6496 110.032 95.6449 109.956L93.3376 77.8871C93.3347 77.8468 93.3402 77.8064 93.3539 77.7685C93.3677 77.7305 93.3892 77.6959 93.4172 77.6668C93.4442 77.6366 93.4772 77.6122 93.514 77.5954C93.5509 77.5785 93.5908 77.5695 93.6314 77.5688H110.651C110.705 77.5707 110.757 77.5854 110.804 77.6117L116.985 81.3326C117.027 81.3565 117.062 81.3901 117.088 81.4307C117.113 81.4712 117.129 81.5174 117.132 81.5652L119.164 109.913C119.168 109.954 119.163 109.996 119.15 110.035C119.136 110.074 119.114 110.11 119.084 110.139C119.058 110.168 119.026 110.191 118.99 110.207C118.954 110.223 118.915 110.231 118.876 110.231ZM96.2324 109.619H118.552L116.545 81.7427L110.596 78.1625H93.9496L96.2324 109.619Z'
          fill='#0167A7'
        />
        <path
          d='M116.832 81.5896L110.651 77.8687L112.922 82.8014L116.832 81.5896Z'
          fill='#EBEBEB'
        />
        <path
          d='M112.922 83.0952C112.865 83.0949 112.81 83.0786 112.762 83.0483C112.715 83.018 112.677 82.9748 112.652 82.9238L110.382 77.9911C110.355 77.9332 110.348 77.8684 110.36 77.8059C110.372 77.7434 110.403 77.6862 110.449 77.6423C110.498 77.6007 110.558 77.5755 110.622 77.57C110.685 77.5645 110.749 77.5791 110.804 77.6117L116.985 81.3326C117.035 81.3626 117.075 81.4061 117.101 81.4581C117.127 81.5102 117.138 81.5685 117.132 81.6264C117.124 81.6827 117.101 81.7357 117.064 81.779C117.027 81.8223 116.978 81.8543 116.924 81.8712L113.007 83.0952H112.922ZM111.324 78.6153L113.081 82.4403L116.141 81.504L111.324 78.6153Z'
          fill='#0167A7'
        />
        <path
          d='M103.252 92.3118C103.261 92.4782 103.236 92.6446 103.179 92.801C103.122 92.9573 103.033 93.1003 102.918 93.2212C102.804 93.3421 102.666 93.4383 102.513 93.504C102.359 93.5697 102.195 93.6034 102.028 93.6032C101.679 93.5969 101.344 93.4615 101.089 93.223C100.833 92.9846 100.675 92.66 100.645 92.3118C100.629 92.1438 100.649 91.9743 100.704 91.8147C100.759 91.6551 100.848 91.509 100.963 91.3862C101.079 91.2634 101.22 91.1668 101.376 91.1026C101.532 91.0385 101.7 91.0085 101.869 91.0144C102.219 91.0222 102.554 91.1589 102.809 91.3984C103.064 91.6378 103.222 91.9631 103.252 92.3118Z'
          fill='#0167A7'
        />
        <path
          d='M111.636 92.3111C111.651 92.4787 111.63 92.6475 111.575 92.8064C111.52 92.9652 111.431 93.1105 111.315 93.2326C111.2 93.3547 111.059 93.4509 110.904 93.5146C110.748 93.5784 110.581 93.6083 110.412 93.6024C110.062 93.5963 109.727 93.4611 109.471 93.2228C109.214 92.9844 109.055 92.6598 109.023 92.3111C109.013 92.1443 109.037 91.9772 109.094 91.8201C109.151 91.663 109.24 91.5193 109.355 91.3977C109.469 91.2762 109.608 91.1794 109.761 91.1133C109.915 91.0473 110.08 91.0134 110.247 91.0137C110.599 91.0186 110.936 91.1542 111.192 91.394C111.449 91.6339 111.608 91.9609 111.636 92.3111Z'
          fill='#0167A7'
        />
        <path
          d='M114.011 100.721C113.937 100.719 113.865 100.69 113.811 100.639C113.757 100.589 113.723 100.519 113.717 100.445C113.564 98.3582 110.296 96.663 106.434 96.663C103.87 96.663 101.569 97.428 100.431 98.652C100.197 98.8775 100.014 99.1502 99.8934 99.452C99.7731 99.7538 99.7184 100.078 99.733 100.402C99.7358 100.442 99.7307 100.483 99.7179 100.521C99.7052 100.559 99.685 100.595 99.6586 100.625C99.6052 100.686 99.5296 100.724 99.4485 100.73C99.3673 100.735 99.2872 100.709 99.2258 100.655C99.1644 100.602 99.1267 100.526 99.121 100.445C99.1028 100.039 99.1696 99.6344 99.3172 99.256C99.4647 98.8777 99.6898 98.5343 99.9778 98.2481C101.202 96.9078 103.693 96.0693 106.416 96.0693C110.657 96.0693 114.115 97.9727 114.286 100.402C114.289 100.441 114.284 100.48 114.271 100.516C114.259 100.553 114.239 100.587 114.214 100.616C114.188 100.645 114.157 100.668 114.122 100.685C114.087 100.702 114.05 100.712 114.011 100.714V100.721Z'
          fill='#0167A7'
        />
        <path
          d='M98.4601 89.6923C98.3854 89.6908 98.3137 89.6626 98.2581 89.6128C98.2024 89.5566 98.1712 89.4807 98.1712 89.4016C98.1712 89.3225 98.2024 89.2467 98.2581 89.1905L99.5617 87.789C99.6176 87.7352 99.6921 87.7051 99.7697 87.7051C99.8474 87.7051 99.9219 87.7352 99.9778 87.789C100.005 87.8163 100.027 87.8487 100.042 87.8844C100.057 87.9202 100.064 87.9584 100.064 87.9971C100.064 88.0358 100.057 88.074 100.042 88.1098C100.027 88.1455 100.005 88.1779 99.9778 88.2052L98.6743 89.6067C98.6462 89.6347 98.6127 89.6567 98.5759 89.6714C98.5391 89.6861 98.4997 89.6933 98.4601 89.6923Z'
          fill='#0167A7'
        />
        <path
          d='M113.399 89.6923C113.325 89.6889 113.254 89.6609 113.197 89.6128L111.698 88.2113C111.669 88.1839 111.647 88.1511 111.631 88.1148C111.616 88.0786 111.608 88.0396 111.608 88.0002C111.608 87.9608 111.616 87.9218 111.631 87.8855C111.647 87.8492 111.669 87.8164 111.698 87.789C111.753 87.7352 111.828 87.7051 111.906 87.7051C111.983 87.7051 112.058 87.7352 112.114 87.789L113.613 89.1905C113.641 89.2178 113.662 89.2502 113.677 89.2859C113.692 89.3216 113.7 89.3599 113.7 89.3986C113.7 89.4372 113.692 89.4755 113.677 89.5112C113.662 89.5469 113.641 89.5794 113.613 89.6067C113.555 89.661 113.479 89.6916 113.399 89.6923Z'
          fill='#0167A7'
        />
        <path
          d='M91.7831 116.271H93.1663L95.1614 118.928L94.9717 116.271H96.367L96.7097 121.07H95.296L93.3131 118.432L93.5029 121.07H92.1075L91.7831 116.271Z'
          fill='#0167A7'
        />
        <path
          d='M97.334 118.67C97.2867 118.344 97.3092 118.012 97.4 117.696C97.4907 117.379 97.6476 117.086 97.8603 116.834C98.0928 116.606 98.3713 116.429 98.6773 116.316C98.9833 116.203 99.3097 116.157 99.6351 116.179C100.325 116.142 101.002 116.372 101.526 116.822C102.006 117.3 102.284 117.944 102.303 118.621C102.354 119.088 102.291 119.561 102.12 119.998C101.961 120.351 101.696 120.644 101.361 120.837C100.959 121.052 100.507 121.156 100.051 121.137C99.583 121.152 99.1172 121.064 98.6865 120.88C98.3092 120.698 97.9909 120.414 97.7685 120.06C97.5059 119.642 97.3562 119.163 97.334 118.67ZM98.8211 118.67C98.8152 119.046 98.9359 119.412 99.1638 119.711C99.2594 119.816 99.3769 119.899 99.508 119.954C99.6391 120.009 99.7807 120.035 99.9227 120.029C100.058 120.038 100.194 120.015 100.319 119.961C100.444 119.906 100.553 119.823 100.639 119.717C100.815 119.383 100.873 118.999 100.804 118.627C100.807 118.268 100.686 117.918 100.461 117.636C100.363 117.533 100.244 117.451 100.112 117.398C99.9805 117.344 99.8386 117.319 99.6963 117.324C99.5631 117.318 99.4305 117.344 99.3092 117.399C99.188 117.455 99.0815 117.538 98.9986 117.642C98.8194 117.957 98.7566 118.326 98.8211 118.683V118.67Z'
          fill='#0167A7'
        />
        <path
          d='M105.18 116.271H107.383C107.746 116.26 108.108 116.32 108.448 116.449C108.717 116.562 108.956 116.736 109.146 116.957C109.338 117.182 109.486 117.441 109.58 117.722C109.679 118.023 109.741 118.336 109.764 118.652C109.811 119.052 109.78 119.457 109.672 119.845C109.588 120.114 109.438 120.358 109.237 120.555C109.07 120.733 108.859 120.864 108.625 120.935C108.341 121.017 108.047 121.063 107.75 121.069H105.553L105.18 116.271ZM106.74 117.361L106.93 119.98H107.291C107.515 119.995 107.738 119.959 107.946 119.876C108.083 119.797 108.184 119.667 108.228 119.515C108.298 119.243 108.314 118.961 108.277 118.683C108.288 118.309 108.174 117.943 107.952 117.642C107.839 117.54 107.705 117.461 107.559 117.413C107.414 117.364 107.26 117.347 107.108 117.361H106.74Z'
          fill='#0167A7'
        />
        <path
          d='M113.319 120.274H111.636L111.459 121.07H109.947L111.404 116.271H113.044L115.192 121.07H113.638L113.319 120.274ZM112.934 119.24L112.285 117.514L111.881 119.24H112.934Z'
          fill='#0167A7'
        />
        <path
          d='M114.494 116.271H118.999L119.084 117.459H117.573L117.83 121.07H116.349L116.092 117.459H114.58L114.494 116.271Z'
          fill='#0167A7'
        />
        <path
          d='M122.45 120.274H120.767L120.59 121.07H119.078L120.535 116.271H122.151L124.293 121.07H122.744L122.45 120.274ZM122.065 119.24L121.41 117.514L121 119.24H122.065Z'
          fill='#0167A7'
        />
        <path
          d='M184.458 59.9248C185.003 59.6494 185.682 59.3128 186.258 58.964C186.833 58.6152 187.482 58.2786 188.094 57.9114C189.287 57.1708 190.499 56.4426 191.643 55.6347C193.942 54.062 196.096 52.2884 198.081 50.3348C198.332 50.1022 198.553 49.8452 198.791 49.5943L199.14 49.221L199.312 49.0374L199.397 48.9394C199.312 49.1047 199.397 49.0557 199.397 48.8966C199.451 48.678 199.482 48.4545 199.489 48.2295C199.475 46.9282 199.325 45.6319 199.042 44.3617C198.498 41.6199 197.671 38.7925 196.857 36.0262L199.232 34.9858C200.731 37.6399 201.982 40.426 202.971 43.309C203.527 44.8671 203.881 46.4898 204.024 48.1377C204.061 48.6501 204.045 49.1649 203.975 49.6738C203.884 50.3935 203.629 51.0825 203.228 51.6873L203.124 51.8281L203.045 51.926L202.953 52.0423L202.763 52.2748L202.384 52.7338C202.133 53.0337 201.888 53.3458 201.619 53.6335C199.526 55.956 197.191 58.0486 194.654 59.8759C193.43 60.7816 192.133 61.6507 190.805 62.4402C190.15 62.838 189.483 63.2174 188.804 63.5907C188.124 63.964 187.463 64.3068 186.68 64.674L184.458 59.9248Z'
          fill='#FFB573'
        />
        <path
          d='M179.011 203.806C179.47 203.795 179.925 203.733 180.37 203.623C180.393 203.616 180.413 203.603 180.429 203.586C180.445 203.569 180.457 203.548 180.462 203.525C180.465 203.501 180.461 203.477 180.452 203.456C180.442 203.434 180.426 203.416 180.407 203.402C180.229 203.286 178.675 202.282 178.075 202.552C178.009 202.583 177.952 202.631 177.91 202.691C177.868 202.751 177.842 202.821 177.836 202.894C177.813 203.01 177.819 203.129 177.854 203.242C177.889 203.354 177.953 203.456 178.038 203.537C178.32 203.741 178.665 203.837 179.011 203.806ZM180.021 203.451C179.134 203.629 178.461 203.598 178.185 203.359C178.13 203.303 178.09 203.234 178.069 203.158C178.047 203.082 178.045 203.002 178.063 202.925C178.065 202.892 178.076 202.861 178.094 202.834C178.112 202.807 178.138 202.786 178.167 202.772C178.491 202.631 179.391 203.084 180.021 203.451Z'
          fill='#0167A7'
        />
        <path
          d='M180.364 203.623H180.425C180.443 203.612 180.458 203.596 180.469 203.578C180.479 203.56 180.486 203.54 180.486 203.519C180.486 203.451 180.486 201.976 179.923 201.487C179.856 201.422 179.775 201.373 179.686 201.344C179.596 201.316 179.502 201.308 179.409 201.321C179.313 201.322 179.219 201.356 179.145 201.417C179.07 201.479 179.019 201.564 178.999 201.658C178.883 202.27 179.813 203.341 180.303 203.623C180.323 203.628 180.344 203.628 180.364 203.623ZM179.489 201.548C179.588 201.548 179.684 201.585 179.758 201.652C180.081 202.128 180.248 202.693 180.236 203.268C179.746 202.876 179.165 202.044 179.238 201.695C179.238 201.64 179.281 201.566 179.434 201.548H179.489Z'
          fill='#0167A7'
        />
        <path
          d='M179.887 44.6191C179.275 47.6791 178.051 53.7991 180.162 55.8493C180.162 55.8493 179.33 58.9093 173.681 58.9093C167.463 58.9093 170.713 55.8493 170.713 55.8493C174.103 55.0415 174.011 52.5262 173.424 50.1577L179.887 44.6191Z'
          fill='#FFB573'
        />
        <path
          d='M169.519 57.0733C168.546 57.208 169.379 54.6804 169.77 54.4173C170.688 53.8053 182.537 52.9546 182.457 54.4173C182.408 55.0293 182.114 56.2533 181.6 56.6572C181.086 57.0611 178.038 55.8004 169.519 57.0733Z'
          fill='#263238'
        />
        <path
          d='M171.453 56.2046C170.676 56.4677 170.75 53.9218 171.013 53.6158C171.625 52.9059 181.215 50.4457 181.496 51.8349C181.606 52.4469 181.661 53.6709 181.331 54.1054C181.001 54.5399 178.185 53.8239 171.453 56.2046Z'
          fill='#263238'
        />
        <path
          d='M167.885 39.1356C167.847 39.1373 167.808 39.1298 167.773 39.1139C167.738 39.0979 167.707 39.0739 167.683 39.0438C167.497 38.803 167.256 38.6094 166.98 38.4786C166.705 38.3479 166.403 38.2836 166.098 38.291C166.066 38.2953 166.034 38.2931 166.003 38.2845C165.972 38.276 165.943 38.2612 165.918 38.2412C165.893 38.2212 165.872 38.1964 165.857 38.1681C165.841 38.1399 165.832 38.1088 165.829 38.0768C165.824 38.0138 165.843 37.9512 165.883 37.9021C165.923 37.8531 165.98 37.8213 166.043 37.8137C166.428 37.794 166.813 37.8678 167.163 38.0287C167.514 38.1896 167.82 38.4329 168.057 38.7378C168.098 38.7874 168.117 38.8513 168.112 38.9154C168.106 38.9796 168.075 39.0389 168.026 39.0805C167.985 39.1103 167.936 39.1293 167.885 39.1356Z'
          fill='#263238'
        />
        <path
          d='M166.698 42.1279C166.42 43.1043 166.008 44.0373 165.474 44.9003C165.699 45.0293 165.948 45.1077 166.206 45.1299C166.464 45.1521 166.724 45.1176 166.967 45.0288L166.698 42.1279Z'
          fill='#FF5652'
        />
        <path
          d='M166.998 41.388C167.041 41.7981 166.851 42.153 166.582 42.1775C166.312 42.202 166.062 41.896 166.025 41.4798C165.988 41.0637 166.172 40.7148 166.435 40.6903C166.698 40.6659 166.955 40.9719 166.998 41.388Z'
          fill='#263238'
        />
        <path
          d='M166.557 40.7024L165.541 40.5127C165.541 40.5127 166.123 41.2349 166.557 40.7024Z'
          fill='#263238'
        />
        <path
          d='M186.3 203.507H181.172L181.576 191.634H186.704L186.3 203.507Z'
          fill='#FFB573'
        />
        <path
          d='M180.768 202.913H186.527C186.626 202.911 186.723 202.945 186.798 203.009C186.874 203.074 186.923 203.164 186.937 203.262L187.592 207.815C187.605 207.93 187.593 208.047 187.558 208.157C187.522 208.268 187.464 208.369 187.387 208.456C187.309 208.542 187.214 208.61 187.108 208.657C187.002 208.704 186.888 208.728 186.772 208.727C184.764 208.697 183.797 208.574 181.264 208.574C179.703 208.574 177.426 208.739 175.278 208.739C173.13 208.739 173.008 206.61 173.907 206.42C177.922 205.551 178.559 204.364 179.911 203.225C180.152 203.025 180.455 202.915 180.768 202.913Z'
          fill='#263238'
        />
        <g opacity='0.2'>
          <path
            d='M186.704 191.634H181.576L181.368 197.754H186.496L186.704 191.634Z'
            fill='black'
          />
        </g>
        <path
          d='M165.902 62.9362C162.764 61.716 159.69 60.3372 156.692 58.8052C153.595 57.2919 150.645 55.4952 147.879 53.4379C147.14 52.8592 146.441 52.2315 145.786 51.5591C145.615 51.3755 145.443 51.2041 145.278 50.9899C145.084 50.771 144.904 50.5401 144.739 50.2984C144.288 49.6409 144.006 48.8819 143.919 48.0891C143.852 47.3374 143.954 46.5803 144.219 45.8736C144.431 45.3056 144.722 44.7701 145.082 44.2824C145.673 43.474 146.371 42.7493 147.157 42.1282C148.544 41.024 150.041 40.0662 151.624 39.2702C152.383 38.8662 153.154 38.5052 153.938 38.1686C154.721 37.832 155.492 37.5137 156.331 37.2261L157.414 39.5823C154.746 41.2653 151.979 43.1196 149.948 45.1882C149.493 45.649 149.093 46.1602 148.754 46.7121C148.46 47.1956 148.467 47.5566 148.516 47.526C148.564 47.4954 148.516 47.526 148.601 47.5872L148.846 47.8504C148.938 47.9606 149.066 48.0707 149.183 48.187C149.701 48.6641 150.251 49.1058 150.829 49.5089C152.092 50.4034 153.408 51.2209 154.77 51.9569C156.147 52.728 157.567 53.4563 159.011 54.154C161.9 55.5371 164.887 56.8651 167.818 58.0769L165.902 62.9362Z'
          fill='#FFB573'
        />
        <path
          d='M158.944 37.936L159.789 36.1L155.921 34.7842C155.921 34.7842 154.697 38.4011 156.147 40.0657C156.753 39.9703 157.326 39.7257 157.814 39.354C158.303 38.9822 158.691 38.4949 158.944 37.936Z'
          fill='#FFB573'
        />
        <path
          d='M159.77 32.1279L156.692 31.2344L155.921 34.7595L159.77 36.0998V32.1279Z'
          fill='#FFB573'
        />
        <path
          d='M199.55 36.2777L199.997 31.9937L196.031 32.9484C196.031 32.9484 195.97 36.9753 198.069 37.6118L199.55 36.2777Z'
          fill='#FFB573'
        />
        <path
          d='M198.088 28.9761L195.499 30.1817L196.031 32.9174L199.997 31.981L198.088 28.9761Z'
          fill='#FFB573'
        />
        <path
          d='M180.695 38.6091C180.884 43.6948 181.068 45.843 178.773 48.6888C175.315 52.9728 169.03 52.0854 167.328 47.1588C165.798 42.7279 165.749 35.1574 170.499 32.6605C171.543 32.1053 172.711 31.8262 173.894 31.8495C175.076 31.8728 176.233 32.1979 177.254 32.7938C178.275 33.3897 179.128 34.2367 179.73 35.2545C180.332 36.2723 180.664 37.427 180.695 38.6091Z'
          fill='#FFB573'
        />
        <path
          d='M178.479 48.6822C183.473 46.5219 186.753 41.9502 185.37 34.8633C184.042 28.064 179.452 27.3908 177.555 28.7984C175.658 30.206 170.927 28.1068 168.087 30.4936C163.191 34.6368 167.818 39.0616 170.242 41.736C171.686 44.7104 173.583 50.7936 178.479 48.6822Z'
          fill='#263238'
        />
        <path
          d='M176.404 33.6463C177.014 34.4278 177.838 35.0138 178.777 35.3319C179.715 35.6501 180.726 35.6865 181.685 35.4366C182.644 35.1868 183.509 34.6616 184.172 33.926C184.836 33.1903 185.27 32.2764 185.42 31.297C185.571 30.3176 185.431 29.3156 185.019 28.4147C184.606 27.5138 183.939 26.7534 183.099 26.2274C182.259 25.7014 181.284 25.4329 180.293 25.4549C179.302 25.4769 178.34 25.7885 177.524 26.3512C176.409 27.1704 175.664 28.3989 175.454 29.7669C175.244 31.1349 175.586 32.5302 176.404 33.6463Z'
          fill='#0167A7'
        />
        <path
          d='M177.702 29.2762C176.986 25.1758 180.86 22.0179 186.05 23.5418C191.239 25.0657 188.498 29.6618 187.427 33.3338C186.356 37.0058 189.158 40.5064 190.321 37.7096C191.484 34.9128 189.526 34.0376 189.526 34.0376C189.526 34.0376 195.034 35.4636 189.917 41.6754C184.801 47.8871 180.168 41.0266 181.423 36.9813C182.439 33.7316 178.381 33.1502 177.702 29.2762Z'
          fill='#263238'
        />
        <path
          d='M172.518 30.8239C170.15 29.5999 166.141 28.5901 163.858 32.4335C162.781 34.2695 163.197 36.7175 163.197 36.7175L170.168 37.1765L172.518 30.8239Z'
          fill='#263238'
        />
        <path
          d='M162.377 35.5065C162.336 35.5033 162.298 35.4844 162.271 35.4535C162.243 35.4227 162.229 35.3825 162.23 35.3412C162.23 35.2372 162.408 32.777 163.895 31.2347C167.488 27.5015 171.698 30.6227 172.898 31.6325C172.923 31.6598 172.938 31.6957 172.939 31.7332C172.94 31.7708 172.928 31.8075 172.903 31.8362C172.879 31.865 172.845 31.8838 172.808 31.8891C172.771 31.8944 172.733 31.8859 172.702 31.8651C171.545 30.8736 167.518 27.9054 164.134 31.4428C162.726 32.9055 162.555 35.3351 162.555 35.3596C162.554 35.3814 162.549 35.4028 162.539 35.4225C162.53 35.4421 162.516 35.4594 162.499 35.4733C162.483 35.4872 162.463 35.4974 162.442 35.5031C162.421 35.5088 162.399 35.51 162.377 35.5065Z'
          fill='#263238'
        />
        <path
          d='M171.092 41.5164C171.065 42.4792 170.708 43.4036 170.082 44.1357C169.238 45.1272 168.246 44.6376 168.044 43.5237C167.843 42.5384 168.044 40.8309 169.109 40.2373C170.174 39.6436 171.104 40.4025 171.092 41.5164Z'
          fill='#FFB573'
        />
        <path
          d='M169.991 88.1016C169.991 88.1016 170.321 123.689 173.406 143.518C175.903 159.534 180.511 196.572 180.511 196.572H187.506C187.506 196.572 188.185 160.813 186.894 144.975C183.693 104.889 191.961 97.3917 185.291 88.1016H169.991Z'
          fill='#263238'
        />
        <path
          opacity='0.1'
          d='M169.991 88.1016C169.991 88.1016 170.321 123.689 173.406 143.518C175.903 159.534 180.511 196.572 180.511 196.572H187.506C187.506 196.572 188.185 160.813 186.894 144.975C183.693 104.889 191.961 97.3917 185.291 88.1016H169.991Z'
          fill='white'
        />
        <path
          opacity='0.3'
          d='M173.669 104.246C176.117 114.987 174.164 131.903 172.824 139.351C171.404 128.041 170.688 113.384 170.333 102.631C171.6 100.587 172.8 100.452 173.669 104.246Z'
          fill='black'
        />
        <path
          d='M179.556 196.708H188.461L188.926 193.586L179.507 193.268L179.556 196.708Z'
          fill='#0167A7'
        />
        <path
          d='M182.096 58.04C182.934 56.3754 187.439 55.3289 189.899 55.335L191.735 63.5113C191.735 63.5113 186.839 70.788 184.795 70.0169C182.39 69.1295 180.303 61.6325 182.096 58.04Z'
          fill='#0167A7'
        />
        <path
          opacity='0.4'
          d='M182.096 58.04C182.934 56.3754 187.439 55.3289 189.899 55.335L191.735 63.5113C191.735 63.5113 186.839 70.788 184.795 70.0169C182.39 69.1295 180.303 61.6325 182.096 58.04Z'
          fill='black'
        />
        <path
          d='M176.906 194.706C176.916 194.688 176.922 194.668 176.922 194.648C176.922 194.627 176.916 194.607 176.906 194.59C176.895 194.566 176.876 194.547 176.853 194.535C176.83 194.523 176.804 194.518 176.778 194.522C176.521 194.565 174.256 194.92 173.95 195.569C173.918 195.628 173.9 195.694 173.9 195.762C173.9 195.829 173.918 195.895 173.95 195.954C174.003 196.051 174.078 196.134 174.17 196.194C174.262 196.255 174.367 196.293 174.477 196.303C175.217 196.377 176.313 195.349 176.863 194.724L176.906 194.706ZM174.189 195.648C174.397 195.306 175.572 194.988 176.466 194.822C175.658 195.642 174.948 196.101 174.532 196.046C174.46 196.039 174.391 196.014 174.331 195.973C174.271 195.933 174.222 195.878 174.189 195.814C174.176 195.79 174.168 195.764 174.168 195.737C174.168 195.71 174.176 195.684 174.189 195.661C174.189 195.661 174.183 195.655 174.189 195.648Z'
          fill='#0167A7'
        />
        <path
          d='M176.906 194.706C176.916 194.688 176.922 194.668 176.922 194.648C176.922 194.627 176.916 194.607 176.906 194.589C176.87 194.547 175.964 193.5 175.125 193.445C175.005 193.435 174.883 193.45 174.768 193.489C174.653 193.528 174.548 193.59 174.458 193.671C174.207 193.898 174.232 194.094 174.293 194.216C174.556 194.742 176.184 194.926 176.839 194.785C176.856 194.779 176.871 194.768 176.883 194.754C176.894 194.74 176.902 194.724 176.906 194.706ZM174.513 193.935C174.54 193.893 174.573 193.856 174.611 193.824C174.675 193.764 174.751 193.717 174.835 193.689C174.918 193.66 175.007 193.65 175.095 193.659C175.664 193.799 176.175 194.114 176.557 194.559C175.829 194.62 174.654 194.4 174.495 194.081C174.485 194.058 174.481 194.031 174.484 194.006C174.488 193.98 174.498 193.955 174.513 193.935Z'
          fill='#0167A7'
        />
        <path
          d='M182.249 192.533L177.561 194.62L175.474 190.269L172.745 184.577L172.42 183.917L177.108 181.83L177.469 182.576L180.113 188.09L182.249 192.533Z'
          fill='#FFB573'
        />
        <path
          opacity='0.2'
          d='M180.113 188.09L175.474 190.269L172.745 184.577L177.469 182.576L180.113 188.09Z'
          fill='black'
        />
        <path
          d='M164.495 88.1016C164.495 88.1016 149.519 125.36 153.577 142.863C157.249 158.708 173.467 188.714 173.467 188.714L179.752 185.569C179.752 185.569 169.721 149.344 168.638 141.474C166.521 126.174 180.125 104.552 180.125 88.1016H164.495Z'
          fill='#263238'
        />
        <path
          opacity='0.1'
          d='M164.495 88.1016C164.495 88.1016 149.519 125.36 153.577 142.863C157.249 158.708 173.467 188.714 173.467 188.714L179.752 185.569C179.752 185.569 169.721 149.344 168.638 141.474C166.521 126.174 180.125 104.552 180.125 88.1016H164.495Z'
          fill='white'
        />
        <path
          d='M176.729 193.965L181.38 190.581C181.458 190.519 181.557 190.489 181.656 190.496C181.756 190.503 181.849 190.546 181.919 190.618L185.132 193.916C185.209 194.002 185.268 194.104 185.304 194.214C185.34 194.325 185.353 194.441 185.341 194.557C185.329 194.672 185.292 194.784 185.234 194.885C185.176 194.985 185.097 195.072 185.003 195.14C183.357 196.291 182.506 196.768 180.462 198.255C179.201 199.173 176.692 201.199 174.954 202.466C173.216 203.733 171.894 202.08 172.506 201.401C175.248 198.341 175.823 196.444 176.251 194.724C176.318 194.423 176.486 194.155 176.729 193.965Z'
          fill='#263238'
        />
        <path
          d='M173.063 190.042L181.16 186.34L180.125 183.041L171.453 186.995L173.063 190.042Z'
          fill='#0167A7'
        />
        <path
          d='M168.467 57.6487C167.8 55.9107 161.949 53.9767 159.011 53.3647L157.72 62.9854C157.72 62.9854 162.518 69.8765 164.617 69.3073C167.102 68.6341 169.911 61.3758 168.467 57.6487Z'
          fill='#0167A7'
        />
        <path
          opacity='0.4'
          d='M168.467 57.6487C167.8 55.9107 161.949 53.9767 159.011 53.3647L157.72 62.9854C157.72 62.9854 162.518 69.8765 164.617 69.3073C167.102 68.6341 169.911 61.3758 168.467 57.6487Z'
          fill='black'
        />
        <path
          d='M162.047 57.165C162.047 57.165 159.599 58.0218 164.495 88.1016H185.303C184.954 79.6315 184.942 74.4051 188.975 57.012C186.059 56.4104 183.104 56.0218 180.131 55.8492C176.986 55.6225 173.828 55.6225 170.682 55.8492C166.649 56.2042 162.047 57.165 162.047 57.165Z'
          fill='#0167A7'
        />
        <path
          opacity='0.4'
          d='M162.047 57.165C162.047 57.165 159.599 58.0218 164.495 88.1016H185.303C184.954 79.6315 184.942 74.4051 188.975 57.012C186.059 56.4104 183.104 56.0218 180.131 55.8492C176.986 55.6225 173.828 55.6225 170.682 55.8492C166.649 56.2042 162.047 57.165 162.047 57.165Z'
          fill='black'
        />
        <path
          d='M185.627 86.8839L186.564 88.7199C186.637 88.8668 186.466 89.0137 186.227 89.0137H164.391C164.201 89.0137 164.048 88.9219 164.036 88.7995L163.846 86.9635C163.846 86.835 163.999 86.7248 164.201 86.7248H185.26C185.33 86.7137 185.402 86.7228 185.467 86.751C185.532 86.7792 185.587 86.8253 185.627 86.8839Z'
          fill='#0167A7'
        />
        <path
          opacity='0.3'
          d='M185.627 86.8839L186.564 88.7199C186.637 88.8668 186.466 89.0137 186.227 89.0137H164.391C164.201 89.0137 164.048 88.9219 164.036 88.7995L163.846 86.9635C163.846 86.835 163.999 86.7248 164.201 86.7248H185.26C185.33 86.7137 185.402 86.7228 185.467 86.751C185.532 86.7792 185.587 86.8253 185.627 86.8839Z'
          fill='white'
        />
        <path
          d='M182.812 89.2519H183.375C183.491 89.2519 183.577 89.1907 183.565 89.1234L183.302 86.6754C183.302 86.6019 183.198 86.5469 183.087 86.5469H182.518C182.408 86.5469 182.322 86.6019 182.328 86.6754L182.592 89.1234C182.586 89.1785 182.683 89.2519 182.812 89.2519Z'
          fill='#263238'
        />
        <path
          d='M169.109 89.2519H169.672C169.783 89.2519 169.868 89.1907 169.862 89.1234L169.599 86.6754C169.599 86.6019 169.495 86.5469 169.379 86.5469H168.816C168.699 86.5469 168.62 86.6019 168.626 86.6754L168.889 89.1234C168.895 89.1785 168.993 89.2519 169.109 89.2519Z'
          fill='#263238'
        />
      </g>
      <defs>
        <clipPath id='clip0_283_5227'>
          <rect width='243' height='216' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
