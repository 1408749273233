export default function AddCards() {
	return (
    <svg
      width='112'
      height='100'
      viewBox='0 0 112 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.3' clip-path='url(#clip0_279_3875)'>
        <path
          d='M57.062 0.21775L53.4365 1.15268L8.77016 12.6712L5.14471 13.6062C3.38495 14.0621 1.87791 15.1974 0.954196 16.7631C0.0304862 18.3288 -0.234451 20.1969 0.21751 21.9577L15.9383 82.9193C16.3942 84.6791 17.5295 86.1861 19.0952 87.1099C20.6609 88.0336 22.5291 88.2985 24.2898 87.8466L24.2992 87.8441L76.1977 74.4606L76.2071 74.4581C77.9668 74.0022 79.4739 72.8669 80.3976 71.3012C81.3213 69.7355 81.5862 67.8674 81.1343 66.1066L65.4135 5.14498C64.9576 3.38522 63.8223 1.87817 62.2566 0.954452C60.6909 0.0307356 58.8227 -0.234207 57.062 0.21775Z'
          fill='#1F271B'
        />
        <path
          d='M57.5547 2.12932L53.2606 3.23668L9.93143 14.4104L5.63739 15.5178C4.38423 15.8424 3.31102 16.6509 2.65322 17.7659C1.99542 18.8808 1.80675 20.2112 2.1286 21.4651L17.8494 82.4267C18.174 83.6798 18.9825 84.7531 20.0975 85.4109C21.2125 86.0686 22.5428 86.2573 23.7967 85.9355L23.8061 85.933L75.7046 72.5495L75.714 72.547C76.9672 72.2224 78.0404 71.4138 78.6981 70.2989C79.3559 69.184 79.5446 67.8536 79.2227 66.5997L63.502 5.63811C63.1773 4.38496 62.3688 3.31176 61.2539 2.65396C60.1389 1.99616 58.8086 1.80748 57.5547 2.12932Z'
          fill='white'
        />
        <path
          d='M54.3125 21.8702L28.0583 28.6407C27.765 28.7163 27.4538 28.6723 27.193 28.5185C26.9321 28.3646 26.7431 28.1134 26.6675 27.8202C26.5919 27.5269 26.6359 27.2157 26.7897 26.9549C26.9436 26.694 27.1948 26.505 27.488 26.4294L53.7422 19.659C54.0354 19.5837 54.3464 19.6278 54.607 19.7817C54.8676 19.9356 55.0564 20.1867 55.132 20.4797C55.2076 20.7728 55.1637 21.0839 55.0101 21.3446C54.8564 21.6053 54.6055 21.7944 54.3125 21.8702Z'
          fill='#1F271B'
        />
        <path
          d='M59.8356 24.4257L29.0207 32.3722C28.7274 32.4478 28.4162 32.4039 28.1554 32.25C27.8945 32.0961 27.7055 31.8449 27.6299 31.5517C27.5543 31.2585 27.5983 30.9472 27.7521 30.6864C27.906 30.4256 28.1572 30.2366 28.4504 30.161L59.2654 22.2144C59.5586 22.1388 59.8699 22.1827 60.1307 22.3366C60.3915 22.4905 60.5805 22.7417 60.6561 23.0349C60.7318 23.3281 60.6878 23.6394 60.5339 23.9002C60.3801 24.161 60.1289 24.35 59.8356 24.4257Z'
          fill='#1F271B'
        />
        <path
          d='M58.625 38.5929L32.3708 45.3633C32.2256 45.4008 32.0744 45.4093 31.926 45.3883C31.7775 45.3673 31.6346 45.3173 31.5055 45.2411C31.3763 45.1649 31.2635 45.064 31.1733 44.9442C31.0831 44.8244 31.0175 44.688 30.98 44.5428C30.9426 44.3976 30.9341 44.2465 30.9551 44.098C30.976 43.9495 31.026 43.8067 31.1022 43.6775C31.1784 43.5484 31.2793 43.4355 31.3991 43.3453C31.5189 43.2552 31.6553 43.1895 31.8005 43.1521L58.0547 36.3816C58.1999 36.3442 58.3511 36.3357 58.4995 36.3567C58.648 36.3777 58.7909 36.4277 58.9201 36.5039C59.0492 36.58 59.1621 36.6809 59.2522 36.8007C59.3424 36.9206 59.4081 37.057 59.4455 37.2021C59.4829 37.3473 59.4914 37.4985 59.4704 37.647C59.4495 37.7954 59.3995 37.9383 59.3233 38.0675C59.2471 38.1966 59.1462 38.3095 59.0264 38.3996C58.9066 38.4898 58.7702 38.5555 58.625 38.5929Z'
          fill='#1F271B'
        />
        <path
          d='M64.1479 41.1483L33.3329 49.0949C33.1877 49.1323 33.0366 49.1408 32.8881 49.1198C32.7396 49.0989 32.5968 49.0489 32.4676 48.9727C32.3385 48.8965 32.2256 48.7956 32.1354 48.6758C32.0453 48.556 31.9796 48.4196 31.9422 48.2744C31.9047 48.1292 31.8963 47.978 31.9172 47.8296C31.9382 47.6811 31.9882 47.5382 32.0644 47.4091C32.1406 47.2799 32.2415 47.167 32.3613 47.0769C32.4811 46.9867 32.6175 46.9211 32.7627 46.8836L63.5776 38.9371C63.8709 38.8614 64.1821 38.9054 64.443 39.0593C64.7038 39.2132 64.8928 39.4643 64.9684 39.7576C65.044 40.0508 65.0001 40.3621 64.8462 40.6229C64.6923 40.8837 64.4411 41.0727 64.1479 41.1483Z'
          fill='#1F271B'
        />
        <path
          d='M62.9372 55.3156L36.683 62.086C36.3898 62.1614 36.0788 62.1173 35.8181 61.9634C35.5575 61.8095 35.3686 61.5584 35.293 61.2653C35.2174 60.9722 35.2613 60.6611 35.415 60.4003C35.5687 60.1396 35.8197 59.9505 36.1128 59.8747L62.367 53.1043C62.6602 53.0287 62.9715 53.0726 63.2323 53.2265C63.4931 53.3804 63.6821 53.6316 63.7577 53.9248C63.8333 54.218 63.7894 54.5293 63.6355 54.7901C63.4816 55.0509 63.2304 55.2399 62.9372 55.3156Z'
          fill='#1F271B'
        />
        <path
          d='M68.4604 57.871L37.6454 65.8175C37.5002 65.8551 37.349 65.8637 37.2004 65.8429C37.0518 65.822 36.9088 65.772 36.7796 65.6958C36.6503 65.6197 36.5374 65.5188 36.4471 65.3989C36.3569 65.279 36.2912 65.1426 36.2537 64.9973C36.2162 64.852 36.2078 64.7008 36.2288 64.5522C36.2498 64.4037 36.2999 64.2607 36.3762 64.1315C36.4525 64.0023 36.5535 63.8895 36.6734 63.7993C36.7934 63.7092 36.9299 63.6436 37.0752 63.6063L67.8902 55.6597C68.1834 55.5841 68.4947 55.6281 68.7555 55.7819C69.0163 55.9358 69.2053 56.187 69.2809 56.4802C69.3565 56.7735 69.3126 57.0847 69.1587 57.3455C69.0048 57.6064 68.7536 57.7954 68.4604 57.871Z'
          fill='#1F271B'
        />
        <path
          d='M23.5765 35.5828L14.2854 37.9788C14.1448 38.0149 13.9956 37.9937 13.8706 37.9199C13.7455 37.8462 13.6549 37.7258 13.6185 37.5853L11.4857 29.3149C11.4496 29.1743 11.4708 29.0252 11.5445 28.9001C11.6183 28.7751 11.7386 28.6845 11.8792 28.6481L21.1703 26.2521C21.3109 26.216 21.46 26.2371 21.5851 26.3109C21.7101 26.3847 21.8007 26.505 21.8371 26.6455L23.9699 34.9159C24.006 35.0565 23.9848 35.2057 23.9111 35.3307C23.8373 35.4557 23.717 35.5464 23.5765 35.5828Z'
          fill='#1F271B'
        />
        <path
          d='M27.8887 52.3054L18.5976 54.7014C18.457 54.7375 18.3078 54.7164 18.1828 54.6426C18.0578 54.5688 17.9671 54.4485 17.9307 54.308L15.798 46.0376C15.7619 45.897 15.783 45.7478 15.8568 45.6228C15.9306 45.4978 16.0509 45.4071 16.1914 45.3707L25.4825 42.9747C25.6231 42.9386 25.7723 42.9598 25.8973 43.0336C26.0223 43.1073 26.113 43.2277 26.1494 43.3682L28.2822 51.6386C28.3182 51.7792 28.2971 51.9283 28.2233 52.0533C28.1496 52.1784 28.0292 52.269 27.8887 52.3054Z'
          fill='#1F271B'
        />
        <path
          d='M32.2015 69.0281L22.9104 71.4241C22.7698 71.4602 22.6206 71.439 22.4956 71.3653C22.3705 71.2915 22.2799 71.1712 22.2435 71.0306L20.1107 62.7603C20.0746 62.6197 20.0958 62.4705 20.1695 62.3455C20.2433 62.2204 20.3636 62.1298 20.5042 62.0934L29.7953 59.6974C29.9359 59.6613 30.085 59.6825 30.2101 59.7562C30.3351 59.83 30.4257 59.9503 30.4621 60.0908L32.5949 68.3612C32.631 68.5018 32.6098 68.651 32.5361 68.776C32.4623 68.901 32.342 68.9917 32.2015 69.0281Z'
          fill='#1F271B'
        />
        <path
          d='M93.2717 15.6709H39.6559C37.838 15.6729 36.0952 16.396 34.8098 17.6814C33.5244 18.9668 32.8014 20.7096 32.7993 22.5275V85.4835C32.8014 87.3014 33.5244 89.0442 34.8098 90.3296C36.0952 91.615 37.838 92.3381 39.6559 92.3401H93.2717C95.0895 92.3381 96.8323 91.615 98.1177 90.3296C99.4032 89.0442 100.126 87.3014 100.128 85.4835V22.5275C100.126 20.7096 99.4032 18.9668 98.1177 17.6814C96.8323 16.396 95.0895 15.6729 93.2717 15.6709Z'
          fill='#1F271B'
        />
        <path
          d='M93.2715 17.6448H39.6557C38.3612 17.6462 37.1201 18.1611 36.2047 19.0765C35.2893 19.9919 34.7744 21.233 34.7729 22.5275V85.4835C34.7744 86.7781 35.2893 88.0192 36.2047 88.9345C37.1201 89.8499 38.3612 90.3648 39.6557 90.3663H93.2715C94.5661 90.3648 95.8072 89.8499 96.7225 88.9345C97.6379 88.0191 98.1528 86.7781 98.1542 85.4835V22.5275C98.1528 21.233 97.6379 19.9919 96.7225 19.0765C95.8072 18.1611 94.5661 17.6462 93.2715 17.6448Z'
          fill='white'
        />
        <path
          d='M98.9044 99.9999C105.817 99.9999 111.421 94.3961 111.421 87.4835C111.421 80.5708 105.817 74.967 98.9044 74.967C91.9917 74.967 86.3879 80.5708 86.3879 87.4835C86.3879 94.3961 91.9917 99.9999 98.9044 99.9999Z'
          fill='#1F271B'
        />
        <path
          d='M104.891 85.8509H100.537V81.4973C100.537 81.0643 100.365 80.6491 100.059 80.3429C99.7528 80.0368 99.3376 79.8647 98.9046 79.8647C98.4716 79.8647 98.0564 80.0368 97.7502 80.3429C97.444 80.6491 97.272 81.0643 97.272 81.4973V85.8509H92.9185C92.4855 85.8509 92.0702 86.0229 91.7641 86.329C91.4579 86.6352 91.2859 87.0505 91.2859 87.4834C91.2859 87.9164 91.4579 88.3317 91.7641 88.6379C92.0702 88.944 92.4855 89.116 92.9185 89.116H97.272V93.4696C97.272 93.9026 97.444 94.3178 97.7502 94.624C98.0564 94.9301 98.4716 95.1021 98.9046 95.1021C99.3376 95.1021 99.7528 94.9301 100.059 94.624C100.365 94.3178 100.537 93.9026 100.537 93.4696V89.116H104.891C105.324 89.116 105.739 88.944 106.045 88.6379C106.351 88.3317 106.523 87.9164 106.523 87.4834C106.523 87.0505 106.351 86.6352 106.045 86.329C105.739 86.0229 105.324 85.8509 104.891 85.8509Z'
          fill='white'
        />
        <path
          d='M85.2023 52.2389H58.0891C57.9391 52.2391 57.7905 52.2097 57.6518 52.1524C57.5131 52.0951 57.3871 52.011 57.2809 51.905C57.1748 51.7989 57.0905 51.673 57.0331 51.5344C56.9756 51.3958 56.946 51.2472 56.946 51.0971C56.946 50.9471 56.9756 50.7985 57.0331 50.6599C57.0905 50.5213 57.1748 50.3953 57.2809 50.2893C57.3871 50.1833 57.5131 50.0992 57.6518 50.0419C57.7905 49.9846 57.9391 49.9552 58.0891 49.9553H85.2023C85.5049 49.9557 85.795 50.0761 86.0088 50.2902C86.2227 50.5043 86.3428 50.7945 86.3428 51.0971C86.3428 51.3997 86.2227 51.69 86.0088 51.9041C85.795 52.1182 85.5049 52.2386 85.2023 52.2389Z'
          fill='#1F271B'
        />
        <path
          d='M89.9122 56.0925H58.0891C57.9391 56.0926 57.7905 56.0632 57.6518 56.0059C57.5131 55.9486 57.3871 55.8645 57.2809 55.7585C57.1748 55.6524 57.0905 55.5265 57.0331 55.3879C56.9756 55.2493 56.946 55.1007 56.946 54.9506C56.946 54.8006 56.9756 54.652 57.0331 54.5134C57.0905 54.3748 57.1748 54.2489 57.2809 54.1428C57.3871 54.0368 57.5131 53.9527 57.6518 53.8954C57.7905 53.8381 57.9391 53.8087 58.0891 53.8088H89.9122C90.215 53.8088 90.5055 53.9291 90.7196 54.1433C90.9337 54.3574 91.054 54.6478 91.054 54.9506C91.054 55.2535 90.9337 55.5439 90.7196 55.758C90.5055 55.9722 90.215 56.0925 89.9122 56.0925Z'
          fill='#1F271B'
        />
        <path
          d='M85.2023 69.5087H58.0891C57.9391 69.5089 57.7905 69.4795 57.6518 69.4222C57.5131 69.3649 57.3871 69.2808 57.2809 69.1747C57.1748 69.0687 57.0905 68.9428 57.0331 68.8042C56.9756 68.6655 56.946 68.517 56.946 68.3669C56.946 68.2169 56.9756 68.0683 57.0331 67.9297C57.0905 67.791 57.1748 67.6651 57.2809 67.5591C57.3871 67.453 57.5131 67.369 57.6518 67.3116C57.7905 67.2543 57.9391 67.2249 58.0891 67.2251H85.2023C85.5051 67.2251 85.7955 67.3454 86.0096 67.5595C86.2238 67.7737 86.3441 68.0641 86.3441 68.3669C86.3441 68.6697 86.2238 68.9602 86.0096 69.1743C85.7955 69.3884 85.5051 69.5087 85.2023 69.5087Z'
          fill='#1F271B'
        />
        <path
          d='M89.9122 73.3625H58.0891C57.9391 73.3626 57.7905 73.3332 57.6518 73.2759C57.5131 73.2186 57.3871 73.1345 57.2809 73.0285C57.1748 72.9225 57.0905 72.7965 57.0331 72.6579C56.9756 72.5193 56.946 72.3707 56.946 72.2207C56.946 72.0706 56.9756 71.922 57.0331 71.7834C57.0905 71.6448 57.1748 71.5189 57.2809 71.4128C57.3871 71.3068 57.5131 71.2227 57.6518 71.1654C57.7905 71.1081 57.9391 71.0787 58.0891 71.0789H89.9122C90.0623 71.0787 90.2109 71.1081 90.3496 71.1654C90.4882 71.2227 90.6143 71.3068 90.7204 71.4128C90.8266 71.5189 90.9108 71.6448 90.9683 71.7834C91.0257 71.922 91.0553 72.0706 91.0553 72.2207C91.0553 72.3707 91.0257 72.5193 90.9683 72.6579C90.9108 72.7965 90.8266 72.9225 90.7204 73.0285C90.6143 73.1345 90.4882 73.2186 90.3496 73.2759C90.2109 73.3332 90.0623 73.3626 89.9122 73.3625Z'
          fill='#1F271B'
        />
        <path
          d='M52.0159 57.842H42.4208C42.2756 57.8418 42.1365 57.7841 42.0338 57.6815C41.9312 57.5788 41.8735 57.4397 41.8733 57.2945V48.7536C41.8735 48.6084 41.9312 48.4692 42.0338 48.3666C42.1365 48.2639 42.2756 48.2062 42.4208 48.2061H52.0159C52.161 48.2062 52.3002 48.2639 52.4028 48.3666C52.5055 48.4692 52.5632 48.6084 52.5634 48.7536V57.2945C52.5632 57.4397 52.5055 57.5788 52.4028 57.6815C52.3002 57.7841 52.161 57.8418 52.0159 57.842Z'
          fill='#1F271B'
        />
        <path
          d='M52.0159 75.1118H42.4208C42.2756 75.1116 42.1365 75.0539 42.0338 74.9513C41.9312 74.8486 41.8735 74.7094 41.8733 74.5643V66.0233C41.8735 65.8782 41.9312 65.739 42.0338 65.6364C42.1365 65.5337 42.2756 65.476 42.4208 65.4758H52.0159C52.161 65.476 52.3002 65.5337 52.4028 65.6364C52.5055 65.739 52.5632 65.8782 52.5634 66.0233V74.5643C52.5632 74.7094 52.5055 74.8486 52.4028 74.9513C52.3002 75.0539 52.161 75.1116 52.0159 75.1118Z'
          fill='#1F271B'
        />
        <path
          d='M85.2325 33.0143H65.2431C64.9403 33.0143 64.6499 32.894 64.4357 32.6799C64.2216 32.4658 64.1013 32.1753 64.1013 31.8725C64.1013 31.5697 64.2216 31.2793 64.4357 31.0651C64.6499 30.851 64.9403 30.7307 65.2431 30.7307H85.2325C85.5354 30.7307 85.8258 30.851 86.0399 31.0651C86.254 31.2793 86.3743 31.5697 86.3743 31.8725C86.3743 32.1753 86.254 32.4658 86.0399 32.6799C85.8258 32.894 85.5354 33.0143 85.2325 33.0143Z'
          fill='#1F271B'
        />
        <path
          d='M89.9425 36.8678H65.2431C65.0932 36.8678 64.9447 36.8383 64.8062 36.7809C64.6676 36.7235 64.5418 36.6394 64.4357 36.5334C64.3297 36.4274 64.2456 36.3015 64.1882 36.163C64.1309 36.0245 64.1013 35.876 64.1013 35.726C64.1013 35.5761 64.1309 35.4276 64.1882 35.2891C64.2456 35.1506 64.3297 35.0247 64.4357 34.9187C64.5418 34.8126 64.6676 34.7285 64.8062 34.6711C64.9447 34.6138 65.0932 34.5842 65.2431 34.5842H89.9425C90.2453 34.5842 90.5357 34.7045 90.7499 34.9187C90.964 35.1328 91.0843 35.4232 91.0843 35.726C91.0843 36.0289 90.964 36.3193 90.7499 36.5334C90.5357 36.7475 90.2453 36.8678 89.9425 36.8678Z'
          fill='#1F271B'
        />
        <path
          d='M60.8193 41.5379H42.3908C42.2456 41.5378 42.1064 41.4801 42.0038 41.3774C41.9012 41.2748 41.8434 41.1356 41.8433 40.9905V26.608C41.8434 26.4629 41.9012 26.3237 42.0038 26.2211C42.1064 26.1184 42.2456 26.0607 42.3908 26.0605H60.8193C60.9644 26.0607 61.1036 26.1184 61.2062 26.2211C61.3089 26.3237 61.3666 26.4629 61.3668 26.608V40.9905C61.3666 41.1356 61.3089 41.2748 61.2062 41.3774C61.1036 41.4801 60.9644 41.5378 60.8193 41.5379Z'
          fill='#1F271B'
        />
      </g>
      <defs>
        <clipPath id='clip0_279_3875'>
          <rect width='111.421' height='100' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
