import React, { useEffect, useRef, useState } from 'react';
import ImageModal from './ImageModal';

const RenderHtml = ({ content, renderClassName }) => {
  const containerRef = useRef(null);
  const [url, setUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const imageClickHandler = (src) => {
    setUrl(src);
    openModal();
  };

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;

      container.innerHTML = content;

      const images = container.querySelectorAll('img');
      images.forEach((img) => {
        img.onclick = () => imageClickHandler(img.src);
      });
    }
  }, [content]);
  return (
    <>
      <div
        ref={containerRef}
        className={`editor min-h-[140px] overflow-y-auto ${renderClassName}`}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
      <ImageModal imageUrl={url} isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default RenderHtml;
