import { Pencil, ChevronDown, Search } from 'lucide-react';
import { BackSide, CustomListPopover, FrontSide } from '@common/components';
import {
  apiEndpoints,
  queryKeys,
  useGetQuery,
  useUpdateMutation,
  usePostMutation,
} from '@services';
import { useState, useEffect } from 'react';
import { Button } from '@components';
import { toast } from 'react-toastify';
import { SUCCESS } from '@constants';
import useInfiniteScroll from '@common/hooks/useInfiniteScrool';
import { doInfiniteScroll } from '@utils';
import { Loader } from '@common/components/Loader';

export function DecksPreview({ selectedDeck, selectedCard, setSelectedCard }) {
  const [newTags, setNewTags] = useState([]);
  const [removeTags, setRemoveTags] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [initialQuestion, setInitialQuestion] = useState();
  const [initialAnswer, setInitialAnswer] = useState();
  const [rooms, setRooms] = useState([]);
  const [search, setSearch] = useState('');
  const [tagsLoading, setTagsLoading] = useState(true);
  const [checkBoxDisable, setCheckBoxDisable] = useState(false);

  const [cardTags, setCardTags] = useState([]);

  const {
    data: getCardDetails,
    isFetching: cardDetailsFetching,
    refetch: refetchCardDetails,
  } = useGetQuery(
    queryKeys?.CARD?.CARDS,
    apiEndpoints?.CARD?.CARD_DETAILS(selectedCard),
    {},
    { enabled: !!selectedCard },
  );

  const {
    listing: getDeckRooms,
    listFetching: fetchingDeckRooms,
    listRefetch: refetchDeckRooms,
    listNextPage: deckRoomsNextPage,
    listHasNextPage: deckRoomsHasNextPage,
  } = useInfiniteScroll({
    apiUrl: apiEndpoints?.DECK?.ROOMS,
    key: queryKeys?.DECK?.ROOM,
    params: { deck: selectedDeck, search: search },
    isEnabled: !!selectedDeck,
  });

  const onGetAllCardTags = () => {
    setTagsLoading(false);
  };

  const {
    data: getCardTags,
    refetch: refetchCardTags,
    isFetching: fetchingCardTags,
    isLoading: LoadingCardTags,
  } = useGetQuery(
    queryKeys?.CARD?.TAGS,
    apiEndpoints?.CARD?.TAGS,
    {
      card: selectedCard,
    },
    { enabled: false, onSuccess: onGetAllCardTags },
  );

  const handleRoomScroll = (e) =>
    doInfiniteScroll(
      e,
      deckRoomsHasNextPage,
      fetchingDeckRooms,
      deckRoomsNextPage,
    );

  const { mutate: updateCardMutation } = useUpdateMutation(
    apiEndpoints?.CARD?.UPDATE_CARD + `${selectedCard}`,
  );

  useEffect(() => {
    refetchCardTags();
  }, [selectedCard]);

  const onSuccessUpdate = () => {
    setCheckBoxDisable(false);
    toast.success(SUCCESS('Deck updated'));
  };

  const onErrorUpdate = () => {
    toast.error('Something went wrong.');
    setCheckBoxDisable(false);
  };
  const { mutate: updateDeckMutation } = useUpdateMutation(
    apiEndpoints?.DECK?.UPDATE(selectedDeck),
    onSuccessUpdate,
    onErrorUpdate,
  );

  useEffect(() => {
    refetchDeckRooms();
  }, [search]);

  useEffect(() => {
    if (selectedDeck) {
      refetchDeckRooms();
    }
  }, [selectedDeck]);

  useEffect(() => {
    if (getCardTags) {
      setCardTags(getCardTags?.data?.results);
    }
  }, [getCardTags]);

  const onSuccessCreateCard = (data) => {};
  const { mutate: createCardMutation } = usePostMutation(
    apiEndpoints?.CARD?.ADD_CARD,
    onSuccessCreateCard,
  );

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    const allSelectedIds = getDeckRooms?.pages?.flatMap((page) =>
      page?.data?.results
        ?.filter((room) => room?.isSelected)
        ?.map((room) => room?.id),
    );
    setRooms(allSelectedIds);
  }, [getDeckRooms]);

  const handleSave = () => {
    setEditMode(!editMode);
    const data = {
      question: question,
      answer: answer,
      toRemoveTags: removeTags,
      newTags: newTags,
    };
    updateCardMutation({ payload: data });
    setNewTags([]);
    setRemoveTags([]);
  };

  useEffect(() => {
    if (!selectedCard) {
    } else if (selectedCard) {
      refetchDeckRooms();
      refetchCardDetails();
    }
  }, [selectedCard]);

  const handleCheckedChange = (value, id) => {
    setCheckBoxDisable(true);

    if (value) {
      setRooms([...rooms, id]);
      updateDeckMutation({ payload: { newRooms: [id] } });
    } else {
      setRooms((rooms) => rooms?.filter((roomId) => roomId !== id));

      updateDeckMutation({ payload: { toRemoveRooms: [id] } });
    }
  };

  const handleDiscard = () => {
    setEditMode(!editMode);
    setQuestion(initialQuestion);
    setAnswer(initialAnswer);
    setCardTags(getCardTags?.data?.results);
  };

  useEffect(() => {
    if (getCardDetails?.data) {
      setQuestion(getCardDetails?.data?.question);
      setAnswer(getCardDetails?.data?.answer);
      setInitialQuestion(getCardDetails?.data?.question);
      setInitialAnswer(getCardDetails?.data?.answer);
    }
  }, [getCardDetails?.data]);

  return (
    <div className=' flex flex-col gap-7 pl-[1.875rem] pr-10 pt-6 lg:w-[calc(100vw-589px)] 2xl:w-[calc(100vw-657px)]'>
      <div className='flex items-center justify-between'>
        <h1 className='text-2xl font-bold leading-[1.8125rem]'>Details</h1>
        {editMode ? (
          <div className='flex gap-3'>
            <Button
              variant='outline'
              className='flex items-center gap-1 rounded-md'
              onClick={handleDiscard}
            >
              Discard Changes
            </Button>
            <Button
              className='flex items-center gap-1 rounded-md'
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        ) : (
          <div className='flex gap-4'>
            <Button className='h-9 w-9 p-2.5' onClick={handleEditMode}>
              <Pencil height='18px' width='18px' />
            </Button>

            <CustomListPopover
              name={'Room'}
              data={getDeckRooms}
              search={search}
              setSearch={setSearch}
              handleScroll={handleRoomScroll}
              selectedItems={rooms}
              updateItem={handleCheckedChange}
              checkBoxDisable={checkBoxDisable}
            />
          </div>
        )}
      </div>
      {!cardDetailsFetching ? (
        <div className='flex h-[calc(100vh-100px)]'>
          <FrontSide
            data={getCardDetails}
            question={question}
            setQuestion={setQuestion}
            editMode={editMode}
            setEditMode={setEditMode}
            toAdd={newTags}
            setToAdd={setNewTags}
            removeTags={removeTags}
            setRemoveTags={setRemoveTags}
            tags={getCardDetails?.data?.tags}
            selectedCard={selectedCard}
            activeCardTags={getCardTags?.data?.results}
            cardTags={cardTags}
            setCardTags={setCardTags}
            tagsLoading={tagsLoading}
            setTagsLoading={setTagsLoading}
          />
          <BackSide answer={answer} setAnswer={setAnswer} editMode={editMode} />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
