import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const doInfiniteScroll = (
  event,
  hasNextPage,
  isRefetching,
  fetchNextPage,
) => {
  const target = event.target;
  const { scrollHeight, scrollTop, clientHeight } = target;

  if (
    scrollHeight - scrollTop <= clientHeight + 10 &&
    hasNextPage &&
    !isRefetching
  )
    fetchNextPage();
};
