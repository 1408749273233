import { useEffect, useState } from 'react';
import {
  CardContent,
  CardHeader,
  CardTitle,
  Card,
  Checkbox,
} from '@components';

export function ContentCard({
  data,
  setActiveCardId,
  deleteCards,
  handleDeleteCards,
}) {
  const [isActive, setIsActive] = useState(false);

  const handleCardClick = () => {
    setIsActive(true);
    setActiveCardId(data?.id);
  };

  const handleCardFocus = () => {
    setIsActive(true);
  };

  const handleCardBlur = () => {
    setIsActive(false);
  };

  return (
    <Card
      className={`relative z-[3] flex min-h-[6.75rem] cursor-pointer flex-col gap-2 rounded-lg ${isActive ? 'border-none bg-primary/5 p-[13px]' : ' border border-primary/15 p-3'}`}
      onClick={handleCardClick}
      onFocus={handleCardFocus}
      onBlur={handleCardBlur}
      tabIndex={0}
    >
      <CardHeader className='p-0'>
        <CardTitle className='max-w-[14.5rem] truncate text-sm font-semibold leading-[1.0588rem] text-primary'>
          {data.cleanedQuestion}
        </CardTitle>

        <div>
          <Checkbox
            className='absolute right-2 top-2 z-[9999] !mt-0'
            checked={deleteCards?.includes(data?.id)}
            onCheckedChange={(e) => handleDeleteCards(e, data?.id)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </CardHeader>
      <CardContent className='line-clamp-3 max-w-[16.5rem] p-0 text-xs font-normal leading-4'>
        {data.cleanedAnswer}
      </CardContent>
    </Card>
  );
  6;
}
