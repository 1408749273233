import { X } from 'lucide-react';
import React from 'react';

export function RatingAlert({ onClose }) {
  return (
    <div className='absolute left-1/2 top-[5.9375rem] z-20 h-[7rem] w-[23.5rem] -translate-x-1/2 transform rounded-md border bg-white  p-2 pb-6 pl-6 shadow-alert'>
      <div className='flex justify-end'>
        <X
          height={16}
          width={16}
          className='cursor-pointer'
          onClick={onClose}
        />
      </div>
      <div className='space-y-2'>
        <p className='text-sm font-normal leading-5'>
          Answers and ratings for the student have been revealed.
        </p>
      </div>
    </div>
  );
}
