import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@components';
import { ROUTES } from '@routes';
import { ACCESS_TOKEN } from '@constants';
import { removeLocalStorageItem } from '@utils';
import { useNavigate } from 'react-router-dom';
export function LogoutDialog() {
  const navigate = useNavigate();
  const handleLogout = () => {
    removeLocalStorageItem(ACCESS_TOKEN);
    navigate(ROUTES.SIGN_IN);
  };
  return (
    <Dialog>
      <DialogTrigger className='px-4 pb-6 pt-4 text-base font-semibold leading-5 text-borderDark'>
        Logout
      </DialogTrigger>
      <DialogContent className='flex h-[208px] w-[346px] flex-col items-center py-11'>
        <DialogHeader>
          <DialogTitle>Logging Out?</DialogTitle>
        </DialogHeader>
        <p className='text-sm font-medium leading-5'>
          Are you sure you want to logout?
        </p>
        <div className='flex w-full justify-center'>
          <Button
            variant='destructive'
            className='w-[11.25rem]'
            onClick={() => handleLogout()}
          >
            Logout
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
