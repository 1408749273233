import * as Yup from 'yup';

export const LoginFormValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
});

export const CreateDeckValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(60, 'Name must be at most 60 characters')
    .required('Name is required'),
  tag: Yup.array(),
});

export const roomValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(60, 'Name must be at most 60 characters')
    .required('Name is required'),
});

export const tagValidationSchema = Yup.object().shape({
  name: Yup.string().max(60, 'Name must be at most 60 characters'),
});
